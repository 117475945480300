import { useEffect, useRef } from "react";

import INdropmenu from "./INdropDown";
import StartDropDown from "./dropdownStartmenu";

function DropDownMenu({ handleShowMenu, handleShowLocDesk, handleShowAutorDesk, setShowMenu }) {

  // 

  const menuRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  }

  return (
    <div className="absolute top-0 left-0 w-[100vw] bg-[#8080808f] h-[100vh]">
      <div ref={menuRef}
        className="dropable-menu  bg-white h-[100%]    w-[270px]"
        handleShowMenu={handleShowMenu}
      >
        <INdropmenu handleShowMenu={handleShowMenu} handleShowLocDesk={handleShowLocDesk} setShowMenu={setShowMenu} />
        {/* <StartDropDown handleShowMenu={handleShowMenu} handleShowAutorDesk={handleShowAutorDesk}/> */}
      </div>
    </div>
  );
}

export default DropDownMenu;
