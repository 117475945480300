import { ThreeDots } from "react-bootstrap-icons";
import { useState, useEffect, useRef } from "react";

function AdressInProfile({ MiniDropDown, title, address, icon }) {
  const [isshow, setIsShown] = useState(false);

  const handleShow = (event) => {
    setIsShown((prev) => !prev);
  };

  const dotsRef = useRef(null);

  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dotsRef.current && !dotsRef.current.contains(event.target)) {
      // click occurred outside of wrapper, close the div here
      setIsShown(false)
    }
  }

  return (
    <div className="your-adrs  w-[70%] rounded-[16px] p-[20px] bg-white justify-between items-center flex hover:shadow cursor-pointer relative ">
      <div className=" flex gap-[5px] w-[100%]">
        <div className="adres-icon">
          <div className="added-adrs-hous-icn w-[50px] text-[#010125] h-[50px] rounded-full flex justify-center items-center bg-gray-100">
            {icon}
          </div>
        </div>
        <div className="w-[80%]">
          <p className="text-[#010125]">{title}</p>
          <p className="adrs-misam-text truncate">{address}</p>
        </div>
      </div>
      <div  ref={dotsRef}
        className="adrs-drop-btn absolute bg-white right-[20px] w-[40px] h-[40px] flex justify-center rounded-full items-center border-[1px] border-[#010125]"
        onClick={() => {
          handleShow();
        }}
      >
        <ThreeDots className="text-[#010125]" />
        {isshow && <MiniDropDown handleShow={handleShow} setIsShown={setIsShown}/>}
      </div>
    </div>
  );
}

export default AdressInProfile;
