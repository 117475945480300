import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import ForgetPassword3 from "./forgetpassword3";

export default function ForgetPassword2({ setActive }) {
  const [show, setshow] = useState(false);
  const [passwordValue, setPasswordValue] = useState('')
  const [isPasswordValueError, setIsPasswordValueError] = useState(false)

  //handel valueChange 
  const handelChange = (e) =>{
    setPasswordValue(e.target.value)
  }
  const handelClick = () => {
    if(( passwordValue === '1234') && passwordValue.length >0 ){
      setIsPasswordValueError(false)
      setActive("forgetpassword3")
    }
    else{
      setIsPasswordValueError(true)
    }
  }

  const handleshow = () => {
    setshow((pre) => !pre);
  };
  return (
    <div className="forgotpaswordee w-full flex flex-col gap-y-[10px] mt-[-3px] px-[18%]">
      <p className="text-center text-[18px]">პაროლის აღდგენა</p>

      <div className="flex flex-col gap-y-[10px]">
        <div className='flex flex-col gap-1'>
          <p className="font-semibold text-[#010125]">კოდი</p>
          <div className={`flex w-[100%] h-[50px] border-[1px] ${isPasswordValueError && 'border-red-600'} rounded-[10px] items-center pr-[10px] gap-[10px]`}>
            <input
              value={passwordValue}
              onChange={(e) => handelChange(e)}
              type={show ? "text" : "password"}
              name="password"
              id="password"
              placeholder="***********"
              className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
            />
            <div onClick={handleshow} className="text-[20px] text-[#010125]">
              {show ? (
                <Eye className="cursor-pointer" />
              ) : (
                <EyeSlash className="cursor-pointer" />
              )}
            </div>
          </div>
          {isPasswordValueError && <p className="font-semibold text-red-600 text-center text-[12px]">კოდი არასწორია</p> }
        </div>
      </div>

      <div
        onClick={() => handelClick()}
        className="flex gap-[5px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-[#010125] text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#010125] border-[2px] border-[#010125] hover:bg-white duration-200"
      >
        <h1>გაგრძელება</h1>
      </div>
    </div>
  );
}
