import React, { useContext, useState } from 'react'
import CategoryData from '../contexts/CategoryData'
import { Link } from 'react-router-dom'
import { BiChevronRight } from 'react-icons/bi'
import SubCategory from './SubCategory'

const CategoryPopUp = () => {
  const categoryData = useContext(CategoryData)
  const [isShowSubCategory,setIsShowSubCategory] = useState(false)
  const [subCategoryData, setSubCategoryData] = useState([])
  return (
    <div className='bg-white w-full h-full  absolute top-0 z-[10]'>
      <div className='w-full h-full overflow-auto mt-[110px]'>
        {
          isShowSubCategory ? 
          <SubCategory subCategoryData={subCategoryData} setIsShowSubCategory={setIsShowSubCategory}/>
          :
          <div className='w-full flex flex-col'>
              <ul className="gap-y-[10px] flex flex-col px-4">
                {
                  categoryData.categoryData.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => {setSubCategoryData(item); setIsShowSubCategory(true)}} 
                      className={` group ${item.id === categoryData.categoryData.length ? '': "border-b-2 border-slate-200"} flex justify-between items-center `}>
                      {/* <Link to={`/products/${item.textLink} `} className={`flex items-center  ${!item.subCategory && 'cursor-pointer-none'} group-one text-gray-600 pr-[5px]`}> */}
                        <h1 className="css-elipses text-[13px] py-[14px] ">{item.text}</h1>
                        {
                          item.subCategory && 
                          <div>
                            <BiChevronRight className='text-[20px] mb-2'/> 
                          </div>
                        }
                      {/* </Link> */}
                    </li>
                  ))
                }
              </ul>

          </div>
        }
      </div>
    </div>
  )
}

export default CategoryPopUp