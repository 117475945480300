import {
  BoxArrowRight,
  ChatDots,
  CreditCard,
  CreditCard2Front,
  FileText,
  GeoAlt,
  Gift,
  Person,
} from "react-bootstrap-icons";
import DropDownMenu from "../content/dropdown-manu/dropdownmenu";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Geoflag from "../../imgs/15.webp";
import { useEffect, useState } from "react";
import MiniDropDown from "../content/profile/minidropsown";
import ProfileOrders from "../content/profile/proforders";
import ProfileAdress from "../content/profile/profadress";
import ProfilePayment from "../content/profile/profPayment";
import ProfilePromocodes from "../content/profile/profpromocodes";
import ProfileProfileWindow from "../content/profile/profprofilewindow";
import ProfileHelp from "../content/profile/profhelp";

function Profile() {
  const location = useLocation();
  const navigate = useNavigate(null);
  const params = useParams()
  const category = location.pathname.split('/')[2]
  const [lastPage,setLastPage] = useState('')
  
  useEffect(() => {
    let newLastPage=''
    if (category === "profile-orders") {
      newLastPage='შეკვეთები'
    }
    if (category === "profile-address") {
      newLastPage='მისამართი'
    }
    if (category === "profile-promocodes") {
      newLastPage='პრომო კოდი'
    }
    if (category === "profile-profwind") {
      newLastPage='ინფორმაცია'
    }
    if (category === "profile-profhelp") {
      newLastPage='დახმარება'
    }
    setLastPage(newLastPage)
  }, [category]);

  useEffect(() => {
    if (location.pathname === "/profile") {
      navigate("../profile/profile-orders");
    }
  }, [location]);

  return (
    <div className="w-[100%] flex items-center flex-col bg-slate-100">
      
      <div
        className="w-full shadow-sm  border-b-[1px] flex
        border-slate-200 items-center justify-center bg-white mt-[90px] max-[540px]:mt-[70px]"
      >
        <div className="w-full flex gap-3 items-center  px-[30px] py-[10px] ">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/profile">
            <p className="text-[14px]">პროფილი</p>
          </Link>
          <p>/</p>
          <Link >
            <p className="text-[14px]">{lastPage}</p>
          </Link>
        </div>
      </div>
      <div className="profile-widow max-w-[2100px]  w-[100%] flex">
      {/* <div className="flex"> */}
        <div className="menu-profile bg-gray-100 min-h-[100vh] pl-[20px] py-[10px] w-[20%] border-r-[1px] min-w-[240px]">
          <div className="flex flex-col py-[15px] border-b-[1px] pr-[15px]">
            <div className="flex items-center gap-[10px] bg-white rounded-[20px] p-[10px]">
              <div className="bg-white rounded-full flex justify-center items-center w-[40px] h-[40px] border-[1px] border-[#010125]">
                <Person className="text-[30px]" />
              </div>
              <div>
                <p className="text-[15px]">გიორგი ჩიქოვანი</p>
                <p className="text-gray-500 text-[13px]">595292822</p>
              </div>
            </div>
          </div>

          <ul className="flex flex-col mt-[10px] ">
            <Link to={"profile-orders"}>
              <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
                <FileText className="" />
                შეკვეთები
              </li>
            </Link>
            <Link to={"profile-address"}>
              <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
                <GeoAlt className="" />
                მისამართები
              </li>
            </Link>
            <Link to="profile-promocodes">
              <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
                <Gift className="" />
                პრომო კოდები
              </li>
            </Link>
            <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
              <CreditCard2Front className="" />
              დაგროვების ბარათი
            </li>
            <Link to="profile-profwind">
              <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
                <Person className="" />
                პროფილი
              </li>
            </Link>
            <Link to="profile-profhelp">
              <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
                <ChatDots className="" />
                დახმარება
              </li>
            </Link>
            <li className="flex items-center gap-[5px] py-[10px] cursor-pointer">
              <BoxArrowRight className="" />
              გასვლა
            </li>
          </ul>
        </div>
        <div className="profile-content-part bg-gray-100 px-[20px] pt-[25px] pb-[50px] w-[80%] min-h-[100vh]">
          <Routes>
            <Route path="/profile-orders" element={<ProfileOrders />} />
            <Route path="/profile-address" element={<ProfileAdress />} />
            <Route path="/profile-payment" element={<ProfilePayment />} />
            <Route path="/profile-promocodes" element={<ProfilePromocodes />} />
            <Route path="/profile-profwind" element={<ProfileProfileWindow />} />
            <Route path="/profile-profhelp" element={<ProfileHelp />} />
          </Routes>
        </div>
      {/* </div> */}
      </div>
    </div>
  );
}

export default Profile;
