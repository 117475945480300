import React, { useContext } from 'react'
import { useState } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import SubSubCategory from './SubSubCategory'
import { Link } from 'react-router-dom'
import IsShowCategoryPopUp from '../contexts/IsShowCategoryPopUp'

const SubCategory = ({subCategoryData,setIsShowSubCategory,handleShowMenu,setShowMenu}) => {
  const isShowCategoryPopUp = useContext(IsShowCategoryPopUp)
  const [isShowSubSubCategory, setIsSubSubCategory] = useState(false)
  const [subSubCategoryData,setSubSubCategoryData] = useState([])

  return (
    <div className='w-full flex flex-col'>
      {
        isShowSubSubCategory ? 
        <SubSubCategory subSubCategoryData={subSubCategoryData} setIsSubSubCategory={setIsSubSubCategory} handleShowMenu={handleShowMenu} setShowMenu={setShowMenu} />
        :
        <ul className="gap-y-[10px] flex flex-col px-4">
          <li
            onClick={() => setIsShowSubCategory(false)} 
            className={` group border-b-2 border-slate-200 flex justify-between items-center `}>
              <div>
                <BiChevronLeft className='text-[20px] mb-2'/> 
              </div>
              <h1 className="css-elipses text-[13px] py-[5px] mb-2">{subCategoryData.text}</h1>
          </li>
          { subCategoryData &&
            subCategoryData.subCategory.map((item) => {
              if(item.subCategory){
                return(
                  <li
                    key={item.id}
                    onClick={() => {setSubSubCategoryData(item); setIsSubSubCategory(true)}}
                    className={` group ${item.id === subCategoryData.subCategory.length ? '': "border-b-2 border-slate-200"} flex  justify-between items-center `}>
                      <h1 className={`css-elipses text-[13px] py-[14px]`}>{item.titleGeo}</h1>
                      {
                        item.subCategory &&
                        <div>
                          <BiChevronRight className='text-[20px] mb-2'/> 
                        </div>
                      }
                  </li>
                )
              }
              else{
                return(
                  <Link 
                      onClick={() => {setShowMenu(false);isShowCategoryPopUp.setIsShowCategoryPopUp(false)}}
                      to={`/products/${subCategoryData.textLink}/${item.title} `} 
                      className={`flex items-center cursor-pointer  group-one text-gray-600 pr-[5px] w-full`}>
                    <li
                      key={item.id}
                      className={` group border-b-2 border-slate-200 flex  justify-between items-center w-full `}>
                      
                        <h1 className={`css-elipses text-[13px] py-[5px] mb-2`}>{item.titleGeo}</h1>
                        {
                          item.subCategory &&
                          <div>
                            <BiChevronRight className='text-[20px] mb-2'/> 
                          </div>
                        }
                    </li>
                  </Link>
              ) 
              }
            
            })
          }
        </ul>
      }
  </div>
  )
}

export default SubCategory