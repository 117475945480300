import { ArrowUpRight, ChevronDown, XLg } from "react-bootstrap-icons";
import MultiRangeSlider from "../mshopContent/multirangeslider";
import { useState } from "react";

function FavSrchDrop({ handleShowSrch, showSrch }) {
  const [moreBrand, setMoreBrand] = useState(true);

  const handlemoreBrand = () => {
    setMoreBrand((pre) => !pre);
  };

  const [moreSize, setMoreSize] = useState(true);

  const handlemoreSize = () => {
    setMoreSize((pre) => !pre);
  };

  const [moreColor, setMoreColor] = useState(true);

  const handlemoreColor = () => {
    setMoreColor((pre) => !pre);
  };

  const [sizedata, setSizedata] = useState([
    {
      id: 1,
      size: "SM",
    },
    {
      id: 2,
      size: "M",
    },
    {
      id: 3,
      size: "LG",
    },
    {
      id: 4,
      size: "XL",
    },
    {
      id: 5,
      size: "2XL",
    },
    {
      id: 6,
      size: "165x100",
    },
    {
      id: 7,
      size: "205x105",
    },
    {
      id: 8,
      size: "26",
    },
    {
      id: 9,
      size: "28",
    },
    {
      id: 10,
      size: "30",
    },
    {
      id: 11,
      size: "32",
    },
  ]);

  const [branddata, setBranddata] = useState([
    {
      id: 1,
      brand: "apple",
    },
    {
      id: 2,
      brand: "Xiaomi",
    },
    {
      id: 3,
      brand: "Samsung",
    },
    {
      id: 4,
      brand: "Huawei",
    },
    {
      id: 5,
      brand: "Google",
    },
    {
      id: 6,
      brand: "Nokia",
    },
  ]);

  const [colordata, setColordata] = useState([
    {
      id: 1,
      color: "წითელი",
    },
    {
      id: 2,
      color: "ყვითელი",
    },
    {
      id: 3,
      color: "ლურჯი",
    },
    {
      id: 4,
      color: "მწვანე",
    },
    {
      id: 5,
      color: "ყავისფერი",
    },
    {
      id: 6,
      color: "ვარდისფერი",
    },
    {
      id: 7,
      color: "თეთრი",
    },
  ]);
  return (
    <div
      className={`favsrchdrop top-[88px] right-0 min-w-[290px] w-[20%] h-[calc(100vh-90px)]  max-[540px]:h-[calc(100vh-70px)] border-l overflow-y-scroll ${
        showSrch ? "fixed" : "hidden"
      } `}
    >
      <div className="favsrchspace pl-[15px] pr-[7px] pt-[20px] pb-[20px] max-[660px]:pb-[35px] bg-white flex flex-col gap-y-[10px] w-[100%] min-h-[100%] ">
        <div className="flex justify-end pr-[10px]">
          <div
            className="dropfavsrchbtn w-[35px] h-[35px] bg-slate-100 rounded-full flex items-center justify-center text-[19px] border border-gray-300 text-gray-500 hover:bg-gray-300"
            onClick={handleShowSrch}
          >
            <XLg className="text-black" />
          </div>
        </div>
        <div className="price ">
          <p className="shoplisttitle">ფასი</p>
          <MultiRangeSlider
            min={0}
            max={1000}
            onChange={({ min, max }) => console.log()}
          />
        </div>
        <div className="brands mb-[10px] flex flex-col gap-y-[8px]">
          <div
            className="flex justify-between items-center border-b-[1px] pb-[3px]"
            onClick={() => {
              handlemoreBrand();
            }}
          >
            <p className="brand-title shoplisttitle">ბრენდი</p>
            <ChevronDown
              className={`duration-200 ${moreBrand ? "rotate-180" : ""}`}
            />
          </div>
          <div
            className={`h-auto flex flex-col gap-y-[5px] ${
              moreBrand ? "flex flex-col" : "hidden checkBoxDiv"
            }`}
          >
            {branddata.map((item) => (
              <div className="flex gap-[5px]">
                <input type="checkbox" name="a"></input>
                <label htmlFor="a" className="text-[15px]">
                  {item.brand}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="price  mb-[10px] flex flex-col gap-y-[8px]">
          <div
            className="flex justify-between items-center border-b-[1px] pb-[3px]"
            onClick={() => {
              handlemoreSize();
            }}
          >
            <p className="brand-title shoplisttitle">ზომა</p>
            <ChevronDown
              className={`duration-200 ${moreSize ? "rotate-180" : ""}`}
            />
          </div>
          <div
            className={`h-auto flex flex-col gap-y-[5px] ${
              moreSize ? "flex flex-col" : "hidden"
            }`}
          >
            <div className="w-full flex flex-wrap gap-[5px]">
              {sizedata.map((item) => (
                <div className=" border-[1px] border-gray-200 px-[5px] py-[2px] rounded-[8px] text-[11px] cursor-pointer">
                  <p>{item.size}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-[8px]">
          <div
            className="flex justify-between items-center border-b-[1px] pb-[3px]"
            onClick={() => {
              handlemoreColor();
            }}
          >
            <p className="brand-title shoplisttitle">ფერი</p>
            <ChevronDown
              className={`duration-200 ${moreColor ? "rotate-180" : ""}`}
            />
          </div>
          <div
            className={`h-auto flex flex-col gap-y-[5px] ${
              moreColor ? "flex flex-col" : "hidden"
            }`}
          >
            {colordata.map((item) => (
              <div className="flex gap-[5px] ">
                <input type="checkbox" name="aa"></input>
                <label className="text-[15px]">{item.color}</label>
              </div>
            ))}
          </div>
        </div>

        <div
        onClick={handleShowSrch}
          className="w-[140px] py-[10px] mt-[10px] rounded-[33px] 
            cursor-pointer flex justify-center items-center mainBg text-white text-[13px]"
        >
          <h2 className="">გასუფთავება</h2>
        </div>
      </div>
    </div>
  );
}

export default FavSrchDrop;
