import { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import {
  CaretDown,
  CurrencyDollar,
  CurrencyExchange,
  GlobeAmericas,
  X,
  XLg,
} from "react-bootstrap-icons";

import { Link } from "react-router-dom";
import CategoryData from "../../contexts/CategoryData";
import { BiChevronRight } from "react-icons/bi";
import SubCategory from "../../categoryPopUpFolder/SubCategory";

export default function InLoggedMenuList({ handleShowMenu,setShowMenu }) {
  const outdropedRef = useRef(null);
  const outdropRef = useRef(null);
  const [showCategory,setShowCategory] = useState(false)
  const categoryData = useContext(CategoryData)
  const [subCategoryData,setSubCategoryData] = useState([])
  const [isShowSubCategory,setIsShowSubCategory] = useState(false)


  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (outdropedRef.current && !outdropedRef.current.contains(event.target)) {
      setDroped(false);
    }
    if (outdropRef.current && !outdropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }

  const [droped, setDroped] = useState(false);
  const [drop, setDrop] = useState(false);

  const handleDroped = () => {
    setDroped((pre) => !pre);
  };

  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  const [clicked, setClicked] = useState("ენები");
  const [click, setClick] = useState("ვალუტა");

  return (
    <div className="h-[100vh] flex flex-col overflow-y-auto  pt-5">
      {
        showCategory ?

        (isShowSubCategory  ? 
          <SubCategory subCategoryData={subCategoryData} setIsShowSubCategory={setIsShowSubCategory} handleShowMenu={handleShowMenu} setShowMenu={setShowMenu}/>
          :
          <ul className='py-2'>
            <div className="flex w-full h-auto  justify-end pb-2">
              <div 
                onClick={() => setShowCategory((pre) => !pre)} 
                  className="w-[35px] h-[35px] cursor-pointer rounded-full flex items-center bg-gray-100 justify-center">
                <XLg />
              </div>
            </div>
            {categoryData.categoryData.map((item) => (
              <li
                key={item.id}
                onClick={() => {setSubCategoryData(item); setIsShowSubCategory(true)}} 
                className={` group ${item.id === categoryData.categoryData.length ? '': "border-b-2 border-slate-200"} flex justify-between items-center  `}>
                  <h1 className="css-elipses text-[13px] py-[14px] ">{item.text}</h1>
                  {
                    item.subCategory && 
                    <div>
                      <BiChevronRight className='text-[20px] mb-2'/> 
                    </div>
                  }
              </li>
            ))} 
          </ul>
          )
                
        :
        <ul className="flex flex-col pb-[16px] gap-[5px]">
          <div className="flex justify-end">
            <div 
            onClick={() => {
                  handleShowMenu();
                }} 
                className="w-[35px] h-[35px] cursor-pointer rounded-full flex items-center bg-gray-100 justify-center">
              <XLg />
            </div>
          </div>
          <Link to={"/"}>
            <li
              className="headerli flex items-center gap-[5px]  cursor-pointer border-b-2 text-[14px] py-2 border-slate-200"
              onClick={() => {
                handleShowMenu();
              }}
            >
              მთავარი
            </li>
          </Link>
          <Link to={"allBlogs"}>
            <li
              className="headerli flex items-center gap-[5px]  cursor-pointer  border-b-2 text-[14px] py-2 border-slate-200"
              onClick={() => {
                handleShowMenu();
              }}
            >
              ბლოგი
            </li>
          </Link>
          <Link to={"aboutus"}>
            <li
              className="headerli flex items-center gap-[5px]  cursor-pointer  border-b-2 text-[14px] py-2 border-slate-200"
              onClick={() => {
                handleShowMenu();
              }}
            >
              ჩვენს შესახებ
            </li>
          </Link>
          <Link
            to="contactPage"
            onClick={() => {
              handleShowMenu();
            }}
          >
            <li className="headerli flex items-center gap-[5px]  cursor-pointer  border-b-2 text-[14px] py-2 border-slate-200">
              კონტაქტი
            </li>
          </Link>
          {/* <Link
            to="contactPage"
            // onClick={() => {
            //   handleShowMenu();
            // }}
          > */}
            <li
              onClick={() => setShowCategory((pre) => !pre)} 
              className="headerli flex items-center gap-[5px]  cursor-pointer  text-[14px] py-2">
              კატეგორიები
            </li>
          {/* </Link> */}
        </ul>
      }

      <div className="flex flex-col gap-y-[10px]">
        <div ref={outdropedRef} className="">
          <div
            className={`p-[15px] flex justify-between items-center cursor-pointer border-[1px] rounded-[10px] duration-150 ${
              droped ? "border-black" : ""
            }`}
            onClick={handleDroped}
          >
            <div className="flex justify-between items-center gap-[5px]">
              <div>
                <GlobeAmericas />
              </div>
              <p>{clicked}</p>
            </div>
            <div className="flex items-center gap-[10px]">
              <X
                className={`text-[red] ${
                  clicked != "ენები" ? "flex" : "hidden"
                }`}
                onClick={() => {
                  setClicked("ენები");
                }}
              />
              <CaretDown
                className={`duration-200 ${droped ? "rotate-180" : ""}`}
              />
            </div>
          </div>

          <div
            className={`filterScrlbar myshadow  top-[58px] w-[100%] overflow-auto rounded-b-[10px] duration-200 ${
              droped ? "h-auto" : "h-0"
            }`}
          >
            <ul className="w-[100%] ">
              <li
                className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("English");
                }}
              >
                English
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("ქართული");
                }}
              >
                ქართული
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("Русский");
                }}
              >
                Русский
              </li>
            </ul>
          </div>
        </div>

        <div ref={outdropRef} className="relative mb-[13%]">
          <div
            className={`p-[15px] flex justify-between items-center cursor-pointer border-[1px] rounded-[10px] duration-150 ${
              drop ? "border-black" : ""
            }`}
            onClick={handleDrop}
          >
            <div className="flex justify-between items-center gap-[5px]">
              <div className={`${click === "ვალუტა" ? "flex" : "hidden"}`}>
                <CurrencyExchange />
              </div>
              <div className={`${click === "USD" ? "flex" : "hidden"}`}>
                <CurrencyDollar className="text-[20px] pb-[2px]" />
              </div>
              <div className={`${click === "ლარი" ? "flex" : "hidden"}`}>
                <p>₾</p>
              </div>
              <p>{click}</p>
            </div>
            <div className="flex items-center gap-[10px]">
              <X
                className={`text-[red] ${
                  click != "ვალუტა" ? "flex" : "hidden"
                }`}
                onClick={() => {
                  setClick("ვალუტა");
                }}
              />
              <CaretDown
                className={`duration-200 ${drop ? "rotate-180" : ""}`}
              />
            </div>
          </div>

          <div
            className={`filterScrlbar myshadow  w-[100%] overflow-auto bg-white rounded-b-[10px] ${
              drop ? "h-auto" : "h-0"
            }`}
          >
            <ul className="w-[100%]">
              <li
                className="px-[15px] py-[5px] flex hover:bg-[#fef4f3] cursor-pointer"
                onClick={() => {
                  handleDrop();
                  setClick("USD");
                }}
              >
                <CurrencyDollar className="text-[20px] pb-[2px]" /> USD
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#fef4f3] cursor-pointer"
                onClick={() => {
                  handleDrop();
                  setClick("ლარი");
                }}
              >
                ₾ ლარი
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
