import { React, useContext, useEffect, useRef, useState } from "react";
import CartPopUpShow from "./contexts/CartPopUpShow";
import { Link } from "react-router-dom";
import CartData from "./contexts/CartData";
import ActiveMoney from "./contexts/activeMoney";
import { XLg } from "react-bootstrap-icons";
import CartPopUpItemComponent from "./CartPopUpItemComponent";

const CartPopUp = ({ cartRef }) => {
  const cartPopUpShow = useContext(CartPopUpShow);
  const cartData = useContext(CartData);
  const activeMoney = useContext(ActiveMoney);
  const [allPrice, setAllPrice] = useState();
  const clearPopUpData = () => {
    const newCart = [];
    cartData.setCartData(newCart);
    localStorage.setItem("items", JSON.stringify(newCart));
  };
  useEffect(() => {
    setAllPrice(0);
    cartData.cartData.map((item) => {
      if (item.sale) {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice(
            (prev) =>
              prev +
              (item.price - (item.price / 100) * item.sale) * item.counter
          );
        } else {
          return setAllPrice(
            (prev) =>
              prev +
              (item.dolPrice - (item.dolPrice / 100) * item.sale) * item.counter
          );
        }
      } else {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice((prev) => prev + item.price * item.counter);
        } else {
          return setAllPrice((prev) => prev + item.dolPrice * item.counter);
        }
      }
    });
  }, [cartData]);

  return (
    <div
      className="w-full h-screen overflow-y-auto pb-1 bg-[#000000bf]  fixed top-0 flex
      justify-end z-[100] "
    >
      <div
        ref={cartRef}
        className="w-[350px] h-full  bg-white rounded-l-md flex flex-col 
      relative"
      >
        <div className="flex justify-between items-center px-3 py-5 border-b-[1px]">
          <h1 className="pt-[5px]">კალათა</h1>

          <div
            onClick={() => cartPopUpShow.setCartPopUpShow(false)}
            className="w-[40px] h-[40px] bg-slate-100 items-center cursor-pointer justify-center flex  rounded-full"
          >
            <XLg className='text-black' />
          </div>
        </div>

        <div className="dropcartpanelcontent w-full h-[calc(100vh-136px-80px-60px)] overflow-auto flex flex-col ">
          {cartData.cartData.map((item) => (
            <CartPopUpItemComponent item={item} />
          ))}
        </div>

        <div className="absolute bottom-0 py-16 w-full ">
          <div
            className="border-b-[1px] w-full flex justify-between px-3 
          items-center h-[60px]"
          >
            <h1>ჯამი:</h1>
            <p className="text-[17px]">
              {activeMoney.activeMoney === "lari"
                ? `${allPrice}₾`
                : `${allPrice}$`}
            </p>
          </div>
          <div className="p-3 flex flex-col gap-3">
            <Link to="/cart">
              <div
                onClick={() => cartPopUpShow.setCartPopUpShow(false)}
                className="w-full h-[50px] hover:bg-[#01012527] text-[14px] duration-200 bg-[#0101253b] cursor-pointer rounded-[33px] flex items-center justify-center"
              >
                <h1>კალათის ნახვა</h1>
              </div>
            </Link>
            <Link to="/checkout">
              <div
                onClick={() => cartPopUpShow.setCartPopUpShow(false)}
                className="w-full h-[50px] bg-[#010125] text-[14px] hover:bg-[#010125af] duration-200 text-white cursor-pointer rounded-[33px] flex items-center justify-center"
              >
                <h1>შეკვეთის გაფორმება</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopUp;
