import AdvertImg from "../../../imgs/9.webp";
import CountUp from "react-countup/build";
import ScrollTrigger from "react-scroll-trigger";
import { useState } from "react";

function ExpAdvert() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <div className="experience-part items-center flex mt-[50px] mb-[30px] justify-center bg-[#f4f4f4]">
      <div className="exper-adv max-w-[1526px] px-[110px] w-[100%] h-[200px] relative rounded-[15px]">
        <img src={AdvertImg} className="w-[100%] h-[100%]  object-cover rounded-[15px]" alt="" />
        <div className="exper-adv-in w-[100%] h-[100%] absolute top-0 left-0 px-[110px]">
          <div className="advert-experience w-[100%] h-[100%] rounded-[15px] flex items-center justify-evenly">
            <div className="w-[100%] flex justify-center ">
              <div className="one-advert  border-l-[3px] border-white text-white pl-[10px] flex flex-col justify-center ">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(true)}
                >
                  <p className="adv-up-txt text-[40px] items-center mt-[-5px] mb-[5px] h-[40px]">
                    {counterOn && (
                      <CountUp className="exper-num" start={0} end={28} duration={1} delay={0} />
                    )}
                  </p>
                </ScrollTrigger>
                <p className="exper-text">
                  წლიანი <br /> გამოცდილება
                </p>
              </div>
            </div>
            <div className="w-[100%] flex justify-center ">
              <div className="one-advert  border-l-[3px] border-white text-white pl-[10px] flex flex-col justify-center ">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(true)}
                >
                  <p className="adv-up-txt text-[40px] items-center mt-[-5px] mb-[5px] h-[40px]">
                    {counterOn && (
                      <CountUp className="exper-num" start={0} end={17} duration={1} delay={0} />
                    )}
                  </p>
                </ScrollTrigger>
                <p className="exper-text">
                  წლიანი <br /> გამოცდილება
                </p>
              </div>
            </div>
            <div className="w-[100%] flex justify-center ">
              <div className="one-advert  border-l-[3px] border-white text-white pl-[10px] flex flex-col justify-center ">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(true)}
                >
                  <p className="adv-up-txt text-[40px] items-center mt-[-5px] mb-[5px] h-[40px]">
                    {counterOn && (
                      <CountUp className="exper-num" start={0} end={35} duration={1} delay={0} />
                    )}
                  </p>
                </ScrollTrigger>
                <p className="exper-text">
                  წლიანი <br /> გამოცდილება
                </p>
              </div>
            </div>
            <div className="w-[100%] flex justify-center ">
              <div className="one-advert  border-l-[3px] border-white text-white pl-[10px] flex flex-col justify-center ">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(true)}
                >
                  <p className="adv-up-txt text-[40px] items-center mt-[-5px] mb-[5px] h-[40px]">
                    {counterOn && (
                      <CountUp className="exper-num" start={0} end={18} duration={1} delay={0} />
                    )}
                  </p>
                </ScrollTrigger>
                <p className="exper-text">
                  წლიანი <br /> გამოცდილება
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpAdvert;
