import { useState } from "react";
import { At, Eye, EyeSlash, Geo, Lock, Person, Telephone } from "react-bootstrap-icons";

function SecRegistration({ openreg, handleSecaut }) {
  const [show, setshow] = useState(false);
  const [valueData,setValueData] = useState({
    firstName:'',
    isFirstNameValueError:false,
    surname:'',
    isSurnameValueError:false,
    email:'',
    isEmailValueError:false,
    mobNumber:'',
    isMobNumberValueError:false,
    address:'',
    isAddressValueError:false,
    passwordValue:'',
    isPasswordValueError:false,
    isPasswordValueError1:false,
    passwordValue2:'',
    isPasswordValue2Error:false,
  })
  const handelChange = (e) => {
    let newValueData={...valueData}
    if(e.target.name==='firstName' ){
      if(e.target.value !== ''){
        newValueData.isFirstNameValueError=false
      }
    }
    if(e.target.name==='surname' ){
      if(e.target.value !== ''){
        newValueData.isSurnameValueError=false
      }
    }
    if(e.target.name==='mobNumber' ){
      if(e.target.value !== ''){
        newValueData.isMobNumberValueError=false
      }
    }
    if(e.target.name==='address' ){
      if(e.target.value !== ''){
        newValueData.isAddressValueError=false
      }
    }
    if(e.target.name === 'email'){
      const re = /\S+@\S+\.\S+/.test(e.target.value)
      if(newValueData.email.length === 0 || !re){
        newValueData.isEmailValueError=true
      }
      else{
        newValueData.isEmailValueError=false
      }
    }
    if(e.target.name === 'passwordValue' ){
      if(e.target.value.length < 8){
        if(!(e.target.value.charAt(0) === e.target.value.charAt(0).toUpperCase())){
          newValueData={...newValueData, isPasswordValueError: true, isPasswordValueValueError1: true}
        }
        else{
          newValueData={...newValueData, isPasswordValueError: true, isPasswordValueValueError1: false}
        }
      }
      else{
        if(!(e.target.value.charAt(0) === e.target.value.charAt(0).toUpperCase())){
          newValueData={...newValueData, isPasswordValueError: false,isPasswordValueError1: true}
        }
        else{
          newValueData={...newValueData, isPasswordValueError: false,isPasswordValueError1: false}
        }
      }
    }
    if(e.target.name === 'passwordValue2'){
      if(valueData.passwordValue !== e.target.value){
        newValueData={...newValueData, isPasswordValue2Error: true}
      }
      else{
        newValueData={...newValueData,  isPasswordValue2Error: false }
      }
    }
    newValueData={...newValueData, [e.target.name]:e.target.value}
    setValueData(newValueData)
  }
  const handelSubmit = () => {
    const newValueData={...valueData}
    if(newValueData.firstName ===''){
      newValueData.isFirstNameValueError=true
    }
    if(newValueData.surname ===''){
      newValueData.isSurnameValueError=true
    }
    if(newValueData.mobNumber ===''){
      newValueData.isMobNumberValueError=true
    }
    if(newValueData.address ===''){
      newValueData.isAddressValueError=true
    }
    if(newValueData.email === ''){
      newValueData.isEmailValueError=true
    }
    if(newValueData.passwordValue ===''){
      newValueData.isPasswordValueError=true
      newValueData.isPasswordValueError1=true
    }
    if(newValueData.passwordValue2 !== newValueData.passwordValue || newValueData.passwordValue2 ==='' ){
      newValueData.isPasswordValue2Error=true
    }
    
    setValueData(newValueData)
    if((!newValueData.isEmailValueError) &&(!newValueData.isAddressValueError) &&(!newValueData.isMobNumberValueError) &&(!newValueData.isSurnameValueError) &&(!newValueData.isFirstNameValueError) && (!newValueData.isPasswordValueError) 
    && (!newValueData.isPasswordValueError1) && (!newValueData.isPasswordValue2Error)){
        handleSecaut()
    }
    else{
      console.log('error')
    }
    console.log(newValueData)
  }
  const handleshow = () => {
    setshow((pre) => !pre);
  };
  return (
    <div
      className="secRegpop w-[100%] flex flex-col gap-[10px] px-[18%]"
    >
      <div className="secnames flex gap-[10px]">
        <div className={`secfirstname flex border-2  ${valueData.isFirstNameValueError && 'border-red-600'} rounded-[16px] w-[50%] pr-[7px] h-[50px] text-gray-500 items-center`}>
          <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
            <Person className="text-[20px]" />
          </div>
          <input
            onChange={(e) => handelChange(e)}
            value={valueData.firstName}
            name='firstName'
            type="text"
            className="secinp outline-none w-[80%] pb-[5px]"
            placeholder="შეიყვანეთ სახელი"
          />
        </div>
        <div className={`seclastname flex border-2 ${valueData.isSurnameValueError && 'border-red-600'} rounded-[16px] w-[50%] pr-[7px] h-[50px] text-gray-500 items-center`}>
          <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
            <Person className="text-[20px]" />
          </div>
          <input
            onChange={(e) => handelChange(e)}
            value={valueData.surname}
            name='surname'
            type="text"
            className="secinp outline-none w-[80%] pb-[5px]"
            placeholder="შეიყვანეთ გვარი"
          />
        </div>
      </div>
      <div className='flex flex-col gap-1 '>
        <div className={`flex border-2 rounded-[16px] ${valueData.isEmailValueError && 'border-red-600'} w-[100%] pr-[7px] h-[50px] text-gray-500 items-center`}>
          <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
            <At className="text-[20px]" />
          </div>
          <input
            onChange={(e) => handelChange(e)}
            value={valueData.email}
            type="text"
            name='email'
            className="secinp outline-none w-[80%] pb-[5px]"
            placeholder="ელ-ფოსტა"
          />
        </div>
        {valueData.isEmailValueError && <p className='text-center text-red-600 text-[11px]'>მაილი არასწორია</p>}
      </div>
      <div className="sectlmis flex gap-[10px]">
        <div className={`sectel flex border-2 ${valueData.isMobNumberValueError && 'border-red-600'} rounded-[16px] w-[100%] pr-[7px] h-[50px] text-gray-500 items-center`}>
          <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
            <Telephone className="text-[20px]" />
          </div>
          <input
            onChange={(e) => handelChange(e)}
            value={valueData.mobNumber}
            type="number"
            name='mobNumber'
            className="secinp outline-none w-[80%] pb-[5px]"
            placeholder="შეიყვანეთ ტელ ნომერი"
          />
        </div>
        <div className={`secmisamarti flex border-2 ${valueData.isAddressValueError && 'border-red-600'} rounded-[16px] w-[100%] pr-[7px] h-[50px] text-gray-500 items-center`}>
          <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
            <Geo className="text-[20px]" />
          </div>
          <input
            onChange={(e) => handelChange(e)}
            value={valueData.address}
            type="text"
            name='address'
            className="secinp outline-none w-[80%] pb-[5px]"
            placeholder="შეიყვანეთ მისამართი"
          />
        </div>
      </div>
      <div className="flex flex-col gap-[10px]">
        <div className='flex flex-col gap-1'>
          <div className={`flex border-2 ${(valueData.isPasswordValueError || valueData.isPasswordValueError1) && 'border-red-600'} rounded-[16px] w-[100%] pr-[7px] h-[50px] text-gray-500 items-center`}>
            <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
              <Lock className="text-[20px]" />
            </div>
            <input
              onChange={(e) => handelChange(e)}
              value={valueData.passwordValue}
              type={show ? "text" : "password"}
              name="passwordValue"
              id="password"
              className="secinp outline-none w-[80%] pb-[5px]"
              placeholder="პაროლი"
            />
            <div onClick={handleshow} className="text-[20px] text-[#010125]">
              {show ? (
                <Eye className="cursor-pointer" />
              ) : (
                <EyeSlash className="cursor-pointer" />
              )}
            </div>
          </div>
          {
              valueData.isPasswordValueError && 
              <p className="font-semibold text-red-600 text-center text-[12px]">ახალი პაროლი უნდა იყო მინიმუმ 8 სიმბოლო</p>
            }
            {
              valueData.isPasswordValueError1 && 
              <p className="font-semibold text-red-600 text-center text-[12px]">ახალი პაროლის პირველი სიმბოლო უნდა იყოს დიდი</p>
            }
        </div>
        <div className='flex flex-col gap-1'>
          <div className={`flex border-2  ${valueData.isPasswordValue2Error && 'border-red-600'} rounded-[16px] w-[100%] pr-[7px] h-[50px] text-gray-500 items-center`}>
            <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
              <Lock className="text-[20px]" />
            </div>
            <input
              onChange={(e) => handelChange(e)}
              value={valueData.passwordValue2}
              type={show ? "text" : "password"}
              name="passwordValue2"
              id="password"
              className="secinp outline-none w-[80%] pb-[5px]"
              placeholder="გაიმეორე პაროლი"
            />
            <div onClick={handleshow} className="text-[20px] text-[#010125]">
              {show ? (
                <Eye className="cursor-pointer" />
              ) : (
                <EyeSlash className="cursor-pointer" />
              )}
            </div>
          </div>
          {
            valueData.isPasswordValue2Error && 
            <p className="font-semibold text-red-600 text-[12px] text-center">სწორად გაიმეორე პაროლი</p>
          }
        </div>
      </div>
      <div>
        <div
          onClick={() => handelSubmit()}
          className="w-[100%] h-[50px] cursor-pointer flex items-center justify-center mainBg text-white rounded-[16px]"
        >
          <h1>დადასტურება</h1>
        </div>
      </div>
    </div>
  );
}
export default SecRegistration;
