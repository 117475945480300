function TypeComment({
  handleSubmit,
  name,
  handleNameChange,
  lastName,
  handleLastNameChange,
  comment,
  handleCommentChange,
}) {
  return (
    <div className="typecomentprod w-[50%] bg-white shadow-sm rounded-[10px] px-[20px]">
      <h1 className="sendcomment border-b-[1px] text-[20px] pt-[15px] pb-[10px]">
        დატოვე კომენტარი
      </h1>
      <div className="commentinputs flex flex-col gap-[20px] py-[20px]">
        <form onSubmit={handleSubmit}>
          <div className="personinfomessage flex gap-[30px]">
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              className="messaginput w-[50%] outline-none border-b-[1px]   px-[5px] pt-[5px] pb-[8px]"
              placeholder="თქვენი სახელი"
            />
            <input
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              className="messaginput w-[50%] outline-none  border-b-[1px]   px-[5px] pt-[5px] pb-[8px]"
              placeholder="თქვენი გვარი"
            />
          </div>
          <textarea
            type="text"
            value={comment}
            onChange={handleCommentChange}
            className="typetextarea w-[100%] min-h-[150px] outline-none  border-b-[1px]   p-[8px]"
            placeholder="რას ფიქრობ?"
          ></textarea>
          <div className="max-[540px]:w-full max-[540px]:justify-center flex">
            <button
              className="w-[200px] h-[45px] text-white cursor-pointer items-center flex justify-center bg-[#010125] rounded-[30px] text-[14px]"
              type="submit"
            >
              <h1 className="">გაგზავნა</h1>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TypeComment;
