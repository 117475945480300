import {
  Cart,
  CartFill,
  Eye,
  EyeFill,
  Heart,
  HeartFill,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Counter from "../slider/prodcounter";
import { useContext, useState, useEffect } from "react";
import CartData from "../../contexts/CartData";
import WishlistData from "../../contexts/WishlistData";
import ActiveMoney from "../../contexts/activeMoney";
import useFunction from "../../hooks/useFunction";

function SaleHorizontalCard({
  item,
  img,
  title,
  price,
  sale,
  index,
  handleShowProdPopup,
  handlePopupDataChange,
  saleis,
  empty,
}) {
  const newCartData = useContext(CartData);
  const wishlistData = useContext(WishlistData);
  const activeMoney = useContext(ActiveMoney);
  const [counternum, setCounternum] = useState(0);
  const [addCart, handelHeart] = useFunction();
  const [test1, setTest1] = useState(1);
  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData]);

  const [hearted, setHearted] = useState(false);

  const handleHearted = () => {
    setHearted((pre) => !pre);
  };

  return (
    <div className="w-full flex justify-between p-[10px] rounded-[18px] bg-white relative">
      
      {sale && (
          <div
            className="absolute left-[8px] top-[8px] px-[8px] h-[25px] bg-[red] rounded-[20px]
           text-white text-[12px] flex items-center justify-center pt-[2px]
          "
          >
            <p>-{sale}%</p>
          </div>
        )}
      <div className="horiz-pht flex items-center gap-[10px]">
        <div className="horiz-img h-[150px] w-[200px] cursor-pointer flex items-center justify-center">
          <img
            src={img}
            className="w-[100%] h-[100%] object-contain"
            alt=""
          ></img>
        </div>
        <Link to={`/product/${item.id}`}>
          <p className="prod-name cursor-pointer">{title}</p>
        </Link>
      </div>

      <div className="horiz-rightinf flex flex-col items-end gap-[15px] justify-center w-[200px] max-[490px]:w-[190px]">
        <div className="flex gap-[10px] items-center">
          {item.sale ? (
            <div className="salecrds flex gap-[5px] items-center ">
              <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                {activeMoney.activeMoney === "lari"
                  ? `${price - (price / 100) * item.sale}₾`
                  : `${item.dolPrice - (item.dolPrice / 100) * item.sale}$`}
              </p>

              <p className="downtexti line-through text-[12px] pt-[5px]">
                {activeMoney.activeMoney === "lari"
                  ? `${price}₾`
                  : `${item.dolPrice}$`}
              </p>
            </div>
          ) : (
            <div className="salecrds flex gap-[5px] items-center ">
              <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                {activeMoney.activeMoney === "lari"
                  ? `${price}₾`
                  : `${item.dolPrice}$`}
              </p>
            </div>
          )}
        </div>
        
        <div className="w-[65%]">
          <Counter
            price={price}
            index={index}
            width={true}
            // counternum={counternum}
            setCounternum={setCounternum}
            test1={test1}
          />
        </div>

        <div className="flex justify-between items-center gap-[5px] w-[100%]">
          <div
            className="w-[30px] h-[30px] rounded-full flex items-center justify-center mainBg text-white"
            onClick={() => {
              handelHeart(item);
            }}
          >
            {hearted ? <HeartFill /> : <Heart />}
          </div>
          <div
            className="mainBg cursor-pointer text-white w-[30px] h-[30px] flex justify-center items-center rounded-full"
            onClick={() => {
              handlePopupDataChange(item, counternum);
              handleShowProdPopup();
            }}
          >
            <EyeFill />
          </div>
          {empty ? (
            <div
              onClick={() => {
                addCart(item, counternum);
                setTest1((prev) => prev + 1);
              }}
              className="   w-[65%] bg-[#010125] duration-200 hover:bg-[green] h-[30px] pb-[3px] gap-[5px] flex justify-center items-center rounded-[33px] text-white cursor-pointer text-[13px]"
            >
              <Cart className="text-[15px] pt-[1px]" />
              <p className="pt-[2px]">დამატება</p>
            </div>
          ) : (
            <div className="  w-[65%] bg-[#0101254f] h-[30px] pb-[4px] gap-[5px] pt-[5px] flex justify-center items-center pointer-events-none rounded-[33px] text-white cursor-pointer text-[13px]">
              <p>არარის მარაგში</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SaleHorizontalCard;
