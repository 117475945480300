import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css/effect-fade";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Sl1 from "../../../imgs/1.webp";
import Sl2 from "../../../imgs/2.webp";
import {FilterRight } from "react-bootstrap-icons";
import {BiChevronRight} from 'react-icons/bi'
import Hover1 from "./hover/Hover2";
import { useState } from "react";
import { useContext } from "react";
import CategoryData from "../../contexts/CategoryData";
import TopSliderWithText from "../../contexts/TopSliderWithText";
import TopSliderVariations from "../../contexts/topslidervariations";
import BorderRadiusContext from "../../contexts/BorderRadiusContext";
import Hover2 from "./hover/Hover2";
import HoverFullStyle from "./hover/HoverFullStyle";


function MainTopCategoriesAndSlider() {
  let swiperRef = useRef();
  const categoryData = useContext(CategoryData)
  const {borderRadius} = useContext(BorderRadiusContext)
  const topSliderWithText = useContext(TopSliderWithText);
  const topslidervariations = useContext(TopSliderVariations)

  return (
    <div className="flex justify-center mt-[90px] max-[540px]:mt-[70px] w-[100%] bg-[#f4f4f4]">
      <div className="UpSlider pt-[20px] justify-between  px-[110px]  max-w-[1526px] flex items-center gap-[15px] w-full">
      {topslidervariations.topslidervariations === "slider1" && (
        <div className="upslcategtitlelist h-[450px] w-[300px] relative max-[1200px]:hidden">
          <div  className={`w-auto h-[50px] gap-[5px] flex items-center px-[25px] mainBg text-white text-[15px] rounded-t-[15px]`}>
            <FilterRight className="text-[29px] pb-[2px]"/>
            <h1>კატეგორიები</h1>
          </div>
          <div className="upcateglist h-[calc(100%-50px)]  w-full overflow-y-scroll bg-white py-[18px] rounded-b-[15px]" >  
            <ul className="gap-y-[10px] flex flex-col px-4">
              {
                categoryData.categoryData.map((item) => (
                  <li
                    key={item.id} 
                    className=" group border-b-2 border-slate-200 ">
                    {/* <Link to={`/products/${item.textLink}/${item.subCategory[0].title} `} className='flex items-center cursor-pointer group-one text-gray-600 pr-[5px]'> */}
                      <div className='flex items-center cursor-pointer group-one text-gray-600 pr-[5px]'>
                        <h1 className="css-elipses text-[13px] py-[5px] mb-2">{item.text}</h1>
                        <div>
                          <BiChevronRight className='text-[20px] mb-2'/> 
                        </div>
                      </div>
                    {/* </Link> */}
                    {item.style === 'style1' && <Hover2 subCategory={item.subCategory}/>}
                    {item.style === 'style2' && <HoverFullStyle subCategory={item.subCategory}/>}
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      )}
        <div className={` relative ${topslidervariations.topslidervariations === "slider1" ? "w-[calc(100%-300px)] max-[1200px]:w-full" : "w-full"} `}>
          <Swiper
            modules={[Autoplay, EffectFade]}
            slidesPerView={1}
            loop={true}
            className="mySwiper items-center flex rounded-[20px]"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            disabledclass={"disabled_swiper_button"}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            speed={2000}
            cssease={"linear"}
          >
            <SwiperSlide>
              <div className="sl-one w-[100%] h-[450px] min-h-[190px] relative mx-[5px]" >
                <img
                  src={Sl2}
                  alt=""
                  className="w-[100%] h-[100%] object-cover rounded-[15px]"
                ></img>
                {topSliderWithText.topsliderwithtext === "sliderwithtext" && (
                <div className={`top-slider-texts absolute top-0 left-0 h-[100%] w-[70%] 
                 justify-center px-[10%] gap-y-4 flex flex-col ${topSliderWithText.topsliderwithtext === "sliderwithtext" && "max-[1200px]:w-full max-[1200px]:px-[5%]"}`}>
                  <div className="">
                    <h1 className="text-[35px] max-[840px]:text-[26px] font-semibold  max-[574px]:text-[20px] max-[360px]:text-[12px] ">
                      პოპულარული ტექნიკა
                    </h1>
                    <p className="top-sl-par text-[22px]  font-normal">
                      შეიძინე ფასდაკლებით
                    </p>
                  </div>
                  <div className="top-btn">
                  <h1
                      className="top-sl-btn mt-[10px] mainBg px-[10px] h-[40px] max-[574px]:h-[35px]
                   w-[150px] text-[13px] max-[574px]:text-[10px] max-[400px]:w-[110px] max-[400px]:pt-[2px] max-[400px]:h-[25px] flex items-center justify-center rounded-[33px] text-white cursor-pointer pointer-events-auto"
                    >
                      სრულად ნახვა
                    </h1>
                  </div>
                </div>
                )}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sl-two w-[100%] h-[450px]  relative mx-[5px]">
                <img
                  src={Sl1}
                  alt=""
                  className="w-[100%] h-full object-cover rounded-[15px]" 
                ></img>
                {topSliderWithText.topsliderwithtext === "sliderwithtext" && (
                <div className={`top-slider-texts absolute top-0 left-0 h-full w-[70%] max-[750px]:w-full justify-center px-[10%] gap-y-4 flex flex-col ${topSliderWithText.topsliderwithtext === "sliderwithtext" && "max-[1200px]:w-full  max-[1200px]:px-[5%]"}`}>
                  <div className="">
                    <h1 className="text-[35px] max-[840px]:text-[26px] font-semibold  max-[574px]:text-[20px] max-[360px]:text-[12px]">
                      საუკეთესო ავეჯი
                    </h1>
                    <p className="top-sl-par text-[22px] font-normal ">
                      შეიძინეთ ჩვენთან
                    </p>
                  </div>
                  <div className="top-btn">
                    <h1
                      className="top-sl-btn mt-[10px] mainBg px-[10px] h-[40px] max-[574px]:h-[35px]
                   w-[150px] text-[13px] max-[574px]:text-[10px] max-[400px]:w-[110px] max-[400px]:pt-[2px] max-[400px]:h-[25px] flex items-center justify-center rounded-[33px] text-white cursor-pointer pointer-events-auto"
                    >
                      სრულად ნახვა
                    </h1>
                  </div>
                </div>
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default MainTopCategoriesAndSlider;
