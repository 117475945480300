import CardProdMenu from "./prodMenuCard";
import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";
import 'swiper/swiper-bundle.min.css';
import "swiper/css/effect-fade";
import React, { useRef, useContext } from "react";
import { PrimaryContext } from "../../allPages/MainContext";

function ProdMenuCardsList() {

  const myDataslider = useContext(PrimaryContext).myDataslider;
  const [dataslider, setDatasl] = useState(null);

  useEffect(() => {
    setDatasl(myDataslider);
  }, [myDataslider]);
  
  let swiperRef = useRef();

  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  const [perslidenum, setPerslidenum] = useState(0);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] >= 1400) {
      setSlidesToDisplay(5);
      setPerslidenum(4)
    } else if (windowSize[0] <= 1400 && windowSize[0] >= 1200) {
      setSlidesToDisplay(4);
      setPerslidenum(3)
    } else if (windowSize[0] <= 1200 && windowSize[0] >= 860) {
      setSlidesToDisplay(3);
      setPerslidenum(2)

    }
    else {
      setSlidesToDisplay(2);
      setPerslidenum(1)
    }
  }, [windowSize]);

  return (
    <div className="flex justify-center  bg-[#f4f4f4]">
      <div className="prod-menu-part mt-[45px] max-w-[1526px] w-[100%] px-[110px]">
        <div className="top-prod-slider-b flex justify-between w-[100%] pb-[20px]">
          <div className="prod-slider  pt-[20px] w-[100%] max-w-[1519px] relative">

            <Swiper
              modules={[Autoplay, EffectFade, Pagination]}
              slidesPerView={slideToDisplay}
              slidesPerGroup={perslidenum}
              spaceBetween={20}
              pagination={{ clickable: true }}
              loop={true}
              className="mySwiperprod items-center flex rounded-[15px]"
              autoplay={{
                delay: 2000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              disabledclass={"disabled_swiper_button"}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              speed={1500}
              cssease={"linear"}
            >
              {!(dataslider === null) && (
            <>
              {dataslider.map((object) => {
                return (
                  <SwiperSlide key={object.id}>
                    <CardProdMenu
                      img={object.img}
                      title={object.name}
                      index={object.id}
                    />
                  </SwiperSlide>
                );
              })}
            </>
          )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProdMenuCardsList;
