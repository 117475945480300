import React, { useRef, useContext, useState, useEffect } from "react";
import { PrimaryContext } from "../../allPages/MainContext";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css/effect-fade";

import { ArrowRightShort, ArrowLeftShort } from "react-bootstrap-icons";
import SliderComponent from "./ProdSliderComponent";
import { useLocation } from "react-router-dom";

import ProdPopup from "./prodPopup";
import FirstSliderStyleContext from "../../contexts/FirstSliderStyleContext";
import Salecomponent from "./saleComponent";
import SlideComponent3 from "./slidecomponent3";

function SliderProd() {
  let location = useLocation();
  const myData = useContext(PrimaryContext).myData;
  const firstSliderStyleContext = useContext(FirstSliderStyleContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(myData);
  }, [myData]);

  let swiperRef = useRef();

  const [popupData, setPopupData] = useState({});

  const handlePopupDataChange = (item, amount) => {
    setPopupData({ ...item, amount: amount });
  };

  const handlePopupDataAmount = (amount) => {
    setPopupData({ ...popupData, amount: amount });
  };

  const [showProdPopup, setShowProdPopup] = useState(false);

  const handleShowProdPopup = () => {
    setShowProdPopup((prev) => !prev);
  };

  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  let itm;
  useEffect(() => {
    if (windowSize[0] >= 1380) {
      if (location.pathname === "/product") {
        itm = 5;
      } else {
        itm = 5;
      }
      setSlidesToDisplay(itm);
    } else if (windowSize[0] <= 1380 && windowSize[0] >= 1200) {
      if (location.pathname === "/product") {
        itm = 4;
      } else {
        itm = 4;
      }
      setSlidesToDisplay(itm);
    } else if (windowSize[0] <= 1200 && windowSize[0] >= 800) {
      if (location.pathname === "/product") {
        itm = 3;
      } else {
        itm = 3;
      }
      setSlidesToDisplay(itm);
    } else if (windowSize[0] <= 1080 && windowSize[0] >= 0) {
      if (location.pathname === "/product") {
        itm = 2;
      } else {
        itm = 2;
      }
      setSlidesToDisplay(itm);
    } 
  }, [windowSize]);


  useEffect(() => {
    if (showProdPopup) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }, [showProdPopup]);

  return (
    <div
      className={`upsliderout flex justify-center  pb-[20px] w-[100%]  ${
        location.pathname.split("/")[1] === "product"
          ? "rounded-[16px] mt-[0px]"
          : "bg-[#f4f4f4] mt-[60px]"
      } `}
    >
      <div
        className={` pt-[20px] px-[110px]  w-[100%] max-w-[1526px] ${
          location.pathname === "/product" ? "prod-slider-main" : "UpSlider "
        } `}
      >
        <div className="flex items-center mb-[20px] justify-between">
          {location.pathname === "/product" ? (
            <h1 className="prodslideTitle font-semibold text-[21px]">
              მსგავსი პროდუქტები
            </h1>
          ) : (
            <h1 className="prodslideTitle font-semibold text-[21px]">
              ახალი დამატებული
            </h1>
          )}

          <div className="sliderarrows flex gap-[10px]">
            <button
              className="learr h-[45px] w-[45px] mainBg border-2 flex justify-center items-center rounded-full"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <ArrowLeftShort className="larr font-semibold text-white text-[30px]	" />
            </button>
            <button
              className="rearr h-[45px] w-[45px] mainBg border-2 flex justify-center items-center rounded-full"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <ArrowRightShort className="rarr font-semibold text-white text-[30px]" />
            </button>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, EffectFade]}
          slidesPerView={slideToDisplay}
          spaceBetween={20}
          loop= {true}
          className="mySwiper items-center flex"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          disabledclass={"disabled_swiper_button"}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          speed={1300}
          cssease={"linear"}
          
        >
          {!(data === null) && (
            <>
              {data.map((object) => {
                if(firstSliderStyleContext.firstSliderStyleContext === 'style1'){
                  return(
                    <SwiperSlide key={object.id}>
                      <SliderComponent
                        item={object}
                        img={object.img}
                        title={object.name}
                        price={object.price}
                        index={object.id}
                        empty={object.empty}
                        sale={object.sale}
                        showsale={object.showsale}
                        setPopupData={setPopupData}
                        handleShowProdPopup={handleShowProdPopup}
                        handlePopupDataChange={handlePopupDataChange}
                        
                      />
                    </SwiperSlide>
                  )
                }
                if(firstSliderStyleContext.firstSliderStyleContext === 'style2'){
                  return(
                    <SwiperSlide key={object.id}>
                      <Salecomponent
                        item={object}
                        img={object.img}
                        title={object.name}
                        price={object.price}
                        index={object.id}
                        empty={object.empty}
                        sale={object.sale}
                        showsale={object.showsale}
                        setPopupData={setPopupData}
                        handleShowProdPopup={handleShowProdPopup}
                        handlePopupDataChange={handlePopupDataChange}
                      />
                    </SwiperSlide>
                  )
                }
                if(firstSliderStyleContext.firstSliderStyleContext === 'style3'){
                  return(
                    <SwiperSlide key={object.id}>
                      <SlideComponent3
                        item={object}
                        img={object.img}
                        title={object.name}
                        price={object.price}
                        index={object.id}
                        empty={object.empty}
                        sale={object.sale}
                        showsale={object.showsale}
                        setPopupData={setPopupData}
                        handleShowProdPopup={handleShowProdPopup}
                        handlePopupDataChange={handlePopupDataChange}
                        
                      />
                    </SwiperSlide>
                  )
                }
                
              })}
            </>
          )}
        </Swiper>
      </div>
      {showProdPopup && (
        <ProdPopup
          item={popupData}
          handleShowProdPopup={handleShowProdPopup}
          image={popupData.img}
          title={popupData.name}
          price={popupData.price}
          amount={popupData.amount}
          empty={popupData.empty}
          sale={popupData.sale}
          handlePopupDataAmount={handlePopupDataAmount}
        />
      )}
    </div>
  );
}

export default SliderProd;
