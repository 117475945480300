import { Check, XLg } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import {useEffect} from 'react'

function AutorDone({ done, setDone, handleDone }) {
  return (
    <div className="bg-[#000000bf]  w-[100vw] h-[100vh] z-[90] fixed top-0 left-0 items-center flex justify-center">
      <Link to="/profile/profile-orders">
        <div className="w-[100%] h-[100%] absolute" onClick={handleDone}></div>
      </Link>
      <div className="adres-div w-[40%]  z-[99] bg-white rounded-[18px]">
        <div className="adres-title flex items-center justify-between px-[30px] py-[10px] border-b-2 border-gray-200">
          <h1 className="text-[18px]">შესვლა</h1>
          <Link to="/profile/profile-orders">
            <div
              className="w-[40px] h-[40px] items-center cursor-pointer bg-slate-100 justify-center flex   rounded-[10px]"
              onClick={() => {
                handleDone();
              }}
            >
              <XLg className='text-black' />
            </div>
          </Link>
        </div>
        <div className="adresses pt-[30px] pb-[50px] h-[442px] flex  flex-col  items-center">
          <div
            className={`w-[100%] h-[100%] items-center flex-col justify-center gap-[20px] ${
              done ? "flex" : "hidden"
            }`}
          >
            <div className="w-[100px] h-[100px] bg-[green] rounded-full items-center flex justify-center text-white text-[45px]">
              <Check />
            </div>
            <p className="text-center">პაროლი წარმატებით შეიცვალა</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutorDone;
