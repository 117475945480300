import {
  ArrowRight,
  Calendar,
  Cart,
  Heart,
  HeartFill,
  ShieldCheck,
  Shop,
  Truck,
} from "react-bootstrap-icons";
import { SiAmazonpay } from "react-icons/si";
import { GrAmex } from "react-icons/gr";
import { RiVisaLine } from "react-icons/ri";
import { FaGooglePay, FaApplePay } from "react-icons/fa";

import Prodphtzoomslider from "../content/productPageCOntent/productPhtzoomslider";
import ProdBigInformation from "../content/productPageCOntent/prodBigInfo";
import SliderProd from "../content/slider/slider-Prod";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { PrimaryContext } from "./MainContext";
import Counter from "../content/slider/prodcounter";
import useFunction from "../hooks/useFunction";
import WishlistData from "../contexts/WishlistData";
import ProdpageCounter from "../content/productPageCOntent/prodpagecounter";

function ProductPage() {
  const myData = useContext(PrimaryContext).myData;
  const wishlistData = useContext(WishlistData);
  const [exChange, setCurrency] = useState(false);
  const [counternum, setCounternum] = useState(1);
  const [colorDefault, setColorDefault] = useState("red");
  const [sizeDefault, setSizeDefault] = useState("XS");
  const [test1, setTest1] = useState(1);
  const handleCurrency = () => {
    setCurrency((prev) => !prev);
  };
  const params = useParams();
  const { Id } = params;
  const [addCart, handelHeart] = useFunction();
  const [item, setItem] = useState([]);
  useEffect(() => {
    if (myData) {
      const filtered = myData.filter((item) => item.id == Id);
      setItem(filtered[0]);
    }
  }, [myData]);

  const [hearted, setHearted] = useState(false);

  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData]);

  return (
    <div className=" items-center w-[100%] mt-[50px] max-[540px]:mt-[30px] py-[40px] flex flex-col gap-[60px] max-[900px]:gap-[40px] bg-gray-100 ">
      <div
        className="w-full shadow-sm  border-b-[1px] flex
       border-slate-200 items-center justify-center bg-white "
      >
        <div className="w-full flex gap-3 items-center max-w-[1519px] px-[110px] py-[10px] header">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/products">
            <p className="text-[14px]">პროდუქტები</p>
          </Link>
          <p>/</p>
          <p className="text-[14px]">{item && item.name}</p>
        </div>
      </div>
      <div className="pht-inf-txt w-[100%]  max-w-[1519px] px-[110px] gap-[20px] flex justify-between">
        <div className="slidersparti w-[60%] h-[500px] max-lg:h-[400px]">
          <Prodphtzoomslider />
        </div>

        <div className="inf w-[40%] flex flex-col gap-[20px] overflow-y-auto pb-[2px] h-[500px] max-sm:h-auto max-sm:overflow-visible">
          <p className="product-title text-[24px]">საოფისე სავარძელი CF 995</p>
          <p>პროდუქტის კოდი: 631046</p>
          <div className="flex flex-col gap-[20px]">
            <div className="p-[20px] shadow bg-white rounded-[20px] flex flex-col gap-[20px]">
              <div className="flex gap-[10px]">
                <p className="after:content-['₾'] text-[#010125] text-[25px]">
                  {item && item.price}
                </p>
              </div>
              <div className="flex gap-[10px] items-center">
                <p>უფასო მიწოდება მესამე დღეს</p>
              </div>
              <div className="flex flex-col gap-[10px]">
                <p>ზომა:</p>
                <div className="flex flex-wrap gap-[10px]">
                  {(item.size || []).map((item) => (
                    <div
                      onClick={() => {
                        setSizeDefault(item.name);
                      }}
                      className={`w-[80px] h-[30px]  rounded-full border-[1px] flex 
                  items-center justify-center  cursor-pointer duration-200 ${
                    item.name === sizeDefault && "bg-[#010125] text-white"
                  }`}
                    >
                      <p className="text-[13px] pt-[1px]">{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-[10px]">
                <p>ფერი:</p>
                <div className="flex gap-[5px]">
                  {(item.color || []).map((item) => (
                    <div
                      onClick={() => {
                        setColorDefault(item.name);
                      }}
                      className={`w-[30px] h-[30px] rounded-full border-[${colorDefault}] ${
                        colorDefault === item.name && "border-[1px]"
                      } flex 
                  items-center justify-center  cursor-pointer`}
                    >
                      <div
                        className={`w-[85%] h-[85%] bg-[${item.name}] rounded-[33px]`}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center gap-[10px] w-[100%]">
                <div
                  onClick={() => {
                    addCart(item, counternum);
                    setTest1((prev) => prev + 1);
                  }}
                  className="w-[50%] flex gap-[10px] text-[17px] cursor-pointer bg-[#010125]  text-white justify-center items-center h-[50px] rounded-[33px]  max-sm:h-[40px]"
                >
                  <Cart />
                  <p>დამატება</p>
                </div>

                <div className="w-[50%] h-[50px] bg-[#010125a2] cursor-pointer text-[17px] text-white justify-center items-center flex rounded-[33px]  max-sm:h-[40px]">
                  <p>ყიდვა</p>
                </div>
              </div>
              <div className="flex gap-[10px] w-full items-center">
                <div className="w-[100px] ">
                  <ProdpageCounter
                    setCounternum={setCounternum}
                    test1={test1}
                  />
                </div>
                <div
                  className="mainBg cursor-pointer text-white flex pt-[2px] w-[40px] h-[40px] group-hover:flex
             justify-center items-center rounded-full"
                  onClick={() => {
                    handelHeart(item);
                  }}
                >
                  {hearted ? <HeartFill /> : <Heart />}
                </div>
              </div>
              <Link to="/cart">
                <div className="w-full flex gap-[10px] bg-gray-200 text-[17px] text-[#010125] justify-between px-[20px] items-center h-[50px] rounded-[10px] cursor-pointer  max-sm:h-[40px]">
                  <p className=" max-sm:text-[14px]">
                    განვადება თვეში 23 ₾ - დან
                  </p>
                  <ArrowRight />
                </div>
              </Link>
            </div>
            <div className="p-[20px] shadow bg-white rounded-[20px] flex flex-col gap-[20px]">
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-[8px]">
                  <div className="flex gap-[10px] items-center">
                    <Shop className="" />
                    <p className="text-[15px] max-sm:text-[14px]">
                      მაღაზიიდან გატანა
                    </p>
                  </div>
                </div>
                <p className="max-sm:text-[14px] ">უფასო</p>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-[8px]">
                  <div className="flex gap-[10px] items-center">
                    <Truck className="" />
                    <p className="text-[15px] max-sm:text-[14px]">
                      ქალაქის მასშტაბით
                    </p>
                  </div>
                </div>
                <p className="max-sm:text-[14px] ">7₾</p>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-[8px]">
                  <div className="flex gap-[10px] items-center">
                    <Truck className="" />
                    <p className="text-[15px] max-sm:text-[14px]">რეგიონებში</p>
                  </div>
                </div>
                <p className=" max-sm:text-[14px]">12₾ - დან</p>
              </div>
            </div>
          </div>
          <div className="p-[20px] shadow bg-white rounded-[20px] flex flex-col gap-[20px] ">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-[8px]">
                <div className="flex gap-[10px] items-center">
                  <ShieldCheck />
                  <p className="text-[15px] max-sm:text-[14px]">
                    გარანტია 1 წელი
                  </p>
                </div>
              </div>
              <a href="" className=" max-sm:text-[14px]">
                დეტალურად
              </a>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-[8px]">
                <div className="flex gap-[10px] items-center">
                  <Calendar className="" />
                  <p className="text-[15px]  max-sm:text-[14px]">
                    30 დღემდე უფასო დაბრუნება
                  </p>
                </div>
              </div>
              <a href="" className=" max-sm:text-[14px]">
                დეტალურად
              </a>
            </div>
          </div>
          <div className="flex items-center gap-[20px]  max-sm:gap-[0px] max-sm:justify-between">
            <p className=" max-sm:text-[13px]">გადახდის სისტემები: </p>
            <div className="flex gap-[5px]">
              <div className="h-[30px] w-[40px] border border-black bg-[white]  flex items-center justify-center rounded-md  max-sm:w-[30px]">
                <GrAmex className="w-[80%] h-[80%] " />
              </div>
              <div className="h-[30px]  w-[40px] bg-white border border-black flex items-center justify-center rounded-md max-sm:w-[30px]">
                <FaApplePay className="w-[80%] h-[80%] text-black" />
              </div>
              <div className="h-[30px]  w-[40px] bg-white flex items-center justify-center rounded-md border border-black max-sm:w-[30px]">
                <FaGooglePay className="w-[80%] h-[80%]" />
              </div>
              <div className="h-[30px] w-[40px] bg-white flex items-center justify-center rounded-md border border-black max-sm:w-[30px]">
                <SiAmazonpay className="w-[80%] h-[80%]" />
              </div>
              <div className="h-[30px]  w-[40px] bg-white flex items-center justify-center rounded-md border border-black max-sm:w-[30px]">
                <RiVisaLine className="w-[80%] h-[80%] text-blue-950" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProdBigInformation />
      <SliderProd />
    </div>
  );
}

export default ProductPage;
