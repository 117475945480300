import React, { useContext, useState } from "react";
import CategoryData from "../contexts/CategoryData";
import SubCategory from "./categoryCompnents/SubCategory";


const CategoryHoverFull = ({ subCategory,setIsShowHeaderHover }) => {
  const categoryData = useContext(CategoryData);

  return (
    <div
      className="absolute h-auto  w-full rounded-b-[30px]  py-[30px] overflow-hidden
    top-[90px] left-0  px-[150px]  bg-white right-auto  justify-center duration-300"
    >
      <div className="flex w-full h-full px-5">
        {categoryData.categoryData.map((item) => (
          <div
            key={item.id}
            className="flex flex-col flex-1 overflow-hidden  gap-3 "
          >
            <h1 className="text-[16px] ">{item.text}</h1>
            <div className="flex flex-col gap-1">
              {item.subCategory &&
                item.subCategory.map((item1) => (
                  <SubCategory
                    key={item1.id}
                    item1={item1}
                    setIsShowHeaderHover={setIsShowHeaderHover}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryHoverFull;
