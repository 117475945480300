import { Buildings, CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import PickShop from "./pickshop";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import CategoryData from "../../contexts/CategoryData";

function DropListMshop({ handleDroplist }) {
  const categoryData = useContext(CategoryData)
  const [showpickShop, setPickShop] = useState(false);
  const handlePickShop = () => {
    setPickShop((prev) => !prev);
  };

  const [windowSize, setWindowSize] = useState([window.innerWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const pickRef = useRef(null);

  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [windowSize]);

  function handleClickOutside(event) {
    if (pickRef.current && !pickRef.current.contains(event.target)) {
      // click occurred outside of wrapper, close the div here
      setPickShop(false);
    }
  }

  return (
    <div  className="h-full">
      <div
        className="categ-list w-[100%] flex flex-col h-[calc(100vh-90px-50px)] max-[660px]:pb-[80px] max-[540px]:h-[calc(100vh-70px-50px)] top-[90px] border-r pb-[40px]  pt-[25px] bg-[white] overflow-y-scroll"
      >
        <div className="relative flex flex-col items-center" ref={pickRef}>
          <div
            className={`pickshop-btn flex items-center mainBg mx-[20px] justify-between p-[5px]  cursor-pointer w-[90%] ${
              showpickShop ? "rounded-t-[17px] mainBg" : "rounded-[17px]"
            }`}
            onClick={() => {
              handlePickShop();
            }}
          >
            <div className="w-[50px] h-[50px] text-white flex justify-center items-center text-[25px] rounded-full border-2">
              <Buildings />
            </div>
            <div className="text-[14px] text-center">
              <h1 className="filialsindrop text-white">ფილიალი დიღომი</h1>
              <p className="text-white">1551 შეფასება</p>
            </div>
            <div className="w-[23px] h-[40px] flex flex-col justify-center items-center">
              {showpickShop ? (
                <CaretUpFill className="text-white" />
              ) : (
                <CaretDownFill className="text-white" />
              )}
            </div>
          </div>
          {showpickShop && <PickShop handleDroplist={handleDroplist} />}
        </div>
        <ul className="mt-[25px] gap-y-[10px] flex flex-col ">
          {
            categoryData.categoryData.map((item) => (
              <Link to={`/products/${item.textLink}/${item.subCategory[0].title} `} >
                <li className="flex cursor-pointer group-one text-gray-600 pr-[5px]" onClick={()=> {handleDroplist()}}>
                  <div className="w-[5px] group-one-hover:bg-[#010125] mx-[10px] rounded-[16px]"></div>
                  <p className="css-elipses">{item.text}</p>
                </li>
              </Link>
            ))
          } 
        </ul>
      </div>
    </div>
  );
}

export default DropListMshop;
