import { Trash, ArrowLeft } from "react-bootstrap-icons";
import EmptyCartBg from "../content/cart-folder/emptyCartBg";
import FullCardBg from "../content/cart-folder/fullCardBg";
import { useContext } from "react";
import CalculatorCart from "../content/cart-folder/calculatorRight";
import { Link } from "react-router-dom";
import CartData from "../../components/contexts/CartData";

function Cart() {
  const newCartData = useContext(CartData);

  /// clear Cart data
  const clearCart = () => {
    const newCart = [];
    newCartData.setCartData(newCart);
    localStorage.setItem("items", JSON.stringify(newCart));
  };

  return (
    <div className="page-cart items-center justify-center bg-gray-100 flex mt-[90px] max-[540px]:mt-[70px] flex-col">
      <div className="w-full mb-5 shadow-sm  border-b-[1px]  border-slate-200 items-center bg-white">
        <div className='flex gap-3 px-[20px]  py-[10px] items-center '>
          <Link to='/'>
            <p className='text-[14px]'>მთავარი</p>
          </Link>
          <p>/</p>
          <Link to='/favoriteProd'>
            <p className='text-[14px]'>კალათა</p>
          </Link>
        </div>
      </div>
      <div className="cart-page flex w-[100%] max-w-[2100px] px-[20px] gap-[20px] min-h-[86vh]">
        <div className="cart-left w-[70%] my-[20px] pb-[20px] h-[100%]">
          <div className="flex justify-between items-center py-[10px] border-b-2 border-[#efefef]">
            <h1 className="text-[24px] font-semibold	">კალათა</h1>
            <div
              onClick={clearCart}
              className="clear-cart flex items-center gap-[10px] border-2 border-[#efefef] rounded-[33px] px-[20px] py-[10px] cursor-pointer bg-white text-gray-600"
            >
              <Trash />
              <p>კალათის გასუფთავება</p>
            </div>
          </div>
          <div className="calc-respons  flex justify-between items-center border-b-2 border-[#efefef]">
            <Link to="/">
              <div className="cursor-pointer w-[50px] h-[35px] items-center flex justify-start">
                <ArrowLeft className="text-[20px]  " />
              </div>
            </Link>
            <p className="flex gap-[5px]">
              კალათაში დამატებულია{" "}
              <p className="text-black"> {newCartData.cartData.length}</p>{" "}
              პროდუქტი
            </p>
          </div>
          <div className="bg-white box-shadow-style mt-[20px] rounded-[15px] ">
            {newCartData.cartData.length ? <FullCardBg /> : <EmptyCartBg />}
          </div>
        </div>
        <CalculatorCart/>
      </div>
    </div>
  );
}

export default Cart;
