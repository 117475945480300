import { useEffect, useState } from "react";

function CountdownTimer() {
  const [expiryTime, setExpiryTime] = useState("10 sep 2023 16:26:00");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });
  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  useEffect(() => {
    countdownTimer();
  }, []);

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="counter-whole bg-[#010125] rounded-[30px] px-[10px] h-[45px] w-[212px] btn-group flex items-center my-1">
          {expiryTime !== false ? (
            <>
              <div
                type="button"
                className="btn btn-outline-success flex flex-col 
              text-center items-center text-white px-2 
               w-[42px] h-[42px] text-[18px] pt-[3px] gap-y-[5px]"
              >
                <p className="my-2 mt-0">
                  {countdownTime.countdownDays}
                </p>
                <p className="count-time  mt-[-20px] text-[10px]">
                  დღე
                </p>
              </div>
              <p className="text-white text-[18px]">:</p>
              <div
                type="button"
                className="btn btn-outline-success flex flex-col
               text-center items-center text-white px-2 w-[42px] h-[42px] mx-[2px] text-[18px] pt-[3px] gap-y-[6px]"
              >
                <p className=" my-2 mt-0">
                  {countdownTime.countdownHours}
                </p>
                <p className="count-time  mt-[-20px] text-[10px]">
                  საათი
                </p>
              </div>
              <p className="text-white text-[18px]">:</p>
              <div
                type="button"
                className="btn btn-outline-success flex flex-col 
              text-center items-center text-white px-2  
                w-[42px] h-[42px] mr-[2px] text-[18px] pt-[3px] gap-y-[6px]"
              >
                <p className=" my-2 mt-0">
                  {countdownTime.countdownMinutes}
                </p>
                <p className="count-time mt-[-20px] text-[10px]">
                  წუთი
                </p>
              </div>
              <p className="text-white text-[18px]">:</p>
              <div
                type="button"
                className="btn btn-outline-success flex flex-col 
              text-center items-center text-white px-2  w-[42px] h-[42px]  text-[18px] pt-[3px] gap-y-[6px]"
              >
                <p className=" my-2 mt-0">
                  {countdownTime.countdownSeconds}
                </p>
                <p className="count-time mt-[-20px] text-[10px]">
                  წამი
                </p>
              </div>
            </>
          ) : (
            <p className="text-white w-full text-center">აქცია დასრულდა</p>
          )}
        </div>
      </div>
    </div>
  );
}
export default CountdownTimer;
