import { Route, Routes } from "react-router-dom";

import Main from "../allPages/main";
import Cart from "../allPages/cart";

import { PrimaryContext } from "../allPages/MainContext";
import AboutUs from "../allPages/aboutUs";
import Profile from "../allPages/profile";
import ProductPage from "../allPages/productPage";
import Footer from "../footer";
import { useContext, useState, useEffect } from "react";
import FavProds from "../allPages/favoriteProducts";
import ContactPage from "../allPages/contactPage";
import Blog from "../allPages/blog";
import CheckOut from "../allPages/checkout";

import HeaderBotMenu from "../content/dropdown-manu/headerbottommenu";
import Header from "../headers/Header";
import SettingPopUpShow from "../contexts/SettingPopUpShow";
import { Gear } from "react-bootstrap-icons";
import MainProduct from "../content/category/MainProduct";
import CategoryPopUp from "../categoryPopUpFolder/CategoryPopUp";
import IsShowCategoryPopUp from "../contexts/IsShowCategoryPopUp";
import EveryBlog from "../content/blogpagecomponents/everyBlog";

const MainRouter = () => {
  const { scroll } = useContext(PrimaryContext);
  const settingPopUpShow = useContext(SettingPopUpShow)
  const isShowCategoryPopUp = useContext(IsShowCategoryPopUp)

  var length = useContext(PrimaryContext).cartLength;
  const [cartLength, setCartLength] = useState(0);
  useEffect(() => {
    setCartLength(length);
  }, [length]);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const goToTopSmooth = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className={`${scroll && "overflow-hidden h-screen relative"} ${isShowCategoryPopUp.isShowCategoryPopUp && "overflow-hidden h-screen relative"} `}>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="cart" element={<Cart />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="product/:Id" element={<ProductPage />} />
        <Route path="products" element={<MainProduct />} />
        <Route path="products/:category" element={<MainProduct />} />
        <Route path="products/:category/:category" element={<MainProduct />} />
        <Route path="products/:category/:category/:category" element={<MainProduct />} />
        <Route path="favoriteProd" element={<FavProds />} />
        <Route path="contactPage" element={<ContactPage />} />
        <Route path="allBlogs" element={<Blog />} />
        <Route path="allBlogs/:id" element={<EveryBlog />}/>
        
        <Route path="checkout" element={<CheckOut />} />
      </Routes>
      {
        isShowCategoryPopUp.isShowCategoryPopUp &&
        <CategoryPopUp/>
      }
      <div
        onClick={() => settingPopUpShow.setSettingPopUpShow(true)}
        className="w-[50px] h-[50px] justify-center cursor-pointer bottom-10 right-10 rounded-full mainBg text-white text-[26px]  z-[50] fixed flex items-center">
        <Gear className="animate-spin-slow"/>
      </div>
      <Footer />
      <HeaderBotMenu
        goToTopFast={goToTopFast}
        cartLength={cartLength}
        goToTopSmooth={goToTopSmooth}
      />
    </div>
  );
};

export default MainRouter;
