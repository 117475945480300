import { useState, useEffect } from "react";
import { Dash, PlusLg } from "react-bootstrap-icons";

function ProdPopupounter({ setCounternum, counternum }) {
  const [count, setCount] = useState(counternum);

  const handleInc = () => {
    setCount((prev) => prev + 1);
  };

  const handleDec = () => {
    if (count !== 1) {
      setCount((prev) => prev - 1);
    }
  };
  useEffect(() => {
    setCounternum(count);
  }, [count]);

  return (
    <div
      className={`popcount w-full  items-center p-[7px] flex justify-between  h-[35px] rounded-[33px] ${
        count == 0 ? "bg-[#f1f1f5]" : "bg-[#010125] text-white"
      }`}
    >
      <div
        onClick={() => {
          handleDec();
        }}
        className={`whenMoreTwo w-[38%] flex items-center justify-start ${
          count < 1 ? `${count == 0 ? "flex" : "hidden"}` : "block text-white"
        }`}
      >
        <Dash className="text-[23px] cursor-pointer " />
      </div>

      <div className=" w-[56%] mt-[3px] text-[18px] pb-[5px] gap-[5px] items-center flex justify-center">
        {count}
      </div>

      <div
        onClick={() => {
          handleInc();
        }}
        className={`w-[33%] flex justify-end  
        ${count <= 1 ? `${count == 0 ? "" : "text-white"}` : "text-white"}`}
      >
        <PlusLg className="text-[23px] font-semibold  cursor-pointer" />
      </div>
    </div>
  );
}

export default ProdPopupounter;
