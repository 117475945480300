import { XLg } from "react-bootstrap-icons";
import bed from "../../../imgs/8.webp";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import OrdersCounter from "./ordersCounter";
import ActiveMoney from "../../contexts/activeMoney";

function OrderedCard({ item, Id, img, name, price, newCartData, sale }) {
  const activeMoney = useContext(ActiveMoney);
  const [productQuantity, setProductQuantity] = useState(item.counter);

  const handleQuantity = (value, show) => {
    setProductQuantity((prev) => value);
  };
  ///delete cart item
  const handelDelete = (Id) => {
    const newCart = newCartData.cartData.filter((item) => Id !== item.id);
    newCartData.setCartData(newCart);
    localStorage.setItem("items", JSON.stringify(newCart));
  };

  return (
    <div className="w-full flex justify-between border-b-[2px] px-[20px] gap-[20px] max-sm:gap-[10px] relative max-md:py-[16px] max-sm:px-[0px] ">
      <div className='flex justify-center items-center'>
        <div
          onClick={() => handelDelete(Id)}
          className="text-[15px] w-[35px] h-[35px] items-center justify-center rounded-full bg-slate-100 flex cursor-pointer max-md:absolute max-md:top-[16px] max-md:right-[20px] max-sm:right-0"
        >
          <XLg className='text-black'/>
      </div>
      </div>
      <div className="flex  justify-center w-[38%] items-center gap-[15px]  ">
        <Link className="flex items-center w-[50%] max-[770px]:w-full " to={`/product/${item.id}`}>
          <div className="w-[100px] h-[100px] max-[770px]:w-full max-[770px]:h-full  cursor-pointer">
            <img src={img} alt="" className="w-full h-full object-contain" />
          </div>
        </Link>
        <Link className="w-[50%] max-md:hidden" to={`/product/${item.id}`}>
          <h1 className=" cursor-pointer text-[14px] ">{name}</h1>
        </Link>
      </div>

      <div className="flex w-[60%] max-md:w-[70%] max-sm:w-[75%] max-md:flex-col max-md:gap-y-[10px]">
        <h1 className="hidden cursor-pointer text-[14px] max-md:flex">
          {name}
        </h1>
        <div className="flex  justify-center w-[40%] max-md:border-b-[1px] max-md:pb-[8px] items-center max-md:justify-between max-md:w-full">
          <p className="hidden max-md:flex">ფასი</p>

          {item.sale ? (
            <div className="salecrds flex gap-[5px] items-center ">
              <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                {activeMoney.activeMoney === "lari"
                  ? `${price - (price / 100) * item.sale}₾`
                  : `${item.dolPrice - (item.dolPrice / 100) * item.sale}$`}
              </p>

              <p className="downtexti line-through text-[12px] pt-[5px]">
                {activeMoney.activeMoney === "lari"
                  ? `${price}₾`
                  : `${item.dolPrice}$`}
              </p>
            </div>
          ) : (
            <div className="salecrds flex gap-[5px] items-center ">
              <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                {activeMoney.activeMoney === "lari"
                  ? `${price}₾`
                  : `${item.dolPrice}$`}
              </p>
            </div>
          )}
        </div>
        <div className="flex  justify-center w-[35%] max-md:border-b-[1px] max-md:pb-[8px] items-center max-md:justify-between max-md:w-full">
          <p className="hidden max-md:flex">რაოდენობა</p>
          <OrdersCounter
            newCartData={newCartData}
            handelDelete={handelDelete}
            items={item}
            handleQuantity={handleQuantity}
          />
        </div>
        <div className="flex  justify-center w-[25%] items-center max-md:justify-between max-md:w-full">
          <p className="hidden max-md:flex">ჯამი</p>
          {item.sale ? (
            <p>
              {activeMoney.activeMoney === "lari"
                ? `${(price - (price / 100) * item.sale) * item.counter}₾`
                : `${
                    (item.dolPrice - (item.dolPrice / 100) * item.sale) *
                    item.counter
                  }$`}
            </p>
          ) : (
            <p>
              {activeMoney.activeMoney === "lari"
                ? `${price * item.counter}₾`
                : `${item.dolPrice * item.counter}$`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderedCard;
