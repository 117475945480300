import { useEffect, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";

const Dropdown = ({ name, children, defaultState }) => {
  const [slide, setSlide] = useState(false);

  useEffect(() => {
    if (defaultState) {
      setSlide(defaultState);
    }
  }, [defaultState]);

  return (
    <div className="flex flex-col gap-y-[8px]">
      <div
        className="flex justify-between items-center border-b-[1px] pb-[3px] cursor-pointer"
        onClick={() => {
          setSlide((prev) => !prev);
        }}
      >
        <p className="brand-title shoplisttitle">{name}</p>
        <ChevronDown className={`duration-200 ${slide ? "rotate-180" : ""}`} />
      </div>
      <div
        className={`gap-y-[5px] transition-all duration-300
        ${
          slide
            ? "min-h-auto overflow-auto h-auto"
            : "min-h-0 h-0 overflow-hidden"
        }`}
      >
        <div
          className={`w-full flex flex-col transition-all duration-500
        ${slide ? "opacity-100" : "opacity-0"}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
