import { useEffect } from "react";
import OrderedCard from "../cart-folder/orderedcard";
import { useState } from "react";
import { useContext } from "react";
import { PrimaryContext } from "../../allPages/MainContext";
import ProfOrderedCards from "./proforderedcarts";
import { useRef } from "react";
import { CaretDown, X } from "react-bootstrap-icons";
import CartData from '../../contexts/CartData'


export default function ProfYesOrders({setShow}) {
  const cartData = useContext(PrimaryContext).cartData;
  const newCartData = useContext(CartData)
  const [thisData, setThisData] = useState([]);

  useEffect(()=>{
    if(newCartData.cartData.length > 0){
      setShow(true)
    }
    else(
      setShow(false)

    )
  },[newCartData])

  
  useEffect(() => {
    setThisData(cartData);
  }, [cartData]);

  const outdropedRef = useRef(null);

  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (outdropedRef.current && !outdropedRef.current.contains(event.target)) {
      setDroped(false);
    }
  }

  const [droped, setDroped] = useState(false);

  const handleDroped = () => {
    setDroped((pre) => !pre);
  };

  const [clicked, setClicked] = useState("გაფილტვრა");

  return (
    <div className="flex flex-col gap-y-[20px] max-sm:items-center">
      <div className="kalendfiltprof w-full flex justify-end items-center gap-[20px]">
        <div ref={outdropedRef} className="relative w-[135px] filterprof">
          <div
            className={`bg-white cursor-pointer p-[10px] rounded-[15px] h-[50px] justify-between flex w-full ${
              droped ? "" : ""
            }`}
            onClick={handleDroped}
          >
            <div className="flex justify-between items-center gap-[5px]">
              <p>{clicked}</p>
            </div>
            <div className="flex items-center gap-[5px]">
              <X
                className={`text-[red] ${
                  clicked != "გაფილტვრა" ? "flex" : "hidden"
                }`}
                onClick={() => {
                  setClicked("გაფილტვრა");
                }}
              />
              <CaretDown
                className={`duration-200 ${droped ? "rotate-180" : ""}`}
              />
            </div>
          </div>

          <div
            className={`filterScrlbar shadow absolute z-[5] top-[58px] w-[100%] overflow-auto bg-white rounded-[10px] ${
              droped ? "flex" : "hidden"
            }`}
          >
            <ul className="w-[100%]">
              <li
                className="px-[15px] py-[5px] hover:bg-[#01012535] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("1 თვე");
                }}
              >
                1 თვე
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#01012535] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("3 თვე");
                }}
              >
                3 თვე
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#01012535] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("6 თვე");
                }}
              >
                6 თვე
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#01012535] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("9 თვე");
                }}
              >
                9 თვე
              </li>
              <li
                className="px-[15px] py-[5px] hover:bg-[#01012535] cursor-pointer"
                onClick={() => {
                  handleDroped();
                  setClicked("12 თვე");
                }}
              >
                12 თვე
              </li>
            </ul>
          </div>
        </div>

        <div className="profileorderfilter flex gap-[20px] items-center ">
          <div className="dateinp flex items-center gap-[5px]">
            <input
              type="date"
              name=""
              id=""
              className="dateinp bg-white px-[10px] h-[50px] rounded-[15px] outline-none"
            />
            
          </div>
          <p>-</p>
          <div className="dateinp flex items-center gap-[5px]">
            <input
              type="date"
              name=""
              id=""
              className="dateinp bg-white px-[10px] h-[50px] rounded-[15px] outline-none"
            />
            
          </div>
        </div>
      </div>
      <div className="w-full bg-white rounded-[20px]">
        <div className="cart-card-container flex flex-wrap p-[15px] justify-start gap-[1.2%] gap-y-[10px]">
          <div className="w-full flex justify-between border-b-[2px] pt-[8px] pb-[13px] text-[16px] px-[20px]  gap-[20px] max-lg:hidden">
            <h1 className="w-[25%] text-center">პროდუქტი</h1>
            <h1 className="w-[13.75%] text-center ">რაოდენობა</h1>
            <h1 className="w-[13.75%] text-center ">ჯამი</h1>
            <h1 className="w-[20%] text-center ">მისამართი</h1>
            <h1 className="w-[13.75%] text-center  ">კოდი</h1>
            <h1 className="w-[13.75%] text-center ">თარიღი</h1>
          </div>
          {newCartData.cartData.map((object) => {
            return (
              <ProfOrderedCards
                key={object.id}
                img={object.img}
                name={object.name}
                price={object.price}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
