import { useState } from "react";
import ProdComments from "./prodComments";
import StarRatingSystem from "./starRatingSystem";
import TypeComment from "./typecomment";
import LiveDate from "../../livedate";

function ProdScore() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!(name === '') && !(lastName === '') && !(comment ==='')){
      const newComment = {
        date:<LiveDate/>,
        name: name,
        lastName: lastName,
        comment: comment,
      };
      setComments([ newComment,...comments]);
      // Clear the input fields
      setName("");
      setLastName("");
      setComment("");
    }


  };
  return (
    <div className="min-h-[100%] flex flex-col gap-y-[30px]">
      <StarRatingSystem />
      <div className="prodmessagestypes">
        <div className="prodscoreComments w-[100%] flex gap-[20px]">
          <ProdComments comments={comments}/>
          <TypeComment
            handleSubmit={handleSubmit}
            name={name}
            handleNameChange={handleNameChange}
            lastName={lastName}
            handleLastNameChange={handleLastNameChange}
            comment={comment}
            handleCommentChange={handleCommentChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ProdScore;
