import React, { useState, useEffect } from 'react';

const LiveDate = () => {
  const [currentDate, setCurrentDate] = useState(new Date());


  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  return (
    <div>
      <p>{day}/{month}/{year}</p>
    </div>
  );
};

export default LiveDate;