import { Link } from "react-router-dom";

function SmallBlogs({ item, blogimg, blogtitle, blogtime }) {
  return (
    <Link to={`/allBlogs/${item.id}`}>
      <div className="flex border-b-[2px] p-[10px] items-center gap-[10px] ">
        <div className="w-[25%] h-[69px] cursor-pointer">
          <img
            src={blogimg}
            className="w-[100%] h-[100%] object-cover rounded-full"
            alt=""
          />
        </div>
        <div className="w-[80%] text-[14px]">
          <p>{blogtime}</p>
          <p className="blogtext cursor-pointer font-semibold">{blogtitle}</p>
        </div>
      </div>
    </Link>
  );
}

export default SmallBlogs;
