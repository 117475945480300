import {
  Basket,
  ChevronDown,
  FilterRight,
  Heart,
  Trash,
} from "react-bootstrap-icons";
import MultiRangeSlider from "../content/mshopContent/multirangeslider";
import { useContext, useState } from "react";
import useScreenSize from "../content/customHooks/useScreenSize";
import FavSrchDrop from "../content/favprodfolder/favsrchdrop";
import SlideComponent3 from "../content/slider/slidecomponent3";
import WishlistData from "../contexts/WishlistData";
import { Link } from "react-router-dom";
import ProdPopup from "../content/slider/prodPopup";
import ProductStyleContext from "../contexts/ProductStyleContext";
import SliderComponent from "../content/slider/ProdSliderComponent";
import Salecomponent from "../content/slider/saleComponent";

function FavProds() {
  let windowWidth = useScreenSize();
  const productStyleContext = useContext(ProductStyleContext)
  const wishlistData = useContext(WishlistData);
  const [showProdPopup, setShowProdPopup] = useState(false);

  const handleShowProdPopup = () => {
    setShowProdPopup((prev) => !prev);
  };

  const [showSrch, setShowSrch] = useState(false);

  const handleShowSrch = () => {
    setShowSrch((prev) => !prev);
  };
  const [popupData, setPopupData] = useState({});

  const handlePopupDataChange = (item, amount) => {
    setPopupData({ ...item, amount: amount });
  };

  const handlePopupDataAmount = (amount) => {
    setPopupData({ ...popupData, amount: amount });
  };

  const [sizedata, setSizedata] = useState([
    {
      id: 1,
      size: "SM",
    },
    {
      id: 2,
      size: "M",
    },
    {
      id: 3,
      size: "LG",
    },
    {
      id: 4,
      size: "XL",
    },
    {
      id: 5,
      size: "2XL",
    },
    {
      id: 6,
      size: "165x100",
    },
    {
      id: 7,
      size: "205x105",
    },
    {
      id: 8,
      size: "26",
    },
    {
      id: 9,
      size: "28",
    },
    {
      id: 10,
      size: "30",
    },
    {
      id: 11,
      size: "32",
    },
  ]);

  const [branddata, setBranddata] = useState([
    {
      id: 1,
      brand: "apple",
    },
    {
      id: 2,
      brand: "Xiaomi",
    },
    {
      id: 3,
      brand: "Samsung",
    },
    {
      id: 4,
      brand: "Huawei",
    },
    {
      id: 5,
      brand: "Google",
    },
    {
      id: 6,
      brand: "Nokia",
    },
  ]);

  const [colordata, setColordata] = useState([
    {
      id: 1,
      color: "წითელი",
    },
    {
      id: 2,
      color: "ყვითელი",
    },
    {
      id: 3,
      color: "ლურჯი",
    },
    {
      id: 4,
      color: "მწვანე",
    },
    {
      id: 5,
      color: "ყავისფერი",
    },
    {
      id: 6,
      color: "ვარდისფერი",
    },
    {
      id: 7,
      color: "თეთრი",
    },
  ]);

  const [moreBrand, setMoreBrand] = useState(true);

  const handlemoreBrand = () => {
    setMoreBrand((pre) => !pre);
  };

  const [moreSize, setMoreSize] = useState(true);

  const handlemoreSize = () => {
    setMoreSize((pre) => !pre);
  };

  const [moreColor, setMoreColor] = useState(true);

  const handlemoreColor = () => {
    setMoreColor((pre) => !pre);
  };
  //clear wishlistData
  const clearWishlistData = () => {
    const newWishlistData = [];
    wishlistData.setWishlistData(newWishlistData);
    localStorage.setItem('wishlistItem',JSON.stringify(newWishlistData))
  };

  return (
    <div className="w-[100%] flex items-center flex-col bg-gray-100 min-h-[93vh]">
      <div className="w-full mb-5 shadow-sm  border-b-[1px] mt-[90px] max-[540px]:mt-[70px] border-slate-200 items-center bg-white">
        <div className='flex gap-3 px-[20px] py-[10px] items-center '>
          <Link to='/'>
            <p className='text-[14px]'>მთავარი</p>
          </Link>
          <p>/</p>
          <Link to='/favoriteProd'>
            <p className='text-[14px]'>კალათა</p>
          </Link>
        </div>
      </div>
      <div className="favprodpage w-[100%] max-w-[2100px] flex px-[20px] relative">
        <div className="favProdList w-full pr-[20px] pb-[40px] pt-[10px]">
          <div className="favprodtitttle py-[10px] flex justify-between items-center mb-[10px]">
            <h1 className="favtitle text-[22px]">რჩეული პროდუქტები</h1>

            <div className="flex gap-[10px]">
              <div
                onClick={() => clearWishlistData()}
                className="flex items-center gap-[10px] border-2 border-[#efefef] rounded-[33px] px-[20px] py-[10px] cursor-pointer bg-white text-gray-600 max-[700px]:h-[50px] max-[700px]:w-[50px] max-[700px]:p-0 max-[700px]:justify-center max-[400px]:w-[40px] max-[400px]:h-[40px]"
              >
                <Trash />
                <p className="max-[700px]:hidden">გასუფთავება</p>
              </div>
            </div>
          </div>
          <div className="bg-red">
            <div className="grid grid-cols-5 max-lg:grid-cols-3 max-sm:grid-cols-2 gap-[20px] max-sm:gap-[13px] w-[100%] gap-y-[25px]  max-sm:gap-y-[13px] ">
              {wishlistData.wishlistData.map((object) => {
                if(productStyleContext.productStyleContext==='style1'){
                  return(
                    <SliderComponent
                      key={object.id}
                      item={object}
                      img={object.img}
                      title={object.name}
                      price={object.price}
                      sale={object.sale}
                      index={object.id}
                      saleis={object.saleis}
                      empty={object.empty}
                      // handleClickedId={handleClickedId}
                      handleShowProdPopup={handleShowProdPopup}
                      handlePopupDataChange={handlePopupDataChange}
                    />
                  )
                }
                if(productStyleContext.productStyleContext==='style2'){
                  return (
                    <Salecomponent
                      key={object.id}
                      item={object}
                      img={object.img}
                      title={object.name}
                      price={object.price}
                      sale={object.sale}
                      index={object.id}
                      empty={object.empty}
                      handleShowProdPopup={handleShowProdPopup}
                      handlePopupDataChange={handlePopupDataChange}
                    />
                  )
                }
                if(productStyleContext.productStyleContext==='style3'){
                  return(
                    <SlideComponent3 key={object.id}
                      item={object}
                      img={object.img}
                      title={object.name}
                      price={object.price}
                      index={object.id}
                      setPopupData={setPopupData}
                      handleShowProdPopup={handleShowProdPopup}
                      handlePopupDataChange={handlePopupDataChange}
                      empty={object.empty}
                    />
                  )
                }
                })}
            </div>
            {wishlistData.wishlistData.length === 0 && (
              <div className="">
                <div className=" flex justify-center flex-col items-center h-[70%]  py-[15%] bg-white box-shadow-style  rounded-[15px]">
                  <div className="bg-[#F1F1F5] w-[140px] h-[140px] rounded-[10px]  flex justify-center items-center">
                    <Heart className="text-[40px] text-gray-500" />
                  </div>
                  <p className="pt-[20px] pb-[30px] font-semibold text-gray-500">
                    რჩეული პროდუქტების სია ცარიელია
                  </p>
                  <Link to="/">
                    <h1 className="bg-[#010125] text-white font-medium px-[20px] pt-[10px] pb-[8px] rounded-[33px] cursor-pointer">
                      პროდუქტების დამატება
                    </h1>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        
      </div>
      {showProdPopup && (
        <ProdPopup
          item={popupData}
          handleShowProdPopup={handleShowProdPopup}
          image={popupData.img}
          title={popupData.name}
          price={popupData.price}
          amount={popupData.amount}
          empty={popupData.empty}
          handlePopupDataAmount={handlePopupDataAmount}
        />
      )}
    </div>
  );
}

export default FavProds;
