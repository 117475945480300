import { React, useContext, useState, useEffect } from "react";
import CartPopUpCounter from "./CartPopUpCounter";
import ActiveMoney from "./contexts/activeMoney";
import WishlistData from "./contexts/WishlistData";
import { Heart, HeartFill } from "react-bootstrap-icons";
import useFunction from "./hooks/useFunction";
import Counter from "./content/slider/prodcounter";
const CartPopUpItemComponent = ({ item }) => {
  const activeMoney = useContext(ActiveMoney);
  const wishlistData = useContext(WishlistData);
  const [hearted, setHearted] = useState(false);
  const [, handelHeart] = useFunction();
  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData]);

  return (
    <div
      key={item.id}
      className="w-full h-auto px-3 flex py-3 border-b-[1px] border-slate-200"
    >
      <div className="w-[30%] py-3 px-3">
        <img src={item.img} className="w-full h-full object-contain" alt="" />
      </div>
      <div className="flex flex-col px-2 gap-1">
        <p className="font-semibold text-[17px]">{item.name}</p>
        <div className="flex gap-[5px] items-center">
          <p className="">კოდი:</p>
          <p className="text-[14px]">256165</p>
        </div>
        <div className="flex gap-[10px] items-center h-[30px]">
          <div className="w-[70px] h-full">
            <CartPopUpCounter item={item} />
          </div>
          <div
            className="w-[30px] h-full rounded-full flex items-center justify-center mainBg cursor-pointer text-white"
            onClick={() => handelHeart(item)}
          >
            {hearted ? <HeartFill /> : <Heart />}
          </div>
        </div>
        {item.sale ? (
          <div className="salecrds flex gap-[5px] items-center ">
            <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]">
              {activeMoney.activeMoney === "lari"
                ? `${item.price - (item.price / 100) * item.sale}₾`
                : `${item.dolPrice - (item.dolPrice / 100) * item.sale}$`}
            </p>

            <p className="downtexti line-through text-[12px] pt-[5px]">
              {activeMoney.activeMoney === "lari"
                ? `${item.price}₾`
                : `${item.dolPrice}$`}
            </p>
          </div>
        ) : (
          <div className="salecrds flex gap-[5px] items-center ">
            <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px] ">
              {activeMoney.activeMoney === "lari"
                ? `${item.price}₾`
                : `${item.dolPrice}$`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartPopUpItemComponent;
