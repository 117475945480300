import { Cart, Heart, HeartFill, XLg } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import ProdPopupounter from "./popupcounter";
import ActiveMoney from "../../contexts/activeMoney";
import WishlistData from "../../contexts/WishlistData";
import useFunction from "../../hooks/useFunction";

function ProdPopup({
  item,
  handleShowProdPopup,
  image,
  title,
  price,
  amount,
  empty,
  sale,
  handlePopupDataAmount,
}) {
  const [counternum, setCounternum] = useState(amount);
  const activeMoney = useContext(ActiveMoney);
  const wishlistData = useContext(WishlistData);
  const [addCart, handelHeart] = useFunction();
  const [hearted, setHearted] = useState(false);

  useEffect(() => {
    handlePopupDataAmount(counternum);
  }, [counternum]);
  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData]);

  console.log(sale);
  return (
    <div className="bg-[#000000bf] w-[100vw] h-[100vh] items-center flex justify-center z-[99] fixed top-0 left-0">
      <div
        className="w-[100%] h-[100%] absolute"
        onClick={handleShowProdPopup}
      ></div>
      <div className="adres-div w-[50%] bg-white z-[99] rounded-[18px]">
        <div className="flex justify-end px-[12px] py-[8px] border-b-[1px]">
          <div
            className="w-[40px] h-[40px] items-center cursor-pointer justify-center flex bg-slate-100 border-gray-200 rounded-[33px]"
            onClick={() => {
              handleShowProdPopup();
            }}
          >
            <XLg className='text-black'/>
          </div>
        </div>
        <div className="cardpopupcont flex p-[20px] gap-[10px]">
          <div className="outimage w-[50%]">
            <Link to={`/product/${item.id}`}>
              <div className="image w-[100%] h-[300px] p-[10px] relative">
                <img
                  src={image}
                  className="w-[100%] h-[100%] object-contain"
                  alt=""
                />
                {sale && (
                  <div
                    className="absolute left-[8px] top-[8px] px-[8px] h-[25px] bg-[red] rounded-[20px]
           text-white text-[12px] flex items-center justify-center pt-[2px]
          "
                  >
                    <p>-{sale}%</p>
                  </div>
                )}
              </div>
            </Link>
          </div>

          <div className="prodpoptext flex flex-col w-[50%]  my-[35px]">
            <Link to={`/product/${item.id}`}>
              <div className="gap-y-[5px] flex flex-col">
                <p className=" font-semibold text-[20px]">{title}</p>
              </div>
            </Link>
            <div className="flex items-center gap-[5px]">
              <p>კოდი:</p>
              <p className="text-[14px]">252678</p>
            </div>
            <div className="flex flex-col gap-y-[10px]">
              {item.sale ? (
                <div className="salecrds flex gap-[5px] items-center ">
                  <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                    {activeMoney.activeMoney === "lari"
                      ? `${price - (price / 100) * item.sale}₾`
                      : `${item.dolPrice - (item.dolPrice / 100) * item.sale}$`}
                  </p>

                  <p className="downtexti line-through text-[12px] pt-[5px]">
                    {activeMoney.activeMoney === "lari"
                      ? `${price}₾`
                      : `${item.dolPrice}$`}
                  </p>
                </div>
              ) : (
                <div className="salecrds flex gap-[5px] items-center ">
                  <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                    {activeMoney.activeMoney === "lari"
                      ? `${price}₾`
                      : `${item.dolPrice}$`}
                  </p>
                </div>
              )}
              <div className="w-[170px] flex gap-[10px] items-center justify-between max-[460px]:w-full">
                <div className="w-[70%] max-[460px]:w-[80%]">
                  <ProdPopupounter
                    counternum={counternum}
                    setCounternum={setCounternum}
                  />
                </div>
                <div
                  className="w-[35px] h-[35px]  rounded-full flex items-center justify-center mainBg cursor-pointer text-white"
                  onClick={() => {
                    handelHeart(item);
                  }}
                >
                  {hearted ? <HeartFill /> : <Heart />}
                </div>
              </div>

              {empty ? (
                <div
                  onClick={() => addCart(item, counternum)}
                  className="giftcardbuy mt-[10px] w-[170px] bg-[#010125] duration-200 hover:bg-[#010125a2] pt-[3px] h-[35px] pb-[4px] gap-[5px] flex justify-center items-center rounded-[33px] text-white cursor-pointer"
                >
                  <Cart />
                  <p className="">დამატება</p>
                </div>
              ) : (
                <div className="giftcardbuy mt-[10px] w-[170px] bg-[#0101254f] h-[35px] pb-[4px] gap-[5px] pt-[5px] flex justify-center items-center pointer-events-none rounded-[33px] text-white cursor-pointer">
                  <p>არარის მარაგში</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProdPopup;
