import pht from "../../../imgs/onepng.avif";
import a from "../../../imgs/twopng.webp";
import s from "../../../imgs/16.webp";
import d from "../../../imgs/19.webp";

import React, { useRef, useState, useEffect } from "react";
import { ChevronCompactDown, ChevronCompactUp } from "react-bootstrap-icons";

import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Thumbs } from "swiper";

import "swiper/css";

const photos = [pht, a, s, d, pht, a, s, d, pht, a, s, d];

function Prodphtzoomslider() {
  const [activeThumb, setActiveThumb] = useState();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  useEffect(() => {
    if (windowSize[0] <= 1700 && windowSize[0] >= 0) {
      setSlidesToDisplay(4);
    }
  }, [windowSize]);

 

  return (
    <div className="flex gap-[20px] h-[100%] max-sm:gap-[15px] max-md:flex-col">
      <div className="w-[120px] h-[100%] max-md:w-full  flex flex-col justify-between max-lg:gap-y-[10px] max-md:hidden">
        <Swiper
          direction={"vertical"}
          
          onSwiper={setActiveThumb}
          loop={true}
          spaceBetween={10}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
            disabledClass: "swiper-button-disabled",
          }}
          slidesPerView={slideToDisplay}
          className="h-[90%] w-full flex max-md:w-[100px] max-md:h-[100px]"
        >
          {photos.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white my-[2px] rounded-[7px] h-[100%] w-[100%] p-[15px]  ">
                <img
                  src={item}
                  alt=" "
                  className="object-contain w-[100%] h-[100%] rounded-[5px] cursor-pointer"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex gap-[10px] items-center w-full max-md:hidden">
          <button
            className="next-btn h-[30px] w-[50%]"
          >
            <p className="flex justify-center items-center bg-gray-300 hover:bg-gray-400 rounded-full  duration-200">
              <ChevronCompactUp className="text-white text-[30px] max-sm:text-[16px]" />
            </p>
          </button>
          <button
            className="prev-btn h-[30px] w-[50%]"
          >
            <p className="flex justify-center items-center bg-gray-300 hover:bg-gray-400 rounded-full  duration-200">
              <ChevronCompactDown className="text-white text-[30px] max-sm:text-[16px]" />
            </p>
          </button>
        </div>
      </div>
      <div className="rounded-[16px] h-[100%] w-[79%] max-[1200px]:w-[85%] max-md:h-[300px] max-md:w-full bg-white flex items-center">
        <div className="product-main-pht bg-white w-full h-[100%]  flex p-[10px] justify-center relative rounded-[16px]">
          <Swiper
            loop={true}
            spaceBetween={10}
            navigation={{
              nextEl: ".next-btn",
              prevEl: ".prev-btn",
              disabledClass: "swiper-button-disabled",
            }}
            modules={[Navigation, Thumbs]}
            grabCursor={true}
            thumbs={{
              swiper:
                activeThumb && !activeThumb.destroyed ? activeThumb : null,
            }}
            className="product-images-slider"
          >
            {photos.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={item}
                  className="object-contain w-[100%] h-[100%]"
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="w-full h-[100%] flex-col justify-between max-lg:gap-y-[10px] hidden max-md:flex">
        <Swiper
          direction={"horizontal"}
          
          onSwiper={setActiveThumb}
          loop={true}
          spaceBetween={10}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
            disabledClass: "swiper-button-disabled",
          }}
          slidesPerView={slideToDisplay}
          className="w-full flex h-[100px] max-[400px]:h-[80px]"
        >
          {photos.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white my-[2px] rounded-[7px] h-[100%] w-[100%] p-[15px] max-[400px]:p-[7px]">
                <img
                  src={item}
                  alt=" "
                  className="object-contain w-[100%] h-[100%] rounded-[5px] cursor-pointer"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Prodphtzoomslider;
