import React from 'react'
import { Link } from 'react-router-dom'

const SubSubCategory = ({item2,item1,setIsShowHeaderHover}) => {
  return (
    <div className="flex flex-col">
      <Link
        onClick={() => setIsShowHeaderHover((prev) =>!prev)}
        to={`/products/${item1.parent.textLink}/${item1.title}/${item2.title}`}>
        <p
          className='text-[14px] pl-[10px]'> 
          {item2.titleGeo}
        </p>
      </Link>
    </div>
  )
}

export default SubSubCategory