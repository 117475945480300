import { HouseDoor,CheckLg } from "react-bootstrap-icons"

function AddAddress (){
    return(
          <div className="hoverable-shadow flex items-center border-2 border-gray-100 p-[20px] rounded-[33px] gap-[20px] mb-[20px] justify-between cursor-pointer">
            <div className="flex gap-[20px] items-center">
            <div className="house-icn-adrs bg-[#f1f1f5] w-[50px] h-[50px] rounded-full items-center flex justify-center">
            <HouseDoor className="text-[#93939e] text-[25px]"/>
            </div>
            <div className="">
                <h1 className="text-#92929d">სახლი</h1>
                <p className="house-adres">61 Vazha Pshavela Ave, T'bilisi, Georgia</p>
            </div>
            </div>
            <div className="mainBg w-[35px] h-[35px] rounded-full items-center flex justify-center">
            <CheckLg className="text-white text-[22px]"/>
            </div>
          </div>
    )
}

export default AddAddress