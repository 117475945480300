import { useState,useEffect } from "react";
import Counter from "./prodcounter";
import { useLocation, Link } from "react-router-dom";
import { Cart, EyeFill, Heart, HeartFill, XLg } from "react-bootstrap-icons";
import CartData from "../../contexts/CartData";
import { useContext } from "react";
import WishlistData from "../../contexts/WishlistData";
import ActiveMoney from "../../contexts/activeMoney";
import useFunction from "../../hooks/useFunction";

function SlideComponent3({
  item,
  img,
  title,
  price,
  index,
  handleShowProdPopup,
  handlePopupDataChange,
  empty,
  sale,
}) {
  let location = useLocation();
  const newCartData = useContext(CartData);
  const activeMoney = useContext(ActiveMoney);
  const wishlistData = useContext(WishlistData);
  const [counternum, setCounternum] = useState(1);
  const [addCart,handelHeart] = useFunction()
  const [test1, setTest1] = useState(0);
  const [hearted, setHearted] = useState(false);
  const [imageChanger,setImageChanger] = useState(img)
  

  const handelDelete = (Id) => {
    if (location.pathname === "/favoriteProd") {
      const newWishlist = wishlistData.wishlistData.filter(
        (item) => Id !== item.id
      );
      wishlistData.setWishlistData(newWishlist);
      localStorage.setItem('wishlistItem',JSON.stringify(newWishlist))
    }
  };
  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData,]);

  return (
    <div className="prodcomp w-[100%] flex justify-center h-[100%] relative group">
      {item.sale && (
        <div className="absolute left-[8px] top-[8px] px-[8px] h-[25px] bg-[red] rounded-[20px] text-white text-[12px] flex items-center justify-center pt-[2px]">
        <p>-{item.sale}%</p>
      </div>
      )}
      <div className="absolute right-[15px] top-[15px] flex flex-col gap-[5px] ">
        <div
          className={`mainBg cursor-pointer text-white w-[30px] h-[30px]  
          justify-center items-center rounded-full ${
            location.pathname === "/favoriteProd"
              ? "flex"
              : "hidden group-hover:flex"
          }`}
          onClick={() => {
            handlePopupDataChange(item, counternum);
            handleShowProdPopup();
          }}
        >
          <EyeFill />
        </div>
        {location.pathname === "/favoriteProd" ? (
          <div
            onClick={() => handelDelete(item.id)}
            className="mainBg cursor-pointer text-white pt-[2px] w-[30px] h-[30px] flex justify-center items-center rounded-full"
          >
            <XLg />
          </div>
        ) : (
          <div
            className="mainBg cursor-pointer text-white pt-[2px] w-[30px] h-[30px] hidden group-hover:flex
            justify-center items-center rounded-full"
            onClick={() => {
              handelHeart(item)
            }}
          >
            {hearted ? <HeartFill /> : <Heart />}
          </div>
        )}
      </div>
      <div
        className={`p-[15px] bg-white  rounded-[15px] flex flex-col justify-between ${
          location.pathname.split("/")[1]  === "mainshop"
            ? "cardib w-[100%] "
            : "cart-onmain w-[100%]"
        }`}
      >
        <Link to={`/product/${item.id}`}>
          <div
            onMouseOver={() => setImageChanger(item.img1)}
            onMouseOut={() => setImageChanger(item.img)}
            className={`cursor-pointer  w-[100%] flex justify-center  ${
              location.pathname === "/product"
                ? "prodcomp-img h-[180px]"
                : `${
                  location.pathname.split("/")[1]  === "mainshop" ||
                    location.pathname === "/favoriteProd"
                      ? "mainshNonsalecard h-[150px]"
                      : "cart-main h-[200px]"
                  }`
            }`}
          >
            <img src={imageChanger} className="w=[100%] h-[98%] object-cover" alt="" />
          </div>

          <p>{title}</p>
        </Link>

        {item.sale ? (
          <div className="salecrds flex gap-[5px] items-center ">
            <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
              {activeMoney.activeMoney === "lari"
                ? `${price - (price / 100) * item.sale}₾`
                : `${item.dolPrice - (item.dolPrice / 100) * item.sale}$`}
            </p>

            <p className="downtexti line-through text-[12px] pt-[5px]">
              {activeMoney.activeMoney === "lari"
                ? `${price}₾`
                : `${item.dolPrice}$`}
            </p>
          </div>
        ) : (
          <div className="salecrds flex gap-[5px] items-center ">
            <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
              {activeMoney.activeMoney === "lari"
                ? `${price}₾`
                : `${item.dolPrice}$`}
            </p>
          </div>
        )}

        <Counter
          price={price}
          counternum={counternum}
          setCounternum={setCounternum}
          width={true}
          test1={test1}
        />
        {empty ? (
          <div
            onClick={() => {
              addCart(item,counternum);
              handelDelete(item.id);
              setTest1((prev) => prev + 1);
            }}
            className="giftcardbuy mt-[10px] w-[100%] bg-[#010125] hover:bg-[#010125a2] duration-200 h-[35px] pb-[4px] gap-[5px] flex justify-center items-center rounded-[33px] text-white cursor-pointer"
          >
            <Cart />
            <p className="">დამატება</p>
          </div>
        ) : (
          <div className="giftcardbuy mt-[10px] w-[100%] bg-[#0101254f] h-[35px] pb-[4px] gap-[5px] pt-[5px] flex justify-center items-center pointer-events-none rounded-[33px] text-white cursor-pointer">
            <p>არარის მარაგში</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SlideComponent3;
