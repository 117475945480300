import MainTopCategoriesAndSlider from "./MainTopCategoriesAndSlider";
import { useContext } from "react";
import TopSliderVariations from "../../contexts/topslidervariations";
export default function MainTopSliderVariations() {
  const topslidervariations = useContext(TopSliderVariations);
  return (
    <>
      <MainTopCategoriesAndSlider />
    </>
  );
}
