import { createContext, useEffect } from "react";
import { useState } from "react";

import pht1 from "../../imgs/3.webp";
import pht2 from "../../imgs/white.avif";
import pht3 from "../../imgs/whiteee.webp";
import pht4 from "../../imgs/whiteeee.avif";
import pht5 from "../../imgs/4.webp";

import pht6 from "../../imgs/3.webp";
import pht7 from "../../imgs/white.avif";
import pht8 from "../../imgs/whiteee.webp";
import pht9 from "../../imgs/whiteeee.avif";

import divani1 from "../../imgs/8.webp";
import divani2 from "../../imgs/10.webp";
import divani3 from "../../imgs/11.webp";
import divani4 from "../../imgs/12.webp";
import divani5 from "../../imgs/13.webp";
import divani6 from "../../imgs/14.webp";
import divani7 from "../../imgs/8.webp";
import divani8 from "../../imgs/10.webp";
import divani9 from "../../imgs/11.webp";

import easyway from "../../imgs/easyway.svg";
import glovo from "../../imgs/78.webp";
import wolt from "../../imgs/80.webp";



const PrimaryContext = createContext(null);

const getLocalStoreData = (key) => {
  const localData = JSON.parse(localStorage.getItem(key));
  if (localData) {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return [];
  }
};

function MainContext({ children }) {
  const [myData, setMydata] = useState(null);

  useEffect(() => {
    setMydata(data);
  }, [data]);

  const [myDataslider, setDataslider] = useState(null);

  useEffect(() => {
    setDataslider(dataslider);
  }, [dataslider]);

  const [filialsData, setFilials] = useState(null);

  useEffect(() => {
    setFilials(filials);
  }, [filials]);

  const [bloginfoData, setBloginfodata] = useState(null);

  useEffect(() => {
    setBloginfodata(bloginfo);
  }, [bloginfo]);

  const [deliveryCompanysData, setDeliveryCompanysdata] = useState([]);

  useEffect(() => {
    setDeliveryCompanysdata(deliveryCompanys);
  }, [deliveryCompanys]);

  const [paymentbanksData, setPaymentbanksdata] = useState([]);

  const [cartData, setCartData] = useState(getLocalStoreData("cartData"));
  const [cartLength, setCartLength] = useState(0);

  const handleAddCart = (index, newQuantity) => {
    var tmpData = cartData.filter((item) => item.id !== index);
    var singleItem = myData[index];
    singleItem = { ...singleItem, quantity: newQuantity };

    if (newQuantity === 0) {
      if (cartData.length === 0) {
        setCartData([singleItem]);
      } else {
        setCartData([...tmpData]);
      }
    } else {
      setCartData([...tmpData, singleItem]);
    }
  };

  useEffect(() => {
    localStorage.setItem("cartData", JSON.stringify(cartData));
    setCartLength(cartData.length);
  }, [cartData]);

  const [scroll, setScroll] = useState(false);
  const handleScroll = (value) => {
    setScroll(value);
  };

  return (
    <PrimaryContext.Provider
      value={{
        myData,
        cartData,
        cartLength,
        handleAddCart,
        handleScroll,
        scroll,
        myDataslider,
        filialsData,
        bloginfoData,
        deliveryCompanysData,
        paymentbanksData
            }}
    >
      {children}
    </PrimaryContext.Provider>
  );
}

export { MainContext, PrimaryContext };

const data = [
  {
    id: 0,
    img: pht1,
    img1: pht2,
    name: "სავარძელი",
    price: "150",
    dolPrice: "300",
    sale: "25",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht2,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht3,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht4,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht5,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 1,
    img: pht2,
    img1: pht3,
    name: "დივანი",
    price: "2100",
    dolPrice: "4200",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht1,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht3,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht4,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht5,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 2,
    img: pht3,
    img1: pht4,
    name: "კომოდი",
    price: "60",
    dolPrice: "120",
    empty: false,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht1,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht2,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht4,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht5,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 3,
    img: pht4,
    img1: pht5,
    name: "სკამი",
    price: "25",
    dolPrice: "50",
    sale: "50",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht1,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht2,
      },
      {
        id: 3,
        name: "#800080",
        name1: "იასამნისფერი",
        src: pht3,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht5,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 4,
    img: pht5,
    img1: pht6,
    name: "სავარძელი",
    price: "200",
    dolPrice: "400",
    sale: "45",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht1,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht2,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht3,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht4,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 5,
    img: pht6,
    img1: pht7,
    name: "სავარძელი",
    price: "290",
    dolPrice: "580",
    sale: "25",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht6,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht1,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht5,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 6,
    img: pht7,
    img1: pht6,
    name: "დივანი",
    price: "2300",
    dolPrice: "4600",
    empty: false,
    color: [
      {
        id: pht1,
        name: "red",
        name1: "წითელი",
        src: pht1,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht8,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht9,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht1,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 7,
    img: pht8,
    img1: pht7,
    name: "კომოდი",
    price: "140",
    dolPrice: "280",
    sale: "35",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht3,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht4,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht9,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht7,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 8,
    img: pht9,
    img1: pht8,
    name: "სკამი",
    price: "30",
    dolPrice: "60",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht1,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht5,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht9,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht1,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 9,
    img: pht1,
    img1: pht2,
    name: "სავარძელი",
    price: "150",
    dolPrice: "300",
    empty: false,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 10,
    img: pht2,
    img1: pht3,
    name: "დივანი",
    price: "2100",
    dolPrice: "4200",
    sale: "35",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 11,
    img: pht3,
    img1: pht4,
    name: "კომოდი",
    price: "60",
    dolPrice: "120",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 12,
    img: pht4,
    img1: pht5,
    name: "სკამი",
    price: "25",
    dolPrice: "50",
    sale: "50",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 13,
    img: pht5,
    img1: pht6,
    name: "სავარძელი",
    price: "200",
    dolPrice: "400",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 14,
    img: pht6,
    img1: pht7,
    name: "სავარძელი",
    price: "290",
    dolPrice: "580",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 15,
    img: pht7,
    img1: pht8,
    name: "დივანი",
    price: "2300",
    dolPrice: "4600",
    empty: false,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
  {
    id: 16,
    img: pht8,
    img1: pht7,
    name: "კომოდი",
    price: "140",
    dolPrice: "280",
    sale: "35",
    empty: true,
    color: [
      {
        id: 1,
        name: "red",
        name1: "წითელი",
        src: pht5,
      },
      {
        id: 2,
        name: "#010125",
        name1: "მწვანე",
        src: pht6,
      },
      {
        id: 3,
        name: "#800080",
        name1: "ყვიტელი",
        src: pht7,
      },
      {
        id: 4,
        name: "#ffc0cb",
        name1: "ვარდისფერი",
        src: pht8,
      },
    ],
    size: [
      {
        id: 1,
        name: "XS",
        name1: "XS",
        // src: pht2,
      },
      {
        id: 2,
        name: "S",
        name1: "S",
        // src: pht3,
      },
      {
        id: 3,
        name: "M",
        name1: "M",
        // src: pht4,
      },
      {
        id: 4,
        name: "L",
        name1: "L",
        // src: pht5,
      },
      {
        id: 5,
        name: "XL",
        name1: "XL",
        // src: pht5,
      },
      {
        id: 6,
        name: "2XL",
        name1: "2XL",
        // src: pht5,
      },
      {
        id: 7,
        name: "3XL",
        name1: "3XL",
        // src: pht5,
      },
      {
        id: 8,
        name: "43",
        name1: "43",
        // src: pht5,
      },
      {
        id: 9,
        name: "180X100",
        name1: "180X100",
        // src: pht5,
      },
    ],
  },
];

const dataslider = [
  {
    id: 10,
    img: divani1,
    name: "30 000 დასახელების პროდუქტი",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 11,
    img: divani2,
    name: "ყველაზე დაბალი ფასები",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 12,
    img: divani3,
    name: "ხარისხი შეესაბამება ფასს",
    price: "30",
    dolPrice: "60",
    empty: false,
  },
  {
    id: 13,
    img: divani4,
    name: "ყველა გემოვნებისთვის",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 14,
    img: divani5,
    name: "გერმანული ავეჯი",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 15,
    img: divani6,
    name: "უმაღლესი ხარისხი ყველასთვის",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 16,
    img: divani7,
    name: "თურქული ავეჯი",
    price: "30",
    dolPrice: "60",
    empty: false,
  },
  {
    id: 17,
    img: divani8,
    name: "ბიუჯეტური და ხარისხიანი",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 18,
    img: divani9,
    name: "ყველაფერი თქვენთვის",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 19,
    img: divani5,
    name: "გერმანული ავეჯი",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 20,
    img: divani6,
    name: "უმაღლესი ხარისხი ყველასთვის",
    price: "30",
    dolPrice: "60",
    empty: false,
  },
  {
    id: 21,
    img: divani7,
    name: "თურქული ავეჯი",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 22,
    img: divani8,
    name: "ბიუჯეტური და ხარისხიანი",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 23,
    img: divani9,
    name: "ყველაფერი თქვენთვის",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 24,
    img: divani4,
    name: "ყველაფერი თქვენთვის",
    price: "30",
    dolPrice: "60",
    empty: true,
  },
  {
    id: 25,
    img: divani6,
    name: "ყველაფერი თქვენთვის",
    price: "30",
    dolPrice: "60",
    empty: false,
  },
];

const filials = [
  {
    id: 100,
    img: divani5,
    name: "ფილიალი 1",
  },
  {
    id: 101,
    img: divani7,
    name: "ფილიალი 2",
  },
  {
    id: 102,
    img: divani2,
    name: "ფილიალი 3",
  },
  {
    id: 103,
    img: divani6,
    name: "ფილიალი 4",
  },
  {
    id: 104,
    img: divani4,
    name: "ფილიალი 5",
  },
];

const bloginfo = [
  {
    id: 1000,
    blogimg: divani5,
    blogtitle:
      "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
    blogtext:
      "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
    blogtime: "24:25",
  },
  {
    id: 1001,
    blogimg: divani7,
    blogtitle: "31 მარტის ასტროლოგიური პროგნოზი",
    blogtext:
      "რას გვირჩევენ ვარსკვლავები 31 მარტისთვის ჯანმრთელობის, ბიზნესის და სიყვარულის მიმართულებით? საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო",
    blogtime: "02:50",
  },
  {
    id: 1002,
    blogimg: divani2,
    blogtitle:
      "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
    blogtext:
      "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
    blogtime: "01:50",
  },
  {
    id: 1003,
    blogimg: divani6,
    blogtitle:
      "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
    blogtext:
      "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
    blogtime: "10:50",
  },
  {
    id: 1004,
    blogimg: divani4,
    blogtitle: "31 მარტის ასტროლოგიური პროგნოზი",
    blogtext:
      "რას გვირჩევენ ვარსკვლავები 31 მარტისთვის ჯანმრთელობის, ბიზნესის და სიყვარულის მიმართულებით? საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო",
    blogtime: "12:50",
  },
  {
    id: 1005,
    blogimg: divani7,
    blogtitle: "31 მარტის ასტროლოგიური პროგნოზი",
    blogtext:
      "რას გვირჩევენ ვარსკვლავები 31 მარტისთვის ჯანმრთელობის, ბიზნესის და სიყვარულის მიმართულებით? საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო",
    blogtime: "02:50",
  },
  {
    id: 1006,
    blogimg: divani2,
    blogtitle:
      "საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო თავგადას",
    blogtext:
      "30 მარტს პლანეტა ვენერა და ურანი შეერთდებიან - რას შეცვლის ეს მნიშვნელოვანი ასტროლოგიური მოვლენა თითოეული ადამიანის ცხოვრებაში. „განახლება და ცვლილება“ - რამაზ გიგაურის",
    blogtime: "01:50",
  },
  {
    id: 1007,
    blogimg: divani6,
    blogtitle:
      "„დღვანდელი სიზმარი ახდება - ნუ აჰყვებით ემოციებს“ - მიხეილ ცაგარელი",
    blogtext:
      "ნუ აჰყვებით ემოციებს. მოერიდეთ სიმძიმეების აწევას, ფიზიკურ გადაღლას, ფინანსურ გარიგებებს, ბინის შეცვლას, მცენარეების დარგვა-გადარგვას. საბედისწერო შეხვედრების, მოულოდნელი",
    blogtime: "10:50",
  },
  {
    id: 1008,
    blogimg: divani4,
    blogtitle: "31 მარტის ასტროლოგიური პროგნოზი",
    blogtext:
      "რას გვირჩევენ ვარსკვლავები 31 მარტისთვის ჯანმრთელობის, ბიზნესის და სიყვარულის მიმართულებით? საბედისწერო შეხვედრების, მოულოდნელი სიახლეებისა და სასიამოვნო",
    blogtime: "12:50",
  },
];

const deliveryCompanys = [
  {
    id: 1,
    img: easyway,
    priceDelivery: 5,
  },
  {
    id: 2,
    img: glovo,
    priceDelivery: 6,
  },
  {
    id: 3,
    img: wolt,
    priceDelivery: 7,
  },
];