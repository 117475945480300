import {
  Person,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import StartCategList from "./startcateglist";
import InLoggedMenuList from "./inloggedmenulist";

function INdropmenu({ handleShowMenu, handleShowLocDesk,setShowMenu }) {

  const [show, setShow] = useState(true);
  
  const handleShow = () => {
    setShow((prev) => !prev);
  };
  

  return (
    <div>
      {/* <div className="flex flex-col p-[16px]">
        <Link to="profile/profile-profwind">
          <div
            className="flex items-center gap-[10px]"
            onClick={() => {
              handleShowMenu();
            }}
          >
            <div className="bg-white border-2 rounded-full flex justify-center items-center w-[50px] h-[50px]">
              <Person className="text-[27px]" />
            </div>
            <div>
              <p>User</p>
              <p className="text-gray-500">595292822</p>
            </div>
          </div>
        </Link>
      </div> */}

      <div className="h-[100%]">
      {/* <div className="w-[100%] flex mb-[15px]">
        <h1
          className={`w-[50%] text-[14px] cursor-pointer h-[45px] text-white  flex justify-center items-center ${
            show ? "bg-white text-[#010125]" : "mainBg"
          }`}
          onClick={()=>{setShow(true)}}
        >
          მენიუ
        </h1>
        <h1
          className={`w-[50%] text-[14px] cursor-pointer  h-[45px] text-white  flex justify-center items-center ${
            show ? "mainBg" : " bg-white text-[#010125] "
          }`}
          onClick={()=>{setShow(false)}}
        >
          კატეგორიები
        </h1>
      </div> */}
      <div className=" h-[85%] px-[16px] ">
      {show === false && <StartCategList handleShowMenu={handleShowMenu} />}
        {show === true && <InLoggedMenuList handleShowMenu={handleShowMenu} setShowMenu={setShowMenu}/>}
      </div>
    </div>
    </div>
  );
}

export default INdropmenu;
