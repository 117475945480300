import { Plus } from "react-bootstrap-icons";
import { useState } from "react";
import AddPromoCode from "./addpromocode";

function ProfilePromocodes() {
  const [showAddPromo, setShowAddPromo] = useState(false);

  const handleShowAddPromo = () => {
    setShowAddPromo((prev) => !prev);
  };
  return (
    <div className="pb-[30px]">
      <h1 className="promocodes-title mb-[40px] text-[22px]">პრომო კოდები</h1>
      <div className="w-[100%] flex flex-col">
        <div className="promoconts flex items-center p-[25px] cursor-pointer hover:shadow bg-white gap-[5px] w-[70%] rounded-[16px]" 
        onClick={handleShowAddPromo}>
          <div className="bg-gray-100 w-[40px] rounded-full h-[40px] flex items-center justify-center">
            <Plus className="text-[#010125] text-[25px]" />
          </div>
          <p className="text-[#010125]">პრომო კოდის დამატება</p>
        </div>
      </div>
      {showAddPromo && <AddPromoCode handleShowAddPromo={handleShowAddPromo} />}
    </div>
  );
}

export default ProfilePromocodes;
