import { XLg } from "react-bootstrap-icons";
import { useState } from "react";

function ProfOrderedCards({ img, name, price }) {
  const [productQuantity, setProductQuantity] = useState(12);
  return (
    <div className="w-full flex border-b-[2px] justify-between gap-[10px] pb-[10px] px-[20px] max-lg:p-[10px] max-sm:px-[0px]">
      <div className="flex items-center gap-[15px]   w-[25%]">
        <div className="w-[50%] h-[100px] max-lg:h-[150px] cursor-pointer max-lg:w-full">
          <img
            src={img}
            alt=""
            className="w-[150px] max-lg:w-full h-full  object-contain "
          />
        </div>
        <h1 className="w-[50%] cursor-pointer text-[14px] max-lg:hidden">{name}</h1>
      </div>

      <div className=" flex max-lg:flex-col gap-[10px] max-lg:gap-y-[10px] w-[75%] justify-between">
        <h1 className="hidden cursor-pointer text-[14px] max-lg:flex">
          {name}
        </h1>

        <div className="flex  justify-center w-[12.5%]  max-lg:border-b-[1px] items-center  max-lg:justify-between max-lg:w-full">
          <p className="hidden max-lg:flex max-sm:text-[13px]">რაოდენობა</p>
          <p className="text-center max-sm:text-[13px]">12</p>
        </div>

        <div className="flex  justify-center  w-[12.5%]  max-lg:border-b-[1px]  items-center max-lg:justify-between max-lg:w-full">
          <p className="hidden max-lg:flex max-sm:text-[13px]">ჯამი</p>
          <p className="text-center max-sm:text-[13px]">{price * productQuantity}₾</p>
        </div>

        <div className="flex  justify-center  w-[12.5%]  max-lg:border-b-[1px]  items-center max-lg:justify-between max-lg:w-full">
          <p className="hidden max-lg:flex max-sm:text-[13px]">მისამართი</p>
          <p className="text-center max-lg:text-end max-sm:text-[11px]">
            თბილისი, ჭავჭავაძის გამზირი #119
          </p>
        </div>

        <div className="flex  justify-center  w-[12.5%]  max-lg:border-b-[1px]  items-center max-lg:justify-between max-lg:w-full">
        <p className="hidden max-lg:flex max-sm:text-[13px]">კოდი</p>
          <p className="text-center max-sm:text-[11px]">2436</p>
        </div>

        <div className="flex  justify-center  w-[12.5%]  items-center max-lg:justify-between max-lg:w-full">
        <p className="hidden max-lg:flex max-sm:text-[13px]">თარიღი</p>
          <p className="text-center  max-sm:text-[11px]">01/11/2024</p>
        </div>
      </div>
    </div>
  );
}

export default ProfOrderedCards;
