import {React,useContext} from 'react'
import WishlistData from '../contexts/WishlistData'
import CartData from '../contexts/CartData'
import CartPopUpShow from '../contexts/CartPopUpShow'

const useFunction = () => {
  const newCartData = useContext(CartData)
  const wishlistData = useContext(WishlistData)
  const cartPopUpShow = useContext(CartPopUpShow)
  const addCart = (items,counternum) => {
    const filtered = newCartData.cartData.find(
      (item) => item.id === items.id
    );
    if (filtered) {
      newCartData.cartData.map((item) => {
        if (items.id === item.id) {
          item.counter = item.counter + counternum;
        }
      });
      localStorage.setItem("items", JSON.stringify(newCartData.cartData));
    } else {
      const newCart = [
        { counter: counternum, ...items },
        ...newCartData.cartData,
      ];
      newCartData.setCartData(newCart);
      localStorage.setItem("items", JSON.stringify(newCart));
    }
    cartPopUpShow.setCartPopUpShow(true)
  }
///add wishlist
const addWishlist = (items) =>{
  const filtered=wishlistData.wishlistData.find((item) => item.id===items.id)
    if(filtered)
    {
    }
    else
    {
      const newWishlist=[{...items},...wishlistData.wishlistData]
      wishlistData.setWishlistData(newWishlist)
      localStorage.setItem('wishlistItem',JSON.stringify(newWishlist))
    }
  }
////delete whishlist
  const handelDelete = (Id) => {
  const newWishlist = wishlistData.wishlistData.filter(
    (item) => Id !== item.id
  );
  wishlistData.setWishlistData(newWishlist);
  localStorage.setItem("wishlistItem", JSON.stringify(newWishlist));
};
  const handelHeart = (item) => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      // setHearted(false);
      handelDelete(item.id);
    } else {
      // setHearted(true);
      addWishlist(item);
    }
  };

  
    return [addCart,handelHeart]
  }

export default useFunction