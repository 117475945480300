import { useState, useContext } from "react";
import SecAutorization from "../autorization/secondautorization";
import { Basket,  Grid, Heart, Person } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { BiHomeAlt2 } from "react-icons/bi";
import CartData from "../../contexts/CartData";
import WishlistData from "../../contexts/WishlistData";
import CategoryData from "../../contexts/CategoryData";
import IsShowCategoryPopUp from "../../contexts/IsShowCategoryPopUp";

export default function HeaderBotMenu({
  goToTopFast,
  goToTopSmooth,
}) {
  const [secAut, setSecaut] = useState(false);
  const newCartData = useContext(CartData);
  const wishlistData = useContext(WishlistData);
  const categoryData = useContext(CategoryData);
  const isShowCategoryPopUp = useContext(IsShowCategoryPopUp)

  const handleSecaut = () => {
    setSecaut((prev) => !prev);
  };
  return (
    <div>
      <div className="resp-bot-menu px-[30px] w-[100%] py-[8px] bg-white border-t-gray-200 border-t-[1px] fixed bottom-0 z-10 hidden justify-between items-center">
        <div
          className="heart-bot-menu w-[40px] rounded-full pt-[5px] px-[10px] flex items-center 
            justify-center 
            cursor-pointer flex-col gap-y-[5px]"
          onClick={() => isShowCategoryPopUp.setIsShowCategoryPopUp((prev) => !prev)}
        >
          <Grid className="text-[#010125] text-[18px]  cursor-pointer" />
          <h1 className="text-[8px]">კატეგორიები</h1>
        </div>
        <Link to="/favoriteProd" onClick={() => isShowCategoryPopUp.setIsShowCategoryPopUp(false)}>
          <div
            className="heart-bot-menu w-[40px] relative rounded-full pt-[5px] px-[10px] flex items-center 
              justify-center 
              cursor-pointer flex-col gap-y-[5px]"
            onClick={goToTopFast}
          >
            <Heart className="text-[#010125] text-[18px]  cursor-pointer" />
            <div className="w-[17px] h-[17px] rounded-full top-[-5px] right-[-5px] absolute bg-[#010125] text-white flex items-center justify-center">
              <p className="pt-[2px] text-[10px]">
                {wishlistData.wishlistData.length}
              </p>
            </div>
            <h1 className="text-[8px]">რჩეული</h1>
          </div>
        </Link>
        <Link to="/"  onClick={() => isShowCategoryPopUp.setIsShowCategoryPopUp(false)}>
          <div
            className="main-bot-menu h-[50px] w-[50px] bg-[#010125] shadow rounded-full  px-[10px] pb-[2px] flex items-center 
                    justify-center shadow-btn
                    cursor-pointer"
            onClick={goToTopSmooth}
          >
            <BiHomeAlt2 className="text-white text-[23px] cursor-pointer" />
          </div>
        </Link>

        <Link to={"cart"} onClick={() => isShowCategoryPopUp.setIsShowCategoryPopUp(false)}>
          <div
            className="cart-bot-menu w-[40px] rounded-full relative bg-white flex items-center
                        justify-center flex-col gap-y-[5px] pt-[2px] cursor-pointer 
                          "
            onClick={goToTopFast}
          >
            <Basket className="text-[#010125] text-[19px]" />
            <div className="w-[17px] h-[17px] rounded-full top-[-5px] right-[-5px] absolute bg-[#010125] text-white flex items-center justify-center">
              <p className="pt-[2px] text-[10px]">
                {newCartData.cartData.length}
              </p>
            </div>
            <h1 className="text-[8px]">კალათა</h1>
          </div>
        </Link>
        <div
          className="prof-btn-bot-menu h-[40px] w-[40px] rounded-full  px-[10px] flex items-center 
                    justify-center 
                    cursor-pointer flex-col gap-[5px] pt-[2px]"
          onClick={() => {
            handleSecaut();
            goToTopFast();
            isShowCategoryPopUp.setIsShowCategoryPopUp(false)
          }}
        >
          <Person className="text-[#010125] text-[23px] cursor-pointer" />
          <h1 className="text-[8px]">ანგარიში</h1>
        </div>
      </div>
      {secAut && <SecAutorization handleSecaut={handleSecaut} />}
    </div>
  );
}
