import React from 'react'
import { useContext } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import IsShowCategoryPopUp from '../contexts/IsShowCategoryPopUp';

const SubSubCategory = ({subSubCategoryData, setIsSubSubCategory,handleShowMenu,setShowMenu}) => {
  const isShowCategoryPopUp = useContext(IsShowCategoryPopUp)
  return (
    <ul className="gap-y-[10px] flex flex-col px-4">
      <li
        onClick={() => setIsSubSubCategory(false)} 
        className=" group border-b-2 border-slate-200 flex justify-between items-center ">
          <div>
            <BiChevronLeft className='text-[20px] mb-2'/> 
          </div>
          <h1 className="css-elipses text-[13px] py-[5px] mb-2">{subSubCategoryData.titleGeo}</h1>
      </li> 
      { 
        subSubCategoryData.subCategory.map((item) => (
          <li
            key={item.id}
            // onClick={() => {setSubSubCategoryData(item); setIsSubSubCategoryData(true)}}
            className={` group ${item.id === subSubCategoryData.subCategory.length ? '': "border-b-2 border-slate-200"} flex justify-between items-center `}>
            <Link
              onClick={() => {setShowMenu(false);isShowCategoryPopUp.setIsShowCategoryPopUp(false)}} 
              to={`/products/${subSubCategoryData.parent.textLink}/${subSubCategoryData.title}/${item.title} `} 
              className='flex items-center cursor-pointer group-one text-gray-600 pr-[5px]'>
              <h1 className="css-elipses text-[13px] py-[5px] mb-2">{item.titleGeo}</h1>
              {
                item.subCategory &&
                <div>
                  <BiChevronRight className='text-[20px] mb-2'/> 
                </div>
              }
            </Link>
          </li>
        ))
      }
    </ul>
  )
}

export default SubSubCategory