import { Geo } from "react-bootstrap-icons";

function MapPart() {
  return (
    <div className="map-div items-center flex justify-center mt-[35px] pt-[35px] pb-[50px] ">
      <div className="map-part max-w-[1526px] px-[110px] w-[100%]">
        <div className="relative map-content flex rounded-[15px]">
          <div className="map-txt w-[50%] p-[40px] bg-white rounded-l-[15px]">
            <div className="flex gap-[1%]">
              <h1 className="map-title text-[30px] font-semibold text-[#010125a2]">ჩვენი</h1>
              <h1 className="map-title text-[30px] font-semibold text-[#010121]">
                მისამართები
              </h1>
            </div>
            <div className="map-city-list flex gap-[20px] mt-[30px] justify-between w-[270px]">
              <div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>თბილისი</p>
                </div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>წყნეთი</p>
                </div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>კიკეთი</p>
                </div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>კოჯორი</p>
                </div>
              </div>
              <div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>ახალდაბა</p>
                </div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>ტაბახმელა</p>
                </div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>წოდორეთი</p>
                </div>
                <div className="flex mt-[10px] items-center">
                  <Geo className="mr-[5px]" />
                  <p>მუხათწყარო</p>
                </div>
              </div>
            </div>
          </div>
          <div className="map-img w-[50%] rounded-r-[18px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11875.22742360023!2d45.477690849999995!3d41.9185106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ska!2sge!4v1676545954815!5m2!1ska!2sge"
              className="img w-[100%] h-[100%] border-0 rounded-r-[15px]"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapPart;
