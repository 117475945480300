import { Buildings, Plus, XLg } from "react-bootstrap-icons";
import MiniDropDown from "./minidropsown";
import { useState } from "react";
import AdressInProfile from "./adress-inprofile";
import Address from "../adress/Address";
import AddMoreAdress from "../adress/addMoreAdresses";
import { HouseDoor } from "react-bootstrap-icons";

function ProfileAdress() {
  const [showLocDesk, setShowLocDesk] = useState(false);

  const handleShowLocDesk = () => {
    setShowLocDesk((prev) => !prev);
  };

  const [showMoreAdresDesk, setShowMoreAdresDesk] = useState(false);

  const handleShowMoreAdresDesk = () => {
    setShowMoreAdresDesk((prev) => !prev);
  };

  return (
    <div className="pb-[30px]">
      <h1 className="adress-title mb-[40px] text-[22px]">მისამართები</h1>
      <div className="adress-conts flex flex-col gap-y-[15px] justify-between">
        <div
          className="add-new-adrs flex items-center gap-[5px] p-[20px] rounded-[16px] text-[#010125] bg-white w-[70%] hover:shadow cursor-pointer"
          onClick={handleShowLocDesk}
        >
          <div className="add-adrs-icn w-[50px] h-[50px] rounded-full bg-gray-100 flex items-center justify-center">
            <div>
              <Plus className="text-[30px]" />
            </div>
          </div>
          <p className="add-adres-text">ახალი მისამართის დამატება</p>
        </div>
        <AdressInProfile
          MiniDropDown={MiniDropDown}
          title={"სახლი"}
          address={"61 Vazha Pshavela Ave, Tbilisi, Georgia"}
          icon={<HouseDoor/>}
        />
        <AdressInProfile
          MiniDropDown={MiniDropDown}
          title={"სამსახური"}
          address={"04 aleksandre yazbegi, Tbilisi, Georgia"}
          icon={<Buildings/>}
        />
      </div>
      {showLocDesk && (
        <Address
          handleShowLocDesk={handleShowLocDesk}
          handleShowMoreAdresDesk={handleShowMoreAdresDesk}
        />
      )}
      {showMoreAdresDesk && (
        <AddMoreAdress
          showMoreAdresDesk={showMoreAdresDesk}
          handleShowLocDesk={handleShowLocDesk}
          handleShowMoreAdresDesk={handleShowMoreAdresDesk}
        />
      )}
    </div>
  );
}

export default ProfileAdress;
