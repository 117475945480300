import { XLg } from "react-bootstrap-icons";
import { useState } from "react";
import SecRegistration from "./secRegistrationPopup";
import SecStartRegpage from "./secRegStartpage";
import ForgetPassword1 from "./forgotpassword1";
import ForgetPassword2 from "./forgetpassword2";
import ForgetPassword3 from "./forgetpassword3";
import AutorDone from "./autorDone";

function SecAutorization({ handleSecaut, handleDone }) {
  const [openreg, setOpenreg] = useState(false);
  const handleOpenreg = () => {
    setOpenreg((prev) => !prev);
  };

  const [active, setActive] = useState("signin");

  return (
    <div className="bg-[#000000bf]  w-[100vw] h-[100vh] z-[99] fixed top-0 left-0 items-center flex justify-center">
      <div className="w-[100%] h-[100%] absolute" onClick={handleSecaut}></div>
      <div className="secAutormain w-[40%] z-[99] bg-white rounded-[18px]">
        <div className="autorizhed flex items-center justify-between px-[30px] py-[10px] border-b-2 border-gray-200">
          <h1 className="text-[18px]">შესვლა</h1>
          <div
            className="w-[40px] h-[40px] items-center bg-slate-100 cursor-pointer justify-center flex   rounded-full"
            onClick={() => {
              handleSecaut();
            }}
          >
            <XLg className='text-black' />
          </div>
        </div>
        <div className="secautorcontt pt-[30px] pb-[50px] flex  flex-col gap-y-[20px]">
          <div className="flex px-[30px] gap-[10px] justify-center">
            <h1
              className={`avtoreg  text-[15px] py-[10px] cursor-pointer border-black ${
                active === "signin"
                  ? "border-b-[2px] text-black"
                  : "text-gray-400 hover:text-black"
              }`}
              onClick={() => {
                setActive("signin");
              }}
            >
              ავტორიზაცია
            </h1>
            <h1
              className={`avtoreg  text-[15px] py-[10px] cursor-pointer border-black ${
                active === "newacc"
                  ? "border-b-[2px] text-black"
                  : "text-gray-400 hover:text-black"
              }`}
              onClick={() => {
                setActive("newacc");
              }}
            >
              რეგისტრაცია
            </h1>
          </div>
          <div className="min-h-[300px]  flex items-center">
            {active === "signin" && (
              <SecStartRegpage
                openreg={openreg}
                handleOpenreg={handleOpenreg}
                setActive={setActive}
                handleSecaut={handleSecaut}
              />
            )}
            {active === "newacc" && (
              <SecRegistration
                openreg={openreg}
                handleOpenreg={handleOpenreg}
                handleSecaut={handleSecaut}
              />
            )}
            {active === "forgetpassword1" && (
              <ForgetPassword1 setActive={setActive} />
            )}
            {active === "forgetpassword2" && (
              <ForgetPassword2 setActive={setActive} />
            )}
            {active === "forgetpassword3" && (
              <ForgetPassword3
                setActive={setActive}
                handleSecaut={handleSecaut}
                handleDone={handleDone}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecAutorization;
