import { Plus, Search } from "react-bootstrap-icons";
import OrderedCard from "./orderedcard";
import { PrimaryContext } from "../../allPages/MainContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import easyway from "../../../imgs/easyway.svg";
import glovo from "../../../imgs/78.webp";
import wolt from "../../../imgs/80.webp";
import CartData from "../../contexts/CartData";
import ActiveMoney from "../../contexts/activeMoney";
function FullCardBg() {
  const activeMoney = useContext(ActiveMoney);
  const newCartData = useContext(CartData);
  const [thisData, setThisData] = useState([]);
  const [showAddPromo, setShowAddPromo] = useState(false);
  const [allPrice, setAllPrice] = useState(0);
  const [deliveryCompanysData, setDeliveryCompanysData] = useState([
    {
      id: 1,
      img: easyway,
      priceDelivery: 5
    },
    {
      id: 2,
      img: glovo,
      priceDelivery: 6
    },
    {
      id: 3,
      img: wolt,
      priceDelivery: 7
    },
  ])
  const [pickdelivery, setPickdelivery] = useState(deliveryCompanysData[0]);
  useEffect(() => {
    setAllPrice(0);
    newCartData.cartData.map((item) => {
      if (item.sale) {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice(
            (prev) =>
              prev +
              (item.price - (item.price / 100) * item.sale) * item.counter
          );
        } else {
          return setAllPrice(
            (prev) =>
              prev +
              (item.dolPrice - (item.dolPrice / 100) * item.sale) * item.counter
          );
        }
      } else {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice((prev) => prev + item.price * item.counter);
        } else {
          return setAllPrice((prev) => prev + item.dolPrice * item.counter);
        }
      }
    });
  }, [newCartData]);


  const [Checked, setChecked] = useState("geo");

  return (
    <div>
      <div className="cart-card-container flex flex-wrap p-[15px] justify-start gap-[1.2%] gap-y-[10px]">
        <div className="w-full flex justify-between border-b-[2px] pt-[8px] pb-[13px] text-[16px] px-[20px]  gap-[20px] max-md:hidden">
          <div className="w-[20px]"> </div>
          <h1 className="w-[38%] text-center">პროდუქტი</h1>

          <div className="flex w-[60%] max-md:w-[70%] max-sm:w-[75%]">
            <h1 className="w-[40%] text-center">ფასი</h1>
            <h1 className="w-[35%] text-center">რაოდენობა</h1>
            <h1 className="w-[25%] text-center">ჯამი</h1>
          </div>
        </div>
        {newCartData.cartData.map((object) => {
          return (
            <OrderedCard 
              item={object}
              newCartData={newCartData}
              key={object.id}
              Id={object.id}
              img={object.img}
              name={object.name}
              price={object.price}
            />
          );
        })}
      </div>
      <div className="cartbottomsum hidden bg-white p-[8px] flex-col rounded-[10px] border-t-[2px]  mt-[40px]">
        <div className="flex border-b-2 pb-[20px] pt-[10px] justify-between ">
          <p>ჯამი</p>
          {allPrice} {activeMoney.activeMoney === "lari" ? "₾" : "$"}
        </div>
        <div className="flex flex-col gap-y-[15px] border-b-2 border-[#efefef] py-[20px]">
          <p className="font-semibold">მიწოდება</p>
          <div className="flex flex-col gap-y-[5px]">
            <div className="flex justify-between">
              <div
                className="flex gap-[10px] items-center"
                onClick={() => setChecked('geo')}
              >
                <div
                  className={`w-[10px] h-[10px] rounded-full ${
                    Checked === "geo"
                      ? "bg-[#010125]"
                      : "bg-white border-[1px] border-[#010125]"
                  }`}
                ></div>
                <p>საქართველო:</p>
              </div>
            </div>
            <div className={` ${ Checked === "geo" ? "flex" : "hidden"}`}>
              <div className="flex flex-col border-[1px] border-[#010125] items-center gap-y-[3px] bg-gray-100 py-[10px] px-[12px] rounded-[8px]">
                <div className="w-[40px] h-[30px]">
                  <img
                    src={easyway}
                    className="w-full h-full object-contain"
                    alt=""
                  />
                </div>
                <p>5{activeMoney.activeMoney === "lari" ? "₾" : "$"}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-[5px]">
            <div className="flex justify-between">
              <div
                className="flex gap-[10px] items-center"
                onClick={() => setChecked('tbilisi')}
              >
                <div
                  className={`w-[10px] h-[10px] rounded-full ${
                    Checked === "geo"
                      ? "bg-white border-[1px] border-[#010125]"
                      : " bg-[#010125]"
                  }`}
                ></div>
                <p>თბილისი:</p>
              </div>
              <p className=""></p>
            </div>
            <div
              className={` gap-[5px] overflow-x-auto ${
                Checked === "geo" ? "hidden" : "flex"
              }`}
            >
              {deliveryCompanysData.map((item) => (
                <div
                  key={item.id}
                  onClick={() => setPickdelivery(item)}
                  className={`border-[#010125] ${
                    pickdelivery && (pickdelivery.id === item.id )&& "border-[1px]"
                  } flex flex-col items-center gap-y-[3px] bg-gray-100 py-[10px] px-[12px] rounded-[8px]`}
                >
                  <div className="w-[40px] h-[30px]">
                    <img
                      src={item.img}
                      className="w-full h-full object-contain"
                      alt="#"
                    />
                  </div>
                  <p>
                    {item.priceDelivery}
                    {activeMoney.activeMoney === "lari" ? "₾" : "$"}
                  </p>
              </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex border-b-2 py-[20px] justify-between ">
          <p>პრომო კოდი -20%</p>
          <p>
            {(allPrice / 100) * 20}
            {activeMoney.activeMoney === "lari" ? "₾" : "$"}
          </p>
        </div>
        <div className="flex justify-between py-[20px] border-b-2 border-[#efefef]">
          <p className="font-semibold">მიწოდება</p>
          <p>{ Checked === 'tbilisi' ? pickdelivery.priceDelivery : 5} {activeMoney.activeMoney === "lari" ? "₾" : "$"}{" "}</p>
        </div>
        <div className="flex pt-[20px] justify-between font-semibold">
          <p>სულ თანხა</p>
          <p>
            {(allPrice - (allPrice / 100) * 20)+(Checked === 'tbilisi' ? pickdelivery.priceDelivery : 5)}
            {activeMoney.activeMoney === "lari" ? "₾" : "$"}{" "}
          </p>
        </div>
      </div>
      <div className="order-end-btn hidden flex-col w-full items-center py-[10px] px-[16px] bg-[white] rounded-[10px] mt-[10px] gap-y-[10px]">
        <div className="flex  justify-between items-center rounded-[10px] h-[45px] px-[5px] w-[50%] max-[530px]:w-[250px]">
          <input
            type="text"
            placeholder="პრომო კოდი"
            className=" outline-none border-b-[1px] border-[#010125] h-full w-[85%] px-[5px]"
          />
          <div className="w-[150px] h-full bg-[#010125] text-white flex justify-center items-center rounded-[30px] cursor-pointer text-[14px]">
            <h1>შენახვა</h1>
          </div>
        </div>
        <Link to="/checkout" className="w-[50%] max-[530px]:w-[250px]">
          <h1 className="endOrderCart bg-[#010125] text-white flex items-center py-[10px] cursor-pointer justify-center w-full rounded-[12px] tracking-wider max-[820px]:text-[14px] ">
            შეკვეთის გაგრძელება
          </h1>
        </Link>
      </div>
    </div>
  );
}

export default FullCardBg;
