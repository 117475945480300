import { useState, useEffect } from "react";
import { Dash, PlusLg } from "react-bootstrap-icons";

function ProdpageCounter({ setCounternum, test1 }) {
  const [count, setCount] = useState(1);
  const handleInc = () => {
    setCount((prev) => prev + 1);
  };
  const handleDec = () => {
    if (count !== 1) {
      setCount((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if (setCounternum) {
      setCounternum(count);
    }
  }, [count]);
  useEffect(() => {
    if (test1) {
      setCount(1);
    }
  }, [test1]);
  return (
    <div className="items-center  p-[7px] flex  justify-between rounded-[33px] bg-gray-200">
      <div
        onClick={() => {
          handleDec();
        }}
        className="whenMoreTwo w-[30px] rounded-full h-[30px] border  flex items-center
         justify-center text-gray-500 bg-white border-gray-300 shadow-sm"
      >
        <Dash className="text-[23px] cursor-pointer " />
      </div>

      <p className="num w-[20%] mt-[3px] text-[18px] h-[24px] items-center flex justify-center">
        {count}
      </p>

      <div
        onClick={() => {
          handleInc();
        }}
        className="w-[30px] rounded-full h-[30px] flex items-center border
        justify-center text-gray-500 bg-white border-gray-300 shadow-sm"
      >
        <PlusLg className="text-[20px] font-semibold  cursor-pointer" />
      </div>
    </div>
  );
}

export default ProdpageCounter;
