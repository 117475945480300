import { useState } from "react";
import { At, Eye, EyeSlash, Lock } from "react-bootstrap-icons"

function SecStartRegpage({openreg, handleSecaut, handleOpenreg, setActive}){
  const [show, setshow] = useState(false);

  const handleshow = () => {
    setshow((pre) => !pre);
  };
    return(
        <div className="registrationpart w-[100%] flex flex-col items-center px-[18%] gap-y-[10px]">
            <p className="mailtitle text-[20px] text-center">
              სისტემაში შესასვლელად <br /> შეიყვანეთ ელ-ფოსტა
            </p>
            <div className="mailinp flex border-2 rounded-[16px] w-full pr-[7px] h-[50px] text-gray-500 items-center">
              <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
                <At className="text-[20px]" />
              </div>
              <input
                type="text"
                className="outline-none w-[100%] pb-[5px]"
                placeholder="ელ-ფოსტა"
              />
            </div>
            <div className="mailinp flex border-2 rounded-[16px] w-full pr-[7px] h-[50px] text-gray-500 items-center">
              <div className="h-[100%] w-[50px] rounded-l-[16px] flex justify-center items-center">
                <Lock className="text-[20px]" />
              </div>
              <input
                type={show ? "text" : "password"}
                className="outline-none w-[100%] pb-[5px]"
                name="password"
            id="password"
            placeholder="***********"
              />
              <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <Eye className="cursor-pointer" />
            ) : (
              <EyeSlash className="cursor-pointer" />
            )}
          </div>
            </div>
            <div onClick={()=>(handleSecaut())} className="secletin flex border-2 rounded-[16px] w-full pr-[7px] h-[60px] text-white mainBg items-center flex-col justify-center cursor-pointer">
                <h1>შესვლა</h1>
            </div>
            <div className="secStartbottxts flex gap-[30px]">
                <p className="underline cursor-pointer" onClick={() => {
            setActive("forgetpassword1");
          }}>დაგავიწყდა პაროლი?</p>
            </div>
          </div>
    )
}

export default SecStartRegpage