function GoogleMap() {
  return (
    <div className="mapstyle w-[100%] h-[400px] p-[15px] shadow-sm rounded-[15px] bg-white">
      <iframe
        title=""
        className="w-[100%] h-[100%] rounded-[15px]"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d186.0902007094683!2d44.76736056373396!3d41.73253479825212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044731980300997%3A0xa4c22bcce1ed8875!2z4YOT4YOU4YOT4YOQ4YOhIOGDnuGDo-GDoOGDmCBEZWRhcyBQdXJp!5e0!3m2!1ska!2sge!4v1672055062572!5m2!1ska!2sge"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default GoogleMap;
