const DescripTionTypeOne = ({ descriptionName, descriptionText }) => {
    return (
      <div className="each-habit flex w-[100%] border-b-[1px] border-b-gray-300 mb-[5px] pb-[3px]">
        <p className="name text-sm w-[190px] max-sm:text-[12px]">
          {descriptionName}:
        </p>
        <p className="descrip w-[80%] text-sm max-sm:text-[12px]">
          {descriptionText}
        </p>
      </div>
    );
  };

function Habits(){
    return(
        <div className="flex flex-col gap-2">
          {data.map((item, value) => {
            return (
                <DescripTionTypeOne
                  descriptionName={item.descriptionName}
                  descriptionText={item.descriptionText}
                />
            );
          })}
        </div>
    )
}

export default Habits

const data = [
    {
      descriptionName: "გამოშვების თარიღი",
      descriptionText: "2022, სექტემბერი",
    },
    {
      descriptionName: "ბრენდი",
      descriptionText: "Apple",
    },
    {
      descriptionName: "მოდელი",
      descriptionText: "IPHONE 13 PRO MAX SINGLE SIM 128GB ALPINE GREEN",
    },
    {
      descriptionName: "ტექნოლოგია",
      descriptionText: "GSM/CDMA/HSPA/EVDO/5G",
    },
    {
      descriptionName: "კავშირის სტანდარტი",
      descriptionText: "5G",
    },
    {
      descriptionName: "კორპუსის აწყობა",
      descriptionText: "Front/Back Glass, aluminium frame",
    },
    {
      descriptionName: "სიმ ბარათი-SIM",
      descriptionText: "Single SIM",
    },
    {
      descriptionName: "სიმ ბარათი-SIM ტიპი",
      descriptionText: "Nano-SIM, Dual stand-by",
    },
    {
      descriptionName: "კორპუსის დაცვა",
      descriptionText: "IP68 dust/water resistant (up to 6m for 30mins)",
    },
  ];