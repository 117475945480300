import {
  Border,
    Grid,
    GridFill,
    HddStack,
    HddStackFill,
    JustifyLeft,
  } from "react-bootstrap-icons";
import {VscListSelection} from 'react-icons/vsc'
import {RiFilter2Line} from 'react-icons/ri'
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef, useContext } from "react";
import SaleHorizontalCard from "../mshopContent/salehorizontalcard";
import SliderComponent from "../slider/ProdSliderComponent";
import ProdPopup from "../slider/prodPopup";
import { PrimaryContext } from "../../allPages/MainContext";
import CategoryData from "../../contexts/CategoryData";
import ProductStyleContext from "../../contexts/ProductStyleContext";
import Salecomponent from "../slider/saleComponent";
import SlideComponent3 from "../slider/slidecomponent3";
import IsShowCategoryPopUp from "../../contexts/IsShowCategoryPopUp";
import DropedFilter from "../mshopContent/dropedfilter";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
  
  function MainCategory() {
    const location = useLocation();
    const categoryData = useContext(CategoryData)
    const isShowCategoryPopUp = useContext(IsShowCategoryPopUp)
    const productStyleContext = useContext(ProductStyleContext)
    const [subCategory, setSubCategory] = useState()
    const [showFilter7, setShowFilter7] = useState(false)
    const [lastAndLast1,setLastAndLast1] = useState('')
    const [pageTitle,setPageTitle] = useState('')
    const {pathname} = location;
    const test = pathname.split("/")[2]
    const test2 = pathname.split("/")[3]
    const params=useParams()
    const {category} = params
    const [sort,setSort] = useState('')
    const [isShowSort,setIsShowSort] = useState('')


    useEffect(() => {
      setSubCategory(categoryData.categoryData.filter((item) => item.textLink === test)[0])
    },[pathname])

    useEffect(()=>{

      let lastAndLast = ''
      if(subCategory && test2){
        subCategory.subCategory.map((item) => {
          if(item.title === test2){
            lastAndLast=item.titleGeo
          }
        })
      }
      setLastAndLast1(lastAndLast)
    },[test2])
  
  
  
    const priceRef = useRef(null);
  
    useEffect(() => {
      // add event listener to document when component mounts
      document.addEventListener("mousedown", handleClickOutside);
  
      // cleanup event listener when component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    function handleClickOutside(event) {
      if (priceRef.current && !priceRef.current.contains(event.target)) {
        // click occurred outside of wrapper, close the div here
        setPricefltr(false);
      }
    }
  
    const [pricefltr, setPricefltr] = useState(false);
  
    const handlePricefltr = () => {
      setPricefltr((prev) => !prev);
    };
  
    const [active, setActive] = useState("");
  
    const [cardStyle, setCardStyle] = useState(true);
  ///////
  const myData = useContext(PrimaryContext).myData;
  const [data, setData] = useState(null);
  const [quantity, setQuantity] = useState();
  useEffect(() => {
    setData(myData);
  }, [myData]);

  const myDataslider = useContext(PrimaryContext).myDataslider;
  const [ravidata, setraviData] = useState(null);

  useEffect(() => {
    setraviData(myDataslider);
  }, [myDataslider]);



  const [popupData, setPopupData] = useState({});

  const handlePopupDataChange = (item, amount) => {
    setPopupData({ ...item, amount: amount });
  };

  const handlePopupDataAmount = (amount) => {
    setPopupData({ ...popupData, amount: amount });
  };

  const [showProdPopup, setShowProdPopup] = useState(false);

  const handleShowProdPopup = () => {
    setShowProdPopup((prev) => !prev);
  };


  useEffect(() => {
    let one =0;
    let two =0;
    if (myData && myDataslider) {
      one = myData.length
      two = myDataslider.length
      setQuantity(one + one + two)
    }
  }, [myData]);
  useEffect(() => {
    let newTitle='პროდუქტები'
    categoryData.categoryData.map((item) => {
      if(item.textLink === test){
        newTitle=item.text
      }
    })
    setPageTitle(newTitle)
  },[test])

  useEffect(() => {
    if (showProdPopup) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }, [showProdPopup]);
///////
    return (
      <div className=" w-full px-[25px] py-10 max-[1200px]:pt-0 bg-gray-100 h-[100%]">
        <div className="w-[100%] grid gap-y-[20px]">
          <div className="max-[700px]:flex-wrap w-[100%] h-auto flex justify-between items-start gap-[10px]">
            <div className=" flex gap-2 items-end">
              <h1 className=" text-[18px]">
                {pageTitle}
              </h1>
              <p className="text-[14px] text-gray-700 pb-[5px]">({quantity})</p>
            </div>
            <div
              ref={priceRef}
              className="relative flex max-[700px]:w-full"
            >
              <div className="flex items-center w-[100%] justify-between">
                <div className="flex items-start h-auto w-full  gap-2  max-[700px]:justify-between max-[700px]:flex-row-reverse">
                  <div className='test1 flex items-start max-[350px]:flex-wrap'>
                    <div className={`flex z-[5] cursor-pointer flex-col h-[35px] w-40 max-[410px]:w-32 mb-2 mx-4 border-[1px] ${isShowSort === true ? ' rounded-t-[15px]':' rounded-[15px]'} bg-white  border-slate-200 shadow-sm items-center justify-start`}>
                      <h1
                        onClick={() => setIsShowSort((prev) => !prev)} 
                      className='text-[12px]  p-2'>{sort ? sort :'სორტირება'}</h1>
                      {
                        isShowSort && 
                        <div className='w-full h-auto bg-white border-[1px] rounded-b-[15px] border-slate-200'>
                          <div className='w-full p-2 h-auto flex flex-col justify-center items-center gap-1'>
                            <h1
                              onClick={() => {setSort('ფასდაკლებით');setIsShowSort((prev) => !prev)}} 
                              className='text-[12px]  '>ფასდაკლებით</h1>
                            <h1
                              onClick={() => {setSort('პოპულარობით');setIsShowSort((prev) => !prev)}} 
                              className='text-[12px]  '>პოპულარობით</h1>
                            <h1
                              onClick={() => {setSort('პოპულარობით');setIsShowSort((prev) => !prev)}} 
                              className='text-[12px]  '>ფასის კლებით</h1>
                            <h1
                              onClick={() => {setSort('პოპულარობით');setIsShowSort((prev) => !prev)}} 
                              className='text-[12px]  '>ფასის ზრდით</h1>
                          </div>
                        </div>
                      }
                    </div>
                    <div
                      onClick={() => {setShowFilter7(true)}}  
                      className=" gap-[2px] hidden max-[1200px]:flex ">
                      <div
                        className='w-[30px] h-[30px] rounded-full flex items-center justify-center'>
                        <RiFilter2Line className='text-[25px]'/>
                      </div>
                      <div className='flex items-center'>
                        <p> ფილტრი </p>
                      </div>
                    </div>
                  </div>
                  <div className='flex'>
                    <div
                      className="w-[30px] h-[30px] flex items-center justify-center text-[20px]  cursor-pointer"
                      onClick={() => {
                        setCardStyle(true);
                      }}
                    >
                      {cardStyle ? <GridFill /> : <Grid />}
                    </div>
                    <div className=''>
                      <div
                        className="w-[30px] h-[30px] flex items-center justify-center text-[22px]  cursor-pointer"
                        onClick={() => {
                          setCardStyle(false);
                        }}
                      >
                        {!cardStyle ? <HddStackFill /> : <HddStack />}
                      </div>
                    </div>
                  </div>
                </div>

                {showFilter7 && <DropedFilter setShowFilter7={setShowFilter7} showFilter7={showFilter7}/>}
              </div>
              
              </div>
          </div>
          <div className="w-[100%]">
            <div className=" w-[100%]">
              <div className="giftcard-container flex-wrap gap-y-[20px] gap-[3.5%] flex">
                {!(data === null) && (
                    <>
                      {data.map((object) => {
                        if(productStyleContext.productStyleContext==='style1'){
                          return(
                          cardStyle ? (
                            <div key={object.id} className="giftcard-sec-cont w-[31%]">
                              <SliderComponent
                                item={object}
                                img={object.img}
                                title={object.name}
                                price={object.price}
                                sale={object.sale}
                                index={object.id}
                                saleis={object.saleis}
                                empty={object.empty}
                                // handleClickedId={handleClickedId}
                                handleShowProdPopup={handleShowProdPopup}
                                handlePopupDataChange={handlePopupDataChange}
                              />
                            </div>
                            ) : (<div key={object.id} className="horizontalcard w-[100%]">
                              <SaleHorizontalCard 
                                item={object}
                                img={object.img}
                                title={object.name}
                                price={object.price}
                                sale={object.sale}
                                index={object.id}
                                saleis={object.saleis}
                                empty={object.empty}
                                // handleClickedId={handleClickedId}
                                handleShowProdPopup={handleShowProdPopup}
                                handlePopupDataChange={handlePopupDataChange}/>
                            </div>)
                          )
                        }
                        if(productStyleContext.productStyleContext==='style2'){
                          return(
                            cardStyle ? (
                              <div key={object.id} className="giftcard-sec-cont w-[31%]">
                                <Salecomponent
                                  item={object}
                                  img={object.img}
                                  title={object.name}
                                  price={object.price}
                                  sale={object.sale}
                                  index={object.id}
                                  empty={object.empty}
                                  handleShowProdPopup={handleShowProdPopup}
                                  handlePopupDataChange={handlePopupDataChange}
                                />
                              </div>
                              ) : (<div key={object.id} className="horizontalcard w-[100%]">
                                <SaleHorizontalCard 
                                  item={object}
                                  img={object.img}
                                  title={object.name}
                                  price={object.price}
                                  sale={object.sale}
                                  index={object.id}
                                  saleis={object.saleis}
                                  empty={object.empty}
                                  // handleClickedId={handleClickedId}
                                  handleShowProdPopup={handleShowProdPopup}
                                  handlePopupDataChange={handlePopupDataChange}/>
                              </div>)

                          )
                        }
                        if(productStyleContext.productStyleContext==='style3'){
                          return(
                            cardStyle ? (
                              <div key={object.id} className="giftcard-sec-cont w-[31%]">
                                <SlideComponent3
                                  item={object}
                                  img={object.img}
                                  title={object.name}
                                  sale={object.sale}
                                  price={object.price}
                                  index={object.id}
                                  setPopupData={setPopupData}
                                  handleShowProdPopup={handleShowProdPopup}
                                  handlePopupDataChange={handlePopupDataChange}
                                  empty={object.empty}
                      />
                              </div>
                              ) : (<div key={object.id} className="horizontalcard w-[100%]">
                                <SaleHorizontalCard 
                                  item={object}
                                  img={object.img}
                                  title={object.name}
                                  price={object.price}
                                  sale={object.sale}
                                  index={object.id}
                                  saleis={object.saleis}
                                  empty={object.empty}
                                  // handleClickedId={handleClickedId}
                                  handleShowProdPopup={handleShowProdPopup}
                                  handlePopupDataChange={handlePopupDataChange}/>
                              </div>)

                          )
                        }
                        
                      })}
                    </>
                  )}
                  
              </div>
            {showProdPopup && (
              <ProdPopup
                item={popupData}
                handleShowProdPopup={handleShowProdPopup}
                image={popupData.img}
                title={popupData.name}
                price={popupData.price}
                amount={popupData.amount}
                empty={popupData.empty}
                sale={popupData.sale}
                handlePopupDataAmount={handlePopupDataAmount}
              />
            )}
          {/* </>
        )} */}
      </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default MainCategory;
  