import { React, useContext } from "react";
import CartData from "./contexts/CartData";
import { Dash, PlusLg } from "react-bootstrap-icons";

const CartPopUpCounter = ({ item }) => {
  const cartData = useContext(CartData);
  const handelDelete = (Id) => {
    const newCart = cartData.cartData.filter((item) => Id !== item.id);
    cartData.setCartData(newCart);
    localStorage.setItem("items", JSON.stringify(newCart));
  };
  const decrement = (item) => {
    if (item.counter > 1) {
      const newCartData = [...cartData.cartData];
      newCartData.map((items) => {
        if (items.id === item.id) {
          return (items.counter = items.counter - 1);
        }
      });
      cartData.setCartData(newCartData);
    } else {
      handelDelete(item.id);
    }
  };
  const increment = (item) => {
    const newCartData = [...cartData.cartData];
    newCartData.map((items) => {
      if (items.id === item.id) {
        return (items.counter = items.counter + 1);
      }
    });
    cartData.setCartData(newCartData);
  };
  
  return (
    <div className="items-center h-[30px] px-[5px] flex  justify-between   rounded-[33px] bg-[#010125a2] text-white">
      <div
        onClick={() => {
          decrement(item);
        }}
        className="whenMoreTwo w-[38%] flex items-center justify-start"
      >
        <Dash className="text-[17px] cursor-pointer " />
      </div>

      <p className="num w-[20%] mt-[3px] text-[15px] items-center flex justify-center">
        {item.counter}
      </p>

      <div
        onClick={() => {
          increment(item);
        }}
        className="w-[33%] flex justify-end"
      >
        <PlusLg className="text-[17px] font-semibold  cursor-pointer" />
      </div>
    </div>
  );
};

export default CartPopUpCounter;
