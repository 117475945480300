import { Link, useLocation } from "react-router-dom";

import logoo from "../imgs/300.webp";
import React from "react";
import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { SiAmazonpay } from "react-icons/si";
import { GrAmex } from "react-icons/gr";
import { ImFacebook } from "react-icons/im";
import { RiVisaLine } from "react-icons/ri";
import { FaTiktok, FaGooglePay, FaApplePay } from "react-icons/fa";
import { At } from "react-bootstrap-icons";

function Footer() {
  let location = useLocation();
  return (
    <div className="footer-main w-[100%]  bg-[#010125] flex flex-col items-center ">
      <div
        className={` w-[100%] h-[100%] ${
          location.pathname === "/mainshop" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/yvela" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/sachuqrebi" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/khachapuri" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/soko-kecze" ||
          location.pathname === "/mainshop/mza-kerdzebi/everything" ||
          location.pathname === "/mainshop/mza-kerdzebi/ravii" ||
          location.pathname === "/mainshop/mza-kerdzebi/supi-souzi" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/fasdaklebit" ||
          location.pathname === "/cart" ||
          location.pathname === "/profile" ||
          location.pathname === "/profile/profile-orders" ||
          location.pathname === "/profile/profile-address" ||
          location.pathname === "/profile/profile-payment" ||
          location.pathname === "/profile/profile-promocodes" ||
          location.pathname === "/profile/profile-profwind" ||
          location.pathname === "/profile/profile-profhelp" ||
          location.pathname === "/favoriteProd"
            ? "max-w-[2100px]"
            : "max-w-[1526px]"
        } `}
      >
        <div
          className={`justify-between flex  py-[40px]  ${
            location.pathname === "/mainshop" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/yvela" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/sachuqrebi" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/khachapuri" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/soko-kecze" ||
            location.pathname === "/mainshop/mza-kerdzebi/everything" ||
            location.pathname === "/mainshop/mza-kerdzebi/ravii" ||
            location.pathname === "/mainshop/mza-kerdzebi/supi-souzi" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/fasdaklebit" ||
            location.pathname === "/cart" ||
            location.pathname === "/profile" ||
            location.pathname === "/profile/profile-orders" ||
            location.pathname === "/profile/profile-address" ||
            location.pathname === "/profile/profile-payment" ||
            location.pathname === "/profile/profile-promocodes" ||
            location.pathname === "/profile/profile-profwind" ||
            location.pathname === "/profile/profile-profhelp" ||
            location.pathname === "/favoriteProd"
              ? "footer-shop px-[20px]"
              : "footer px-[110px]"
          } `}
        >
          <div className="w-full h-auto flex flex-wrap justify-between max-md:gap-y-[30px]">
            <div className="flex flex-col gap-y-[20px] w-[23%] max-md:w-[31%] max-sm:items-start max-sm:w-full">
              <div className="h-[60px]">
                <img src={logoo} className="h-[100%]" alt="" />
              </div>
              <p className="text-white max-sm:text-start">
                წარმატების გზა, რომელიც კოდის ერთი ხაზით იწყება
              </p>
            </div>
            <div className=" flex flex-col gap-7 text-white w-[23%] max-md:w-[31%] max-sm:w-full max-sm:items-start ">
              <h1 className="uppercase">ინფორმაცია</h1>
              <div className="w-full flex flex-col max-sm:items-start  gap-3 ">
                <Link to="/">
                  <p>მთავარი</p>
                </Link>
                <Link to="/mainshop">
                  <p>პროდუქტი</p>
                </Link>
                <Link to="/blog">
                  <p>ბლოგი</p>
                </Link>
                <Link to="/aboutus">
                  <p>ჩვენს შესახებ</p>
                </Link>
                <Link to="/contactPage">
                  <p>კონტაქტი</p>
                </Link>
              </div>
            </div>
            <div className=" flex flex-col gap-7 text-white w-[23%] max-md:w-[31%] max-sm:w-full max-sm:items-start ">
              <h1 className="uppercase ">დამატებითი ბმულები</h1>
              <div className="w-full flex flex-col max-sm:items-start  gap-3 ">
                <p>კონფიდენციალურობის პოლიტიკა</p>
                <p>წესები და პირობები</p>
                <p>დოკუმენტაცია</p>
                <p>საგადახდო სისტემები</p>
                <p>მიწოდების პოლიტიკა</p>
              </div>
            </div>
            <div className=" flex flex-col gap-7 w-[23%]  max-md:w-[31%]  max-sm:w-full max-sm:items-start  justify-start text-white">
              <h1 className="uppercase ">კონტაქტები</h1>
              <div className="w-full flex flex-col max-sm:items-start   gap-3 ">
                <p>
                  ტელეფონი: <a>2 11 11 90</a>
                </p>
                <p>
                  ელ - ფოსტა: <a>Sales@Bmsoft.dev</a>
                </p>
                <p className="text-start">
                  მისამართი: <a className="">წერეთლის გამზირი #116, თბილისი</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-[1px] border-white w-full"></div>
      <div
        className={` w-[100%] h-[100%] ${
          location.pathname === "/mainshop" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/yvela" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/sachuqrebi" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/khachapuri" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/soko-kecze" ||
          location.pathname === "/mainshop/mza-kerdzebi/everything" ||
          location.pathname === "/mainshop/mza-kerdzebi/ravii" ||
          location.pathname === "/mainshop/mza-kerdzebi/supi-souzi" ||
          location.pathname === "/mainshop/sasachuqre-baratebi/fasdaklebit" ||
          location.pathname === "/cart" ||
          location.pathname === "/profile" ||
          location.pathname === "/profile/profile-orders" ||
          location.pathname === "/profile/profile-address" ||
          location.pathname === "/profile/profile-payment" ||
          location.pathname === "/profile/profile-promocodes" ||
          location.pathname === "/profile/profile-profwind" ||
          location.pathname === "/profile/profile-profhelp" ||
          location.pathname === "/favoriteProd"
            ? "max-w-[2100px]"
            : "max-w-[1526px]"
        } `}
      >
        <div
          className={` w-full flex py-3 justify-between  items-center max-sm:items-start max-md:flex-wrap max-md:justify-evenly max-md:gap-y-[10px] ${
            location.pathname === "/mainshop" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/yvela" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/sachuqrebi" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/khachapuri" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/soko-kecze" ||
            location.pathname === "/mainshop/mza-kerdzebi/everything" ||
            location.pathname === "/mainshop/mza-kerdzebi/ravii" ||
            location.pathname === "/mainshop/mza-kerdzebi/supi-souzi" ||
            location.pathname === "/mainshop/sasachuqre-baratebi/fasdaklebit" ||
            location.pathname === "/cart" ||
            location.pathname === "/profile" ||
            location.pathname === "/profile/profile-orders" ||
            location.pathname === "/profile/profile-address" ||
            location.pathname === "/profile/profile-payment" ||
            location.pathname === "/profile/profile-promocodes" ||
            location.pathname === "/profile/profile-profwind" ||
            location.pathname === "/profile/profile-profhelp" ||
            location.pathname === "/favoriteProd"
              ? "footer-shop  px-[20px]"
              : "footer  px-[110px]"
          } `}
        >
          <div className="flex items-center gap-[8px]">
            <h1 className="text-white flex items-center hover:text-gray-500 cursor-pointer max-sm:items-start ">
              <At className="text-[20px]" /> 2023, SUPPORTED BY
            </h1>
            <div className="h-[25px]">
              <img src={logoo} alt="" className="w-full h-full" />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-[15px] h-[15px] text-white hover:text-gray-500 cursor-pointer">
              <BsInstagram className="w-full h-full " />
            </div>
            <div className="w-[15px] h-[15px] text-white hover:text-gray-500 cursor-pointer">
              <ImFacebook className="w-full h-full " />
            </div>
            <div className="w-[15px] h-[15px] text-white hover:text-gray-500 cursor-pointer">
              <FaTiktok className="w-full h-full " />
            </div>
            <div className="w-[15px] h-[15px] text-white hover:text-gray-500 cursor-pointer">
              <BsTwitter className="w-full h-full " />
            </div>
            <div className="w-[15px] h-[15px] text-white hover:text-gray-500 cursor-pointer">
              <BsYoutube className="w-full h-full " />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="h-[30px] w-[40px] border border-black bg-[white]  flex items-center justify-center rounded-md">
              <GrAmex className="w-[80%] h-[80%] " />
            </div>
            <div className="h-[30px]  w-[40px] bg-white border border-black flex items-center justify-center rounded-md">
              <FaApplePay className="w-[80%] h-[80%] text-black" />
            </div>
            <div className="h-[30px]  w-[40px] bg-white flex items-center justify-center rounded-md border border-black">
              <FaGooglePay className="w-[80%] h-[80%]" />
            </div>
            <div className="h-[30px] w-[40px] bg-white flex items-center justify-center rounded-md border border-black">
              <SiAmazonpay className="w-[80%] h-[80%]" />
            </div>
            <div className="h-[30px]  w-[40px] bg-white flex items-center justify-center rounded-md border border-black">
              <RiVisaLine className="w-[80%] h-[80%] text-blue-950" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
