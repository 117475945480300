import { Envelope, Messenger, Telephone } from "react-bootstrap-icons"

function ProfileHelp(){
    return( 
        <div>
          <h1 className="help-title text-[22px] mb-[40px]">დახმარება</h1>
          <div className="help-contacts flex flex-col gap-y-[20px] justify-between">
            <div className="help-tel flex items-center p-[25px] cursor-pointer hover:shadow bg-white gap-[5px] w-[49%] rounded-[16px]">
              <div className="bg-gray-100 w-[40px] rounded-full h-[40px] flex items-center justify-center">
                <Telephone className="text-[#010125] text-[25px]" />
              </div>
              <div>
                <h1 className="text-[#010125]">ცხელი ხაზი</h1>
                <p>2 11 11 90</p>
              </div>
            </div>
            <div className="help-fosta flex items-center p-[25px] cursor-pointer hover:shadow bg-white gap-[5px] w-[49%] rounded-[16px]">
              <div className="bg-gray-100 w-[40px] rounded-full h-[40px] flex items-center justify-center">
                <Envelope className="text-[#010125] text-[25px]" />
              </div>
              <div>
                <h1>ელ - ფოსტა</h1>
                <p className="text-[#010125]">Sales@Bmsoft.dev</p>
              </div>
            </div>
            <div className="help-messenger flex items-center p-[25px] cursor-pointer hover:shadow bg-white gap-[5px] w-[49%] rounded-[16px]">
              <div className="bg-gray-100 w-[40px] rounded-full h-[40px] flex items-center justify-center">
                <Messenger className="text-[#010125] text-[25px]" />
              </div>
              <div>
                <h1>Facebook</h1>
                <p className="text-[#010125]">fb.com/BMSOFT</p>
              </div>
            </div>
          </div>
        </div>
    )
}

export default ProfileHelp