


function MiniDropDown({handleShow, setIsShown}){
    
    return(
        <div  className="shadow rounded-[16px] z-[1] flex flex-col items-center gap-y-[5px] border-[1px] absolute top-[45px] bg-[white] p-[10px] right-[-20px]">
            <p className="w-[170px]" onClick={handleShow()}>ძირითადად მონიშვნა</p>
            <p className="text-[red]" onClick={handleShow()}>წაშლა</p>
        </div>
    )
}

export default MiniDropDown