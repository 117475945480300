import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import HoverComponent1 from './hoverComponents/HoverComponent1'
import { useRef } from 'react'

const HoverFullStyle = ({subCategory}) => {
  const [newRender,setNewRender] = useState(1)
  const ref= useRef()
  return (
    <div
      onMouseOut={() => setNewRender((prev) => prev+1)}
      ref={ref} 
      className='absolute hidden overflow-hidden group-hover:overflow-visible 
      w-[1018px] group-hover:border-r-2 group-hover:rounded-r-[15px] h-[calc(100%-48px)] 
      bg-white top-[50px] left-[95%] z-[10] group-hover:flex duration-300 py-[18px]  '>
        <div className='flex w-full h-full px-5'>
          {
            subCategory.map((item) => (
              <div
                key={item.id} 
                className="flex flex-col flex-1 overflow-hidden  gap-3 ">
                <h1 className='text-[15px] font-[500]'>{item.titleGeo}</h1>
                <div className="flex flex-col gap-1">
                  { item.subCategory &&
                    item.subCategory.map((item1,index) => (
                      <HoverComponent1 key={item1.id}  item1={item1} item={item} refuse={ref} newRender={newRender}/>
                    )
                    )}
                </div>
              </div>
            ))
          }
        </div>
      
    </div>
  )
}

export default HoverFullStyle