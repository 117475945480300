import { useState, useContext,useEffect } from "react";
import { Dash, PlusLg, Trash3 } from "react-bootstrap-icons";
import { PrimaryContext } from "../../allPages/MainContext";

function Counter({height, width, sale,setCounternum ,test1}) {
  const [count, setCount] = useState(1);
  const handleInc = () => {
    setCount((prev) => prev + 1);
  };
  const handleDec = () => {
    if (count !== 1) {
      setCount((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if(setCounternum){
      setCounternum(count)
    }
  },[count])
  useEffect(()=>{
    if(test1)
    {
      setCount(1)
    }
  },[test1])
  return (
    <div
      className={` items-center  p-[7px] flex  justify-between   rounded-[33px]   ${
        height ? "h-[50px] bg-[#010125a2] text-white" : "bg-[#010125a2] text-white "
    } ${width ? "w-[100%]  h-[30px] " : sale ? "w-[62%] h-[30px]  salecardcount " : "prodslcompcount h-[30px] w-[62%]"}`} 
    >
      <div
        onClick={() => {
          handleDec();
        }}
        className={`whenMoreTwo w-[38%] flex items-center justify-start ${
          count < 1
            ? `${count == 0 ? "flex" : "hidden"}`
            : "block text-white"
        }`}
      >
        <Dash className="text-[23px] cursor-pointer " />
      </div>

      <p className="num w-[20%] mt-[3px] text-[18px] h-[24px] items-center flex justify-center">
        {count}
      </p>

      <div
        onClick={() => {
          handleInc();
        }}
        className={`w-[33%] flex justify-end  
        ${
          count <= 1
            ? `${count == 0 ? "" : "text-white"}`
            : "text-white"
        }`}
      >
        <PlusLg className="text-[23px] font-semibold  cursor-pointer" />
      </div>
    </div>
  );
}

export default Counter;
