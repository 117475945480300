import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function BlockBlog({ id,blogimg, blogtitle, blogtext, blogtime }) {
  return (
    <Link to={`/allBlogs/${id}`}>
      <div className="eachblogmain flex gap-[30px] relative  mainBg rounded-[20px] group">
        <div className="relative blobkspht w-[100%] h-[250px] cursor-pointer rounded-[20px] ">
          <div className="w-[100%] h-[100%] bg-[#00000063] absolute rounded-[20px]"></div>
          <img
            src={blogimg}
            className="bloksphtin w-[100%] h-[100%] object-cover rounded-[20px]"
            alt=""
          />
        </div>
        <div className="padblock absolute flex flex-col justify-between w-[100%] text-white px-[10px] pt-[10px] pb-[15px] bottom-0 group-hover:bottom-[10px] duration-200">
          <p className="blogtittle blogttl  text-[16px] cursor-pointer">
            {blogtitle}
          </p>
        </div>
      </div>
    </Link>
  );
}
export default BlockBlog;
