import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import SubSubCategory from "./SubSubCategory";
import { Link } from "react-router-dom";
import { useContext } from "react";
import TestingContext from "../../contexts/TestingContext";

const SubCategory = ({ item1,setIsShowHeaderHover}) => {
  const [chearClick, setChearClick] = useState(false);
  const handelClick = (item1) => {
    if (item1.subCategory) {
      setChearClick((prev) => !prev);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-1">
        <div
          onClick={() => handelClick(item1)}
          key={item1.id}
          className="flex items-center gap-1"
        >
          <Link
            onClick={() => setIsShowHeaderHover((prev) => !prev)}
            to={`/products/${item1.parent.textLink}/${item1.title}`}>
            <p className="text-[14px]">{item1.titleGeo}</p>
          </Link>
          {item1.subCategory && (chearClick ? <ChevronUp /> : <ChevronDown />)}
        </div>
        {chearClick &&
          item1.subCategory.map((item2) => (
            <SubSubCategory key={item2.id} item2={item2} item1={item1} setIsShowHeaderHover={setIsShowHeaderHover} />
          ))}
      </div>
    </>
  );
};

export default SubCategory;
