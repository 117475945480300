import { useState } from "react";
import { useEffect, useRef } from "react";
import { Search, XLg } from "react-bootstrap-icons";

export default function PopUpSearch({ setIsShown, handleClick }) {
  const srchRef = useRef(null);
  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  function handleClickOutside(event) {
    if (srchRef.current && !srchRef.current.contains(event.target)) {
      // click occurred outside of wrapper, close the div here
      setIsShown(false);
    }
  }

  const [data, setData] = useState([
    {
      id: 1,
      text: "მაღაზია",
    },
    {
      id: 2,
      text: "კონტაქტი",
    },
    {
      id: 3,
      text: "კალათა",
    },
  ]);
  return (
    <div className="bg-[#000000bf] fixed top-0 left-0 w-[100%] h-[100%] z-10 flex items-center justify-center">
      <div
        ref={srchRef}
        className="searchPopup w-[600px] bg-gray-100  pb-[85px]  flex flex-col gap-[30px] rounded-[10px]"
      >
        <div className="adres-title flex items-center px-[50px] justify-end py-[10px] border-b-2 border-gray-200">
          <div
            className="w-[35px] h-[35px] rounded-full flex items-center cursor-pointer bg-gray-200 justify-center"
            onClick={() => {
              handleClick();
            }}
          >
            <XLg />
          </div>
        </div>
        <div className="searchpopupcont px-[50px] gap-y-[30px] flex flex-col">
          <h1 className="searshTitle text-center text-[30px] ">რას ეძებ?</h1>
          <div className="flex gap-[10px] items-center  border-b-[#010125] border-b-[2px] mb-[15px]">
            <input
              type="text"
              className="w-full h-[50px] outline-none bg-gray-100 rounded-[35px] ml-[5px] px-[15px]"
              placeholder="ძიება სახელით, ტიპით, ა.შ"
            />
            <div className="flex w-[60px] h-[45px] rounded-full items-center justify-center cursor-pointer">
              <Search />
            </div>
          </div>
          <div className="flex gap-[10px] flex-wrap justify-center items-center text-sm">
            <p>სწრაფი ძიება:</p>

            {data.map((item) => (
              <p
                className="text-[#010125] hover:bg-gray-300 cursor-pointer
                bg-gray-200 rounded-[33px] px-2 py-1 duration-200 text-sm "
              >
                {item.text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
