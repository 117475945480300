import { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckAnounsment from "../checkoutpage/checkAnounsment";
import easyway from "../../../imgs/easyway.svg";
import glovo from "../../../imgs/78.webp";
import wolt from "../../../imgs/80.webp";
import CartData from "../../contexts/CartData";
import ActiveMoney from "../../contexts/activeMoney";
import { PrimaryContext } from "../../allPages/MainContext";

function CalculatorCart() {
  const location = useLocation();
  const navigate = useNavigate();
  const activeMoney = useContext(ActiveMoney);
  const [allPrice, setAllPrice] = useState(0);
  const [deliveryCompanysData, setDeliveryCompanysData] = useState([
    {
      id: 1,
      img: easyway,
      priceDelivery: 5
    },
    {
      id: 2,
      img: glovo,
      priceDelivery: 6
    },
    {
      id: 3,
      img: wolt,
      priceDelivery: 7
    },
  ])
  const [newAllPrice, setNewAllPrice] = useState();
  const ref = useRef(1);
  const newCartData = useContext(CartData);
  const [Checked, setChecked] = useState("geo");

  const [pickdelivery, setPickdelivery] = useState(deliveryCompanysData[0]);

  const [lessMoneyErroe, setLessMoneyError] = useState(false);

  const handleLessMoneyError = () => {
    if (location.pathname === "/checkout") {
      setLessMoneyError((prev) => !prev);
    } else {
      navigate("/checkout");
    }
  };
  useEffect(() => {
    setAllPrice(0);
    newCartData.cartData.map((item) => {
      if (item.sale) {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice(
            (prev) =>
              prev +
              (item.price - (item.price / 100) * item.sale) * item.counter
          );
        } else {
          return setAllPrice(
            (prev) =>
              prev +
              (item.dolPrice - (item.dolPrice / 100) * item.sale) * item.counter
          );
        }
      } else {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice((prev) => prev + item.price * item.counter);
        } else {
          return setAllPrice((prev) => prev + item.dolPrice * item.counter);
        }
      }
    });
  }, [newCartData]);

  return (
    <>
      <div className="cart-calculator box-shadow-style min-w-[285px] w-[30%] bg-[white] sticky top-[110px] self-start rounded-[15px]  my-[20px] flex flex-col justify-between">
        <div className="p-[20px] flex flex-col gap-y-[20px]">
          <div className="flex items-center justify-between border-b-2 border-[#efefef] pb-[20px]">
            <p className="text-[17px] font-semibold">ჯამი:</p>
            <p className=" text-[20px]">
              {allPrice} {activeMoney.activeMoney === "lari" ? "₾" : "$"}
            </p>
          </div>
          <div className="flex flex-col gap-y-[15px] border-b-2 border-[#efefef] pb-[20px]">
            <p className="font-semibold">მიწოდება</p>
            <div className="flex flex-col gap-y-[5px]">
              <div className="flex justify-between">
                <div
                  className="flex gap-[10px] items-center"
                  onClick={() => {
                    setChecked("geo");
                  }}
                >
                  <div
                    className={`w-[10px] h-[10px] rounded-full ${
                      Checked === "geo"
                        ? "bg-[#010125]"
                        : "bg-white border-[1px] border-[#010125]"
                    }`}
                  ></div>
                  <p>საქართველო:</p>
                </div>
              </div>
              <div className={` ${Checked === "geo" ? "flex" : "hidden"}`}>
                <div className="border-[1px] border-[#010125] flex flex-col items-center gap-y-[3px] bg-gray-100 py-[10px] px-[12px] rounded-[8px]">
                  <div className="w-[40px] h-[30px]">
                    <img
                      src={easyway}
                      className="w-full h-full object-contain"
                      alt=""
                    />
                  </div>
                  <p>5{activeMoney.activeMoney === "lari" ? "₾" : "$"}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[5px]">
              <div className="flex justify-between">
                <div
                  className="flex gap-[10px] items-center"
                  onClick={() => {
                    setChecked("tbilisi");
                  }}
                >
                  <div
                    className={`w-[10px] h-[10px] rounded-full ${
                      Checked === "tbilisi"
                        ? "bg-[#010125]"
                        : " bg-white border-[1px] border-[#010125]"
                    }`}
                  ></div>
                  <p>თბილისი:</p>
                </div>
                <p className=""></p>
              </div>
              <div
                className={` gap-[5px] overflow-x-auto ${
                  Checked === "tbilisi" ? "flex" : "hidden"
                }`}
              >
                {deliveryCompanysData.map((item) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => setPickdelivery(item)}
                      className={`border-[#010125] ${
                        pickdelivery && (pickdelivery.id === item.id )&& "border-[1px]"
                      } flex flex-col items-center gap-y-[3px] bg-gray-100 py-[10px] px-[12px] rounded-[8px]`}
                    >
                      <div className="w-[40px] h-[30px]">
                        <img
                          src={item.img}
                          className="w-full h-full object-contain"
                          alt="#"
                        />
                      </div>
                      <p>
                        {item.priceDelivery}
                        {activeMoney.activeMoney === "lari" ? "₾" : "$"}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className=" flex justify-between pb-[20px] border-b-2 border-[#efefef]">
            <p className=" font-semibold">პრომო კოდი -20%</p>
            <p>
              {(allPrice / 100) * 20}
              {activeMoney.activeMoney === "lari" ? "₾" : "$"}
            </p>
          </div>
          <div className="flex justify-between pb-[20px] border-b-2 border-[#efefef]">
              <p className="font-semibold">მიწოდება</p>
              <p>{ Checked === 'tbilisi' ? pickdelivery.priceDelivery : 5} {activeMoney.activeMoney === "lari" ? "₾" : "$"}{" "}</p>
            </div>
          
          <div className="flex justify-between items-center pb-[20px] border-b-2 border-[#efefef]">
            <p className="text-[16px]  font-semibold">მთლიანი ჯამი:</p>
            <p className="text-[20px]">
               {(allPrice - (allPrice / 100) * 20)+(Checked === 'tbilisi' ? pickdelivery.priceDelivery : 5)}
              {activeMoney.activeMoney === "lari" ? "₾" : "$"}{" "}
            </p>
          </div>
        </div>

        <div className="cart-calc-btn px-[20px] pb-[20px] flex flex-col gap-[30px]">
          <div className="flex  justify-between items-center rounded-[10px] h-[45px] px-[5px]">
            <input
              type="text"
              placeholder="პრომო კოდი"
              className=" outline-none border-b-[1px] h-full w-[85%] px-[5px]"
            />
            <div className="w-[150px] h-full bg-[#010125] text-white flex justify-center items-center rounded-[30px] cursor-pointer text-[14px]">
              <h1>შენახვა</h1>
            </div>
          </div>
          <div className="w-full flex justify-center ">
            <h1
              className={`text-white flex items-center h-[45px] rounded-[33px] justify-center text-[14px] w-[250px] ${newCartData.cartData.length === 0 ? "pointer-events-none bg-[#01012559]" : "cursor-pointer bg-[#010125]"} `}
              onClick={handleLessMoneyError}
            >
              {location.pathname === "/checkout"
                ? "შეკვეთის გაფორმება"
                : "შეკვეთის გაგრძელება"}
            </h1>
          </div>
        </div>
      </div>
      {lessMoneyErroe && (
        <CheckAnounsment handleLessMoneyError={handleLessMoneyError} />
      )}
    </>
  );
}

export default CalculatorCart;
