import { XLg, PlusLg } from "react-bootstrap-icons";
import AddAddress from "./addAddress";

function Address({handleShowLocDesk, handleShowMoreAdresDesk}) {
  return (
    <div className="bg-[#000000bf] w-[100vw] h-[100vh] z-[99] fixed top-0 left-0 items-center flex justify-center">
      <div className="w-[100%] h-[100%] absolute"
      onClick={handleShowLocDesk}>

      </div>
      <div className="adres-div w-[50%] z-[99] bg-white rounded-[18px]">
        <div className="adres-title flex items-center justify-between px-[30px] py-[10px] border-b-2 border-gray-200">
          <h1>მისამართი</h1>
          <div className="w-[35px] h-[35px] rounded-full flex items-center cursor-pointer bg-gray-100 justify-center"
          onClick={() => {handleShowLocDesk();}}>
            <XLg />
          </div>
        </div>
        <div className="adresses p-[30px] ">
          <AddAddress/>
          <div className="hoverable-shadow flex items-center border-2 border-gray-100 p-[20px] rounded-[33px] gap-[20px] cursor-pointer "
          onClick={() => {handleShowLocDesk();
          handleShowMoreAdresDesk()}}>
            <div className="bg-[#f1f1f5] w-[50px] h-[50px] rounded-full items-center flex justify-center">
            <PlusLg className="mainColor text-[25px]"/>
            </div>
            <p className="mainColor">
              ახალი მისამართის დამატება</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
