import { useState, useEffect } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import Habits from "./prodHabits";
import Proddescr from "./proddescr";
import ProdScore from "./prodscore";
import ProdVideodescr from "./prodvideodescr";

function ProdBigInformation() {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState("habits");

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowSize[0] >= 1000) {
      setIsOpen(true);
    } else if (windowSize[0] <= 1000) {
      setIsOpen(false);
    }
  }, [windowSize]);
  return (
    <div className="big-info max-w-[1519px] w-[100%] px-[110px] margin-bottom-primary flex flex-col gap-10">
      <div className="prod-desc-side w-full overflow-x-scroll max-[920px]:pb-[6px]">
        <div
          className={`prodbiginfoscrolie w-full flex   gap-[20px] max-sm:gap-[10px] max-lg:gap-[10px] text-[14px] max-md:text-[12px] max-[920px]:w-[800px]  max-md:w-[660px]`}
        >
          <div
            className={`flex gap-[15px] cursor-pointer bg-white hover:bg-[#010125] hover:text-white duration-200 rounded-[30px]
            shadow-sm  items-center justify-center w-[210px] h-[50px] max-md:h-[40px] max-md:w-[170px]`}
            onClick={() => {
              setActive("habits");
            }}
          >
            <p
              className={`hab-title py-2  h-full rounded-[30px] justify-center flex w-full items-center ${
                active === "habits" && "bg-[#010125] text-white"
              }`}
            >
              მახასიათებლები
            </p>
          </div>

          <div
            className={`flex gap-[15px] cursor-pointer bg-white hover:bg-[#010125] hover:text-white duration-200 rounded-[30px]
            shadow-sm items-center justify-center w-[210px]  h-[50px]  max-md:h-[40px] max-md:w-[170px]`}
            onClick={() => {
              setActive("descr");
            }}
          >
            <p
              className={`hab-title py-2    h-full rounded-[30px] justify-center flex w-full items-center ${
                active === "descr" && "bg-[#010125] text-white"
              }`}
            >
              პროდუქტის აღწერა
            </p>
          </div>

          <div
            className={`flex gap-[15px] cursor-pointer bg-white hover:bg-[#010125] hover:text-white duration-200 rounded-[30px]
            shadow-sm  items-center justify-center  w-[210px] h-[50px]  max-md:h-[40px] max-md:w-[120px] `}
            onClick={() => {
              setActive("score");
            }}
          >
            <p
              className={`hab-title py-2  h-full rounded-[30px] justify-center flex w-full items-center ${
                active === "score" && "bg-[#010125] text-white"
              }`}
            >
              შეფასება
            </p>
          </div>

          <div
            className={`flex gap-[15px] cursor-pointer bg-white hover:bg-[#010125] hover:text-white duration-200 rounded-[30px]
            shadow-sm  items-center justify-center w-[210px]  h-[50px]  max-md:h-[40px] max-md:w-[170px]`}
            onClick={() => {
              setActive("videodescr");
            }}
          >
            <p
              className={`hab-title py-2   h-full rounded-[30px] justify-center flex w-full items-center ${
                active === "videodescr" && "bg-[#010125] text-white"
              }`}
            >
              ვიდეო განხილვა
            </p>
          </div>
        </div>
      </div>

      <div className="prod-information w-full">
        {active === "habits" && <Habits />}
        {active === "descr" && <Proddescr />}
        {active === "score" && <ProdScore />}
        {active === "videodescr" && <ProdVideodescr />}
      </div>
    </div>
  );
}

export default ProdBigInformation;
