import { Link, useLocation } from "react-router-dom";
import Us from "../../imgs/24.webp";

function AboutUs() {
  return (
    <div className=" w-[100%]  justify-center items-center flex flex-col gap-[30px] pb-[91px] max-sm:pb-[45px] bg-slate-100">
      <div
        className="w-full shadow-sm  border-b-[1px] flex
        border-slate-200 items-center justify-center bg-white mt-[90px] max-[540px]:mt-[70px]"
      >
        <div className="w-full flex gap-3 items-center max-w-[1519px] px-[110px] py-[10px] header">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/aboutus">
            <p className="text-[14px]">ჩვენს შესახებ</p>
          </Link>
        </div>
      </div>
      <div className="aboutus w-[100%] max-w-[1519px] px-[110px]  gap-y-[30px] flex flex-col justify-between">
        <div className="flex w-full gap-[30px] max-sm:gap-[15px] items-center max-sm:flex-col justify-between">
          <div className="w-[40%]  max-sm:w-full">
            <h1 className="text-[#010125] text-[32px] max-lg:text-[25px]">
              ჩვენ შესახებ
            </h1>
          </div>
          <div className="w-[50%]  max-sm:w-full">
            <p className="text-[18px] max-lg:text-[17px]  max-sm:text-[15px]">
              შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და
              ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად
              მიახლოებული შაბლონი წარუდგინონ შემფასებელს. შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და
              ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად
              მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა,
              როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება
              ტექსტის ბლოკი.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-[700px] py-[80px] max-sm:py-[40px] max-sm:h-[400px]">
        <img src={Us} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="aboutus w-[100%] max-w-[1519px] px-[110px]  gap-y-[30px] flex flex-col justify-between">
        <div className="flex w-full border-t-[2px] gap-[30px]  max-sm:gap-[15px] pt-[50px] max-sm:pt-[30px] max-sm:flex-col">
          <div className="w-[50%] max-sm:w-full">
            <h1 className="text-[#010125] text-[32px] max-lg:text-[25px] max-sm:text-[18px]">
              შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს
            </h1>
          </div>
          <div className="w-[50%] flex flex-col gap-y-[20px] max-sm:gap-[10px] text-[18px] max-lg:text-[17px] max-sm:w-full  max-sm:text-[15px]">
            <p className="text-[#010125] ">
              შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და
              ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად
              მიახლოებული შაბლონი წარუდგინონ შემფასებელს.
            </p>
            <p className="text-gray-600">
              შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და
              ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად
              მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა,
              როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება
              ტექსტის ბლოკი.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
