import { ArrowRight, Basket } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import {useEffect,useContext} from 'react'
import CartData from '../../contexts/CartData'


export default function ProfNoOrder({setShow}){
  const newCartData = useContext(CartData)
  useEffect(()=>{
    if(newCartData.cartData.length > 0){
      setShow(true)
    }
    else(
      setShow(false)

    )
  },[newCartData])
    return(
        <div className="profile-orders-cont flex justify-between p-[30px] items-center bg-white rounded-[20px] hover:shadow">
          <div className="flex items-center gap-[10px] text-gray-500">
            <div className="prof-basket-icn w-[40px] h-[40px] rounded-full flex border-[1px] border-gray-400 justify-center items-center">
              <Basket />
            </div>
            <p className="prof-ord-txt">თქვენ არ გაქვთ შეკვეთები</p>
          </div>
          <Link className="order-start-prof-link" to="/">
            <div className="prof-btn-text flex items-center gap-[10px] bg-gray-100 border-[1px] text-[#010125] cursor-pointer border-[#010125] py-[8px] px-[14px] rounded-[20px] ">
              <h1 className="prof-ord-txt text-[14px]">შეკვეთის დაწყება</h1>
              <ArrowRight/>
            </div>
          </Link>
        </div>
    )
}