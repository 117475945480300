
import MapPart from "../content/map/mapPart"
import ExpAdvert from "../content/experience-advert/exper-adv"
import SliderProd from "../content/slider/slider-Prod"
import PartniorShopList from "../content/partnior-shops-part/partniorShopList"
import ProdMenuCardsList from "../content/prod-menu-cards/prodMenuCardsList"
import SaleSlider from "../content/slider/sale-slider"
import Slider3 from "../content/slider/slider3component"
import MainTopSliderVariations from "../content/mainTopSliderVariations/MainTopSliderVariations"

function Main(){
    return(
        <div className="bg-[#f4f4f4]">
        <MainTopSliderVariations/>
        <ProdMenuCardsList />
        <PartniorShopList />
        <SliderProd />
        <SaleSlider/>
        <Slider3/>
        <ExpAdvert />
        <MapPart />
        </div>
    )
}

export default Main