import { Briefcase, Flag, House, XLg } from "react-bootstrap-icons";
import { useState } from "react";

function AddMoreAdress({
  showMoreAdresDesk,
  handleShowLocDesk,
  handleShowMoreAdresDesk,
}) {
  const [activeLoc, setActiveLoc] = useState("house");

  return (
    <div className="addmiss fixed top-0 py-[20px] left-0 z-[99] w-[100vw] h-[100vh] bg-[#000000bf] items-center flex flex-col">
      <div className="w-[100%] h-[100%] absolute"
      onClick={() => {
        handleShowMoreAdresDesk();
      }}>

      </div>
      <div className="add-more-adrs z-[99] w-[50%] max-xl:w-[70%] max-lg:w-[85%] h-[100%] bg-white rounded-[20px]  overflow-y-scroll">
        <div className="addmoreadrse bg-white px-[25px] w-[100%] h-[80px] border-b-[2px] flex items-center justify-between">
          <h1 className="text-[19px]">მისამართის დამატება</h1>
          <div
            className="w-[40px] h-[40px] rounded-[33px] border flex items-center justify-center cursor-pointer bg-slate-100"
            onClick={() => {
              handleShowLocDesk();
              handleShowMoreAdresDesk();
            }}
          >
            <XLg className="text-[22px] text-black" />
          </div>
        </div>
        <div className="admoreadrscont bg-white w-[100%] p-[25px] max-sm:pb-20 flex flex-col gap-y-[20px]">
          <h1>მისამართის ტიპი</h1>
          <div className="border w-[100%] flex rounded-[20px] mb-[15px] cursor-pointer">
            <div
              className="hom w-[33%] max-sm:flex-col flex items-center justify-center border-r p-[20px] max-md:p-[15px] max-sm:p-[12px]  gap-[10px]"
              onClick={() => {
                setActiveLoc("house");
              }}
            >
              <div
                className={`w-[60px] h-[60px] max-md:w-[45px] max-md:h-[45px] rounded-full flex items-center justify-center ${
                  activeLoc === "house"
                    ? "mainBg text-white"
                    : "bg-[#f1f1f5] text-[#92929d]"
                }`}
              >
                <House className="text-[22px]" />
              </div>
              <p className="mainColor">სახლი</p>
            </div>

            <div
              className="nothom w-[33%] flex max-sm:flex-col items-center justify-center border-r p-[20px] max-md:p-[15px] max-sm:p-[12px]    gap-[10px]"
              onClick={() => {
                setActiveLoc("work");
              }}
            >
              <div
                className={`w-[60px] h-[60px] max-md:w-[45px] max-md:h-[45px] rounded-full  flex items-center justify-center ${
                  activeLoc === "work"
                    ? "mainBg text-white"
                    : "bg-[#f1f1f5] text-[#92929d]"
                }`}
              >
                <Briefcase className="text-[22px] " />
              </div>
              <p className="mainColor">სამსახური</p>
            </div>

            <div
              className="hom w-[33%] flex max-sm:flex-col   items-center justify-center p-[20px] max-md:p-[15px] max-sm:p-[12px] gap-[10px]   "
              onClick={() => {
                setActiveLoc("sxva");
              }}
            >
              <div
                className={`w-[60px] h-[60px] max-md:w-[45px] max-md:h-[45px]  rounded-full flex items-center justify-center ${
                  activeLoc === "sxva"
                    ? "mainBg text-white"
                    : "bg-[#f1f1f5] text-[#92929d]"
                }`}
              >
                <Flag className="text-[22px]" />
              </div>
              <p className="mainColor">სხვა</p>
            </div>
          </div>
          <p className="text-[17px]">შეიყვანეთ მისამართი</p>

          <fieldset className="profile-fieldset border-[1px] px-[10px] rounded-[33px]">
            <input
              type="text"
              placeholder="ჩაწერე ქუჩა და აირჩიე ჩამოთვლილი ვარიანტებიდან"
              className="mx-[15px] mt-[5px] mb-[16px]  w-[97%] max-sm:w-[92%] text-[17px] outline-none"
            />
            <legend className="ml-[15px] px-[5px] text-[15px]">
              ქუჩა და ნომერი
            </legend>
          </fieldset>
          <fieldset className="profile-fieldset border-[1px] px-[10px] rounded-[33px]">
            <input
              type="text"
              placeholder="მაგ: სადარბაზო 2, სართული 4, ბინა 17"
              className="mx-[15px] mt-[5px] mb-[16px] w-[97%] max-sm:w-[92%] text-[17px] outline-none"
            />
            <legend className="ml-[15px] px-[5px] text-[15px]">
              დამატებითი ინფორმაცია
            </legend>
          </fieldset>
          <div className="w-[100%] h-[250px] rounded-[16px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11875.22742360023!2d45.477690849999995!3d41.9185106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ska!2sge!4v1676545954815!5m2!1ska!2sge"
              className="w-[100%] h-[100%] rounded-[16px]"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div>
            <div className="flex gap-[10px] w-full float-right">
              <div
                className="btnonnn flex items-center justify-center bg-[#0e6dff1a] mainColor w-[220px] h-[50px] rounded-[33px] cursor-pointer"
                onClick={() => {
                  handleShowLocDesk();
                  handleShowMoreAdresDesk();
                }}
              >
                <h1>გაუქმება</h1>
              </div>
              <div className="btnonnn flex items-center justify-center mainBg text-white w-[220px] h-[50px] rounded-[33px] cursor-pointer">
                <h1>შენახვა</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMoreAdress;
