import { useState } from "react";
import MultiRangeSlider from "./multirangeslider";
import {
  ChevronDown, Filter, XLg,
} from "react-bootstrap-icons";

export default function DropedFilter({setShowFilter7}) {
  const [moreBrand, setMoreBrand] = useState(true);

  const handlemoreBrand = () => {
    setMoreBrand((pre) => !pre);
  };

  const [moreSize, setMoreSize] = useState(true);

  const handlemoreSize = () => {
    setMoreSize((pre) => !pre);
  };

  const [moreColor, setMoreColor] = useState(true);

  const handlemoreColor = () => {
    setMoreColor((pre) => !pre);
  };

  const [sizedata, setSizedata] = useState([
    {
      id: 1,
      size: "SM",
    },
    {
      id: 2,
      size: "M",
    },
    {
      id: 3,
      size: "LG",
    },
    {
      id: 4,
      size: "XL",
    },
    {
      id: 5,
      size: "2XL",
    },
    {
      id: 6,
      size: "165x100",
    },
    {
      id: 7,
      size: "205x105",
    },
    {
      id: 8,
      size: "26",
    },
    {
      id: 9,
      size: "28",
    },
    {
      id: 10,
      size: "30",
    },
    {
      id: 11,
      size: "32",
    },
  ]);

  const [branddata, setBranddata] = useState([
    {
      id: 1,
      brand: "apple",
    },
    {
      id: 2,
      brand: "Xiaomi",
    },
    {
      id: 3,
      brand: "Samsung",
    },
    {
      id: 4,
      brand: "Huawei",
    },
    {
      id: 5,
      brand: "Google",
    },
    {
      id: 6,
      brand: "Nokia",
    },
  ]);

  const [colordata, setColordata] = useState([
    {
      id: 1,
      color: "წითელი",
    },
    {
      id: 2,
      color: "ყვითელი",
    },
    {
      id: 3,
      color: "ლურჯი",
    },
    {
      id: 4,
      color: "მწვანე",
    },
    {
      id: 5,
      color: "ყავისფერი",
    },
    {
      id: 6,
      color: "ვარდისფერი",
    },
    {
      id: 7,
      color: "თეთრი",
    },
  ]);
  const changer = () => {
    setShowFilter7((prev) => !prev)
  }

  return (
    <div className="h-screen max-[1200px]:w-full  max-[1200px]:flex max-[1200px]:items-end  max-[1200px]:bottom-0  max-[1200px]:bg-[#000000bf]
    max-[1200px]:top-auto  top-0 z-[100] right-0 fixed">
      <div
        className=" max-[1200px]:relative pl-[15px] max-[1200px]:px-5 max-[1200px]:h-[80vh] pr-[7px] pt-[100px] max-[1200px]:pt-[20px]
          sticky flex-col pb-[15px] h-full max-[660px]:pb-[80px]  bg-white w-[100%] overflow-y-scroll max-[1200px]:border-t-[2px]  max-[1200px]:rounded-t-[30px] max-[1200px]:shadow-sm "
      >
        <div className='flex justify-between pb-3 mb-3 border-b-[2px] border-slate-200 items-center'>
          <div className='flex  '>
            <h1 className='shoplisttitle'>ფილტრი</h1>
          </div>
          <div
            onClick={changer}
            className='rounded-full w-[30px] h-[30px]  bg-slate-100 flex justify-center items-center'>
            <XLg className='text-black'/>
          </div>

        </div>
        <div className="price ">
          <p className="shoplisttitle">ფასი</p>
          <MultiRangeSlider
            min={0}
            max={1000}
          />
        </div>

        <div className="brands mb-[10px] flex flex-col gap-y-[8px]">
          <div
            className="flex justify-between items-center border-b-[1px] pb-[3px]"
            onClick={() => {
              handlemoreBrand();
            }}
          >
            <p className="brand-title shoplisttitle">ბრენდი</p>
            <ChevronDown
              className={`duration-200 ${moreBrand ? "rotate-180" : ""}`}
            />
          </div>
          <div
            className={`h-auto flex flex-col gap-y-[5px] ${
              moreBrand ? "flex flex-col" : "hidden checkBoxDiv"
            }`}
          >
            {branddata.map((item) => (
              <div className="flex gap-[5px]">
                <input type="checkbox" name="a"></input>
                <label htmlFor="a" className="text-[15px]">
                  {item.brand}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="price  mb-[10px] flex flex-col gap-y-[8px]">
          <div
            className="flex justify-between items-center border-b-[1px] pb-[3px]"
            onClick={() => {
              handlemoreSize();
            }}
          >
            <p className="brand-title shoplisttitle">ზომა</p>
            <ChevronDown
              className={`duration-200 ${moreSize ? "rotate-180" : ""}`}
            />
          </div>
          <div
            className={`h-auto flex flex-col gap-y-[5px] ${
              moreSize ? "flex flex-col" : "hidden"
            }`}
          >
            <div className="w-full flex flex-wrap gap-[5px]">
              {sizedata.map((item) => (
                <div className=" border-[1px] border-gray-200 px-[5px] py-[2px] rounded-[8px] text-[11px] cursor-pointer">
                  <p>{item.size}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-[8px]">
          <div
            className="flex justify-between items-center border-b-[1px] pb-[3px]"
            onClick={() => {
              handlemoreColor();
            }}
          >
            <p className="brand-title shoplisttitle">ფერი</p>
            <ChevronDown
              className={`duration-200 ${moreColor ? "rotate-180" : ""}`}
            />
          </div>
          <div
            className={`h-auto flex flex-col gap-y-[5px] ${
              moreColor ? "flex flex-col" : "hidden"
            }`}
          >
            {colordata.map((item) => (
              <div className="flex gap-[5px] ">
                <input type="checkbox" name="aa"></input>
                <label className="text-[15px]">{item.color}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='absolute bottom-0 w-full'>
        <div className="w-full h-auto flex  py-3  justify-between items-center px-5 bg-white border-t-[2px] border-t-slate-200">
          <div 
            onClick={changer}
            className=" py-[10px] 
              cursor-pointer flex justify-center items-center  text-[13px]"
            >
            <p className="text-[15px]">გასუფთავება</p>
          </div>
          <div className="bg-[#010125] rounded-full text-white py-3 px-5">
            <h1 className='text-[14px]'>გაფილტვრა</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
