import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const HoverComponent1 = ({item1,item,refuse,newRender}) => {

  const [showMore,setShowMore] = useState(false)
  useEffect(() => {
    if(refuse){
      const myStyle = window.getComputedStyle(refuse.current)
      if(myStyle.display  === 'none'){
        setShowMore(false)
      }
    }
  },[newRender])
    if(showMore){
      
        return(
          item.subCategory.slice(4).map((item3) => (
            <div
              key={item3.id} 
              className='text-[13px] flex flex-col'> 
              <Link to={`products/${item.parent.textLink}/${item.title}/${item3.title}`}>
                <p>{item3.titleGeo}</p>
              </Link>
              {item3.id === item.subCategory.length && 
              <p
                onClick={() => setShowMore((prev) => !prev)}
                className='text-[15px] mt-2'>
                  შემოკლება
              </p>}
            </div>
          ))
        )
      // })
    }
    else{
      if(item1.id < 6 && !showMore ){
        return(
          <div
            key={item1.id} 
            className='text-[13px]'> 
            <Link to={`products/${item.parent.textLink}/${item.title}/${item1.title}`}>
              <p>{item1.titleGeo}</p>
            </Link>
            {
              item1.id === 5 && item.subCategory.length > 5 && 
              <p
                className='mt-2 text-[15px]'
                onClick={() => setShowMore((prev) => !prev)}
              >
                მეტის ნახვა
              </p>
            }
          </div>
          
        )
      }
  }
}

export default HoverComponent1