import { useState } from "react"
import ProfNoOrder from "./profnoorder"
import ProfYesOrders from "./profyesorder"
function ProfileOrders(){
  const [show, setShow] = useState(false)
    return(
        <div className="w-full flex flex-col gap-y-[20px]">
          {show && (<ProfYesOrders setShow={setShow}/>)}
          {!show && (<ProfNoOrder setShow={setShow}/>)}
        </div>
    )
}

export default ProfileOrders