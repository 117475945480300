import personimg from "../../../imgs/23.webp";

function OneMessage({ name, lastname, text, date }) {
  return (
    <div className="flex gap-[10px] border-b-[1px] py-[10px]">
      <div className="messagepersonimg h-[40px] w-[40px]">
        <img
          src={personimg}
          className="w-[100%] h-[100%] rounded-full object-cover"
          alt=""
        />
      </div>
      <div className="flex flex-col gap-y-[3px] w-[75%]">
        <div className="comperson flex gap-[15px] items-center">
          <p>
            {name} {lastname}
          </p>
          <p className="text-[12px] text-gray-300px">{date}</p>
        </div>
        <div className="w-full">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default OneMessage;
