import { useContext } from "react";
import { Link } from "react-router-dom";
import CategoryData from "../../contexts/CategoryData";

function StartCategList({ handleShowMenu}) {
  const categoryData = useContext(CategoryData)
  return (
    <div className="kateglistmenu h-[100%] overflow-y-scroll flex-col">
      <ul className="gap-y-[10px] flex flex-col">
        {
          categoryData.categoryData.map((item) => (
            <Link to={`/products/${item.textLink}/${item.subCategory[0].title} `}>
              <li className="flex cursor-pointer group text-gray-600"
              onClick={handleShowMenu}
              >
                <p className="css-elipses headerli text-[14px] ">{item.text}</p>
              </li>
            </Link>
          ))
        } 
      </ul>
    </div>
  );
}

export default StartCategList;
