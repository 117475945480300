import GoogleMap from "../content/contactpagefolder/contactmap";
import ContactContent from "../content/contactpagefolder/contactContent";
import { Link } from "react-router-dom";

function ContactPage() {
  return (
    <div className="contactmain w-[100%] flex flex-col justify-center mt-[90px] bg-gray-100 pb-[70px]">
      <div
        className="w-full shadow-sm  border-b-[1px] flex
        border-slate-200 items-center justify-center bg-white "
      >
        <div className="w-full flex gap-3 items-center max-w-[1519px] px-[110px] py-[10px] header">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/contactPage">
            <p className="text-[14px]">კონტაქტი</p>
          </Link>
        </div>
      </div>
      <div className="contactbg  max-w-[1526px] w-[100%] px-[110px]  pt-[40px] gap-y-[50px] flex flex-col">
        <GoogleMap />
        <ContactContent />
      </div>
    </div>
  );
}

export default ContactPage;
