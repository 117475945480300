import React from 'react'
import { Link } from 'react-router-dom'
import Hover3 from './Hover3'
import { BiChevronRight } from 'react-icons/bi'

const Hover2 = ({subCategory}) => {
  return (
    <div className='absolute overflow-hidden group-hover:overflow-visible w-0 group-hover:border-r-2 group-hover:rounded-r-[15px] h-[calc(100%-48px)] bg-white top-[48px] left-[100%] z-[10] group-hover:w-full duration-300 py-[18px]'>
      <ul className='flex flex-col gap-[10px] px-5'>

      {
        subCategory.map((item) => (
          <li
            key={item.id}  
            className="group-two">
            <Link to={`/products/${item.parent.textLink}/${item.title}`} className='flex cursor-pointer group-one text-gray-600 '>
              <h1 className="css-elipses text-[13px]">{item.titleGeo}</h1>
              {
                item.subCategory &&
                <div>
                  <BiChevronRight className='text-[20px] '/> 
                  <Hover3 category={item.subCategory} parent1={item}/>
                </div>
              }
            </Link>
          </li>
        ))
      }
      </ul>
    </div>
  )
}

export default Hover2