import React from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import BorderRadiusContext from '../../../contexts/BorderRadiusContext'

const Hover3 = ({category,parent1}) => {
    const {borderRadius} = useContext(BorderRadiusContext)
  return (
    <div className='absolute w-0 group-two-hover:border-l-2 py-[18px]  rounded-r-[15px]  h-[70%] bg-white left-[100%] z-[10] -mt-5 group-two-hover:w-full duration-300' >
      <ul className='gap-[10px] flex flex-col px-4'>
        {category.map((item)=> (
          <li
            key={item.id} 
            className="">
            <Link to={`/products/${parent1.parent.textLink}/${parent1.title}/${item.title}`} className='flex cursor-pointer group-one text-gray-600 pr-[5px]'>
              <h1 className="css-elipses text-[13px]">{item.titleGeo}</h1>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Hover3