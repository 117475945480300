import {
    GeoAlt,
    Basket,
    List,
    CurrencyDollar,
    Heart,
    Search,
    Person,
  } from "react-bootstrap-icons";
  import Logo from "../../imgs/400.webp"
  import { useState, useContext, useEffect, useRef } from "react";
  import { Link, useLocation } from "react-router-dom";
  import useScreenSize from "../content/customHooks/useScreenSize";
  import AddMoreAdress from "../content/adress/addMoreAdresses";
  import AutorDone from "../content/autorization/autorDone";
  import SecAutorization from "../content/autorization/secondautorization";
  import PopUpSearch from "../content/dropdown-manu/searchPopPup";
  import CartData from "../contexts/CartData";
  import WishlistData from "../contexts/WishlistData";
  import Address from "../content/adress/Address";
  import ActiveMoney from "../contexts/activeMoney";
  import DropDownMenu from "../content/dropdown-manu/dropdownmenu";
import CategoryHoverFull from "./CategoryHoverFull";
  
  function Header3() {
    const flagRef = useRef(null);
    const moneyRef = useRef(null);
    const srchRef = useRef(null);
    const srchMain = useRef(null);
    const cartData = useContext(CartData);
    const activeMoney = useContext(ActiveMoney);
    const wishlistData = useContext(WishlistData);
  
    useEffect(() => {
      // add event listener to document when component mounts
      document.addEventListener("mousedown", handleClickOutside);
  
      // cleanup event listener when component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    function handleClickOutside(event) {
      if (flagRef.current && !flagRef.current.contains(event.target)) {
        // click occurred outside of wrapper, close the div here
        setFlag(false);
      }
      if (moneyRef.current && !moneyRef.current.contains(event.target)) {
        // click occurred outside of wrapper, close the div here
        setMoney(false);
      }
      if (srchRef.current && !srchRef.current.contains(event.target)) {
        // click occurred outside of wrapper, close the div here
        setIsShown(false);
      }
    }
  
    let location = useLocation();
  
    const [showAutorDesk, setShowAutorDesk] = useState(false);
  
    const [showLocDesk, setShowLocDesk] = useState(false);
  
    const [showMoreAdresDesk, setShowMoreAdresDesk] = useState(false);
  
    const [showMenu, setShowMenu] = useState(false);
  
    const [isShown, setIsShown] = useState(false);

  
    const [flag, setFlag] = useState(false);
  
    const handleFlag = () => {
      setFlag((prev) => !prev);
    };
  
    // const [activeMoney, setActiveMoney] = useState("lari");
  
    const [money, setMoney] = useState(false);
  
    const [done, setDone] = useState(false);
  
    const handleDone = () => {
      setDone((prev) => !prev);
    };
  
    const handleMoney = () => {
      setMoney((prev) => !prev);
    };
  
    const [active, setActive] = useState("GE");
  
    const handleShowAutorDesk = () => {
      setShowAutorDesk((prev) => !prev);
    };
  
    const handleShowLocDesk = () => {
      setShowLocDesk((prev) => !prev);
    };
  
    const handleShowMoreAdresDesk = () => {
      setShowMoreAdresDesk((prev) => !prev);
    };
  
    const handleShowMenu = () => {
      setShowMenu((prev) => !prev);
    };
  
    const handleClick = (event) => {
      setIsShown((current) => !current);
    };
  
  
    const [secAut, setSecaut] = useState(false);
  
    const handleSecaut = () => {
      setSecaut((prev) => !prev);
    };
  
    useEffect(() => {
      if (isShown) {
        document.body.classList.add("popup-open");
      } else {
        document.body.classList.remove("popup-open");
      }
    }, [isShown]);
  
    useEffect(() => {
      if (showMenu) {
        document.body.classList.add("popup-open");
      } else {
        document.body.classList.remove("popup-open");
      }
    }, [showMenu]);
  
    useEffect(() => {
      if (showLocDesk) {
        document.body.classList.add("popup-open");
      } else {
        document.body.classList.remove("popup-open");
      }
    }, [showLocDesk]);
  
    useEffect(() => {
      if (showMoreAdresDesk) {
        document.body.classList.add("popup-open");
      } else {
        document.body.classList.remove("popup-open");
      }
    }, [showMoreAdresDesk]);
  
    useEffect(() => {
      if (done) {
        document.body.classList.add("popup-open");
      } else {
        document.body.classList.remove("popup-open");
      }
    }, [done]);
  
    useEffect(() => {
      if (secAut) {
        document.body.classList.add("popup-open");
      } else {
        document.body.classList.remove("popup-open");
      }
    }, [secAut]);
  
    return (
      <div className="head w-[100vw] h-[90px]  flex justify-center z-[11] top-0 fixed bg-white border-b-[1px] ">
        <div
          className={`  h-[100%] flex  items-center justify-between relative ${
            location.pathname.split("/")[1]  === "products" ||
            location.pathname === "/cart" ||
            location.pathname.split("/")[1]  === "profile" ||
            location.pathname === "/favoriteProd"
              ? "header-shop px-[20px] w-[2100px]"
              : "header px-[110px] w-[1526px]"
          }`}
        >
          <div
            className={`header-left-all h-[100%] flex header-left w-[20%] items-center gap-[20px] `}
          >
            <div className="header-menu-logo flex h-[100%] items-center gap-[10px]">
              <div
                className={`header-menu-icon  h-[40px] rounded-full relative bg-white hidden items-center
                          justify-center cursor-pointer hover:bg-gray-100 active:bg-none `}
                onClick={() => {
                  handleShowMenu();
                }}
              >
                <List className="text-[#010125] text-[26px]" />
              </div>
              <Link to={"/"}>
                <div
                  className={`w-[130px] h-[55%] flex items-center`}
                >
                  <img
                    src={Logo}
                    className="w-[100%] h-[100%] object-contain"
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <div
              className={`header-flag-srch flex gap-[10px] h-[43px]`}
            >
              <div ref={flagRef} className="relative">
                <div
                  className={`languages-change w-[40px] h-[40px] bg-white p-[2px]
                      justify-center shadow-btn flex
                      cursor-pointer hover:bg-gray-100 ${
                        flag ? "rounded-t-[50%]" : "rounded-[50%]"
                      }`}
                  onClick={handleFlag}
                >
                  <p className="text-sm pt-1 w-[34px] h-[34px] flex items-center justify-center">
                    {active}
                  </p>
                </div>
                <div
                  className={`flex-col w-[40px] items-center bg-white gap-[2px] absolute top-[38px] 
                left-0 rounded-b-[33px] p-[4px] shadow-btn pt-[5px] gap-y-[8px] 
                ${flag ? "flex " : "hidden"}`}
                >
                  <div
                    className={`w-[34px] h-[34px] rounded-full  p-[1px] items-center 
                      justify-center shadow-btn flex cursor-pointer hover:bg-gray-100
                      ${active === "GE" ? "bg-gray-100" : "bg-white"}`}
                    onClick={() => {
                      setActive("GE");
                      handleFlag();
                    }}
                  >
                    <p className="text-sm pt-1">GE</p>
                  </div>
  
                  <div
                    className={`w-[34px] h-[34px] rounded-full p-[1px] items-center 
                      justify-center shadow-btn flex cursor-pointer hover:bg-gray-100
                      ${active === "EN" ? "bg-gray-100" : "bg-white"}`}
                    onClick={() => {
                      setActive("EN");
                      handleFlag();
                    }}
                  >
                    <p className="text-sm pt-1">EN</p>
                  </div>
                </div>
              </div>
              <div
                className={`w-[40px] h-[40px] bg-white flex p-[2px] items-center 
                      justify-center shadow-btn
                      cursor-pointer rounded-full mainColor`}
                onClick={handleClick}
              >
                <Search />
              </div>
            </div>
          </div>
          <div
            className={`menu-list w-[46%] justify-center flex items-center h-[100%]`}
          >
            <ul className=" flex gap-[18px]  items-center h-[100%] text-[14px]">
              <Link to={"/"}>
                <li className="headerli text-[#010125] group hover:text-gray-400 cursor-pointer duration-200">
                  მთავარი
                </li>
              </Link>
              <Link to={"/"} className="group h-full flex items-center">
                <li className="headerli text-[#010125] hover:text-gray-400 cursor-pointer duration-200">
                  კატეგორიები
                </li>
                <CategoryHoverFull/>
              </Link>
              <Link to={"blog/allBlogs"}>
                <li className="headerli text-[#010125] hover:text-gray-400 cursor-pointer duration-200">
                  ბლოგი
                </li>
              </Link>
              <Link to={"aboutus"}>
                <li className="headerli text-[#010125] hover:text-gray-400 cursor-pointer duration-200">
                  ჩვენ შესახებ
                </li>
              </Link>
              <Link to={"ContactPage"}>
                <li className="headerli text-[#010125] hover:text-gray-400 cursor-pointer duration-200">
                  კონტაქტი
                </li>
              </Link>
            </ul>
          </div>
          <div
            className={`headergap flex gap-[10px] justify-end h-[100%] items-center header-right w-[26%]`}
          >
            {/* <Link to="/profile"> */}
            <div
              className="profile-btn h-[40px] w-[40px] rounded-full  px-[10px] flex items-center 
                      justify-center shadow-btn
                      cursor-pointer mainBg"
              onClick={() => {
                handleSecaut();
              }}
            >
              <Person className="text-white text-[20px] cursor-pointer" />
            </div>
            {/* </Link> */}
  
            <div ref={moneyRef} className="relative headerRightSmth">
              <div
                className={`currency-change w-[40px] h-[40px] bg-white p-[4px] flex items-center 
                      justify-center shadow-btn 
                      cursor-pointer hover:bg-gray-100 duration-200 ${
                        money ? "rounded-t-[50%]" : "rounded-full"
                      }`}
                onClick={handleMoney}
              >
                {activeMoney.activeMoney === "dolari" && (
                  <CurrencyDollar className="text-[20px]" />
                )}
                {activeMoney.activeMoney === "lari" && (
                  <p className="text-[17px] pt-[2px] text-[#010125]">₾</p>
                )}
              </div>
              <div
                className={`flex-col w-[40px] items-center bg-white gap-[2px] absolute top-[38px] 
                left-0 rounded-b-full p-[4px] shadow-btn gap-y-[8px] pt-[5px] ${
                  money ? "flex " : "hidden"
                }`}
              >
                <div
                  className="w-[34px] h-[34px] rounded-full bg-white p-[4px] flex items-center 
                    justify-center shadow-btn 
                    cursor-pointer hover:bg-gray-100 duration-200"
                  onClick={() => {
                    activeMoney.setActiveMoney("dolari");
                    handleMoney();
                  }}
                >
                  <CurrencyDollar className="text-[18px]" />
                </div>
  
                <div
                  className="w-[34px] h-[34px] rounded-full bg-white p-[4px] flex items-center 
                    justify-center shadow-btn
                    cursor-pointer hover:bg-gray-100 duration-200"
                  onClick={() => {
                    activeMoney.setActiveMoney("lari");
                    handleMoney();
                  }}
                >
                  <p className="text-[16px] pt-[4px]">₾</p>
                </div>
              </div>
            </div>
  
            <div
              className="location-icon w-[40px] h-[40px] rounded-full bg-white flex items-center 
                      justify-center shadow-btn 
                      cursor-pointer hover:bg-gray-100 duration-200 text-[#010125]"
              onClick={() => {
                handleShowLocDesk();
              }}
            >
              <GeoAlt />
            </div>
            <Link to="/favoriteProd">
              <div
                className="heart-icon w-[40px] h-[40px] relative rounded-full bg-white flex items-center 
                      justify-center shadow-btn 
                      cursor-pointer hover:bg-gray-100 duration-200 text-[#010125]"
              >
                <Heart />
                <div className="w-[20px] h-[20px] rounded-full top-[-5px] right-[-5px] absolute bg-[#010125] text-white flex items-center justify-center">
                  <p className="pt-[2px] text-[14px] ">
                    {wishlistData.wishlistData.length}
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"cart"}>
              <div
                className={`cart-in-header w-[40px] h-[40px] rounded-full relative bg-white flex items-center
                          justify-center shadow-btn cursor-pointer hover:bg-gray-100  duration-200`}
              >
                <Basket className="text-[#010125] text-[22px]" />
                <div className="w-[20px] h-[20px] rounded-full top-[-5px] right-[-5px] absolute bg-[#010125] text-white flex items-center justify-center">
                  <p className="pt-[2px] text-[14px] ">
                    {cartData.cartData.length}
                  </p>
                </div>
              </div>
            </Link>
            <div
              className={`respons-search w-[40px] h-[40px] bg-white hidden p-[2px] items-center 
                      justify-center shadow-btn
                      cursor-pointer rounded-full mainColor `}
              onClick={handleClick}
            >
              <Search />
            </div>
          </div>
        </div>
  
        {showMenu && (
          <DropDownMenu
            handleShowMenu={handleShowMenu}
            handleShowLocDesk={handleShowLocDesk}
            handleShowAutorDesk={handleShowAutorDesk}
            setShowMenu={setShowMenu}
          />
        )}
  
        {showLocDesk && (
          <Address
            handleShowLocDesk={handleShowLocDesk}
            handleShowMoreAdresDesk={handleShowMoreAdresDesk}
          />
        )}
  
        {showMoreAdresDesk && (
          <AddMoreAdress
            showMoreAdresDesk={showMoreAdresDesk}
            handleShowLocDesk={handleShowLocDesk}
            handleShowMoreAdresDesk={handleShowMoreAdresDesk}
          />
        )}
        {done && (
          <AutorDone done={done} setDone={setDone} handleDone={handleDone} />
        )}
  
        {secAut && (
          <SecAutorization handleSecaut={handleSecaut} handleDone={handleDone} />
        )}
        {isShown && (
          <PopUpSearch setIsShown={setIsShown} handleClick={handleClick} />
        )}
      </div>
    );
  }
  
  export default Header3;