import { Gift, XLg } from "react-bootstrap-icons";

function AddPromoCode({ handleShowAddPromo }) {
  return (
    <div className="w-[100vw] h-[100vh] z-[15] fixed top-0 left-0 bg-[#00000066] flex items-center justify-center">
      <div
        className="w-[100%] h-[100%] absolute"
        onClick={handleShowAddPromo}
      ></div>
      <div className="promopopup w-[450px] z-[99] bg-white rounded-[18px]">
        <div className="w-[100%] border-b-[2px] items-center px-[20px] py-[10px] flex justify-between">
          <h1 className="pt-[5px]">პრომო კოდის დამატება</h1>
          <div
            className="w-[40px] cursor-pointer h-[40px] flex items-center bg-slate-100 justify-center rounded-full shadow-btn text-gray-500"
            onClick={handleShowAddPromo}
          >
            <XLg className='text-black'/>
          </div>
        </div>
        <div className="prompopcont items-center flex flex-col gap-[20px] pt-[20px] px-[16px] pb-[50px]">
          <div className="bg-[#cdcdd2] w-[90px] h-[90px] mainColor text-[35px] rounded-full flex justify-center items-center">
            <Gift />
          </div>

          <div className="enterpromoc flex  border-[2px] items-center justify-center pl-[10px]  rounded-[33px] w-[300px]">
            <input
              type="text"
              className="outline-none text-[13px]  w-[100%] "
              placeholder="ჩაწერე კოდი"
            />
            <div
              className="savepromo rounded-[33px] border pt-[12px] pb-[10px] w-[120px] mainBg text-center text-white text-[13px] cursor-pointer"
              onClick={handleShowAddPromo}
            >
              <h1>შენახვა</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPromoCode;
