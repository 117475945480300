import {
  GeoAlt,
  Envelope,
  Facebook,
  Twitter,
  Youtube,
  Telephone,
  Instagram,
  Linkedin,
} from "react-bootstrap-icons";

function ContactContent() {
  return (
    <div className="contact-down-part flex gap-[40px]">
      <div className="send-message w-[50%] flex flex-col gap-y-[10px] bg-white p-[20px] rounded-[15px] shadow-sm">
        <h1 className="cont-main-title text-[22px]">შეტყობინების გაგზავნა</h1>
        <div className="h-[2px] rounded-full bg-gray-300">
          <div className="left mainBg w-[50%] h-[100%] rounded-full"></div>
        </div>
        <div className="name-surname flex gap-[30px] mt-[10px]">
          <div className="name-part w-[50%]">
            <input
              type="text"
              placeholder="სახელი"
              className="inputi-name w-[100%] border-b-[1px] px-[10px] py-[8px] outline-none"
            ></input>
          </div>
          <div className="surname-part w-[50%] flex flex-col gap-y-[5px]">
            <input
              type="text"
              placeholder="გვარი"
              className="inputi-surname w-[100%]  border-b-[1px] px-[10px] py-[8px] outline-none"
            ></input>
          </div>
        </div>
        <div className="e-mail-part flex flex-col gap-y-[5px]">
          <input
            type="text"
            placeholder="ელ-ფოსტა"
            className="inputi-email w-[100%] border-b-[1px] px-[10px] py-[8px] outline-none"
          ></input>
        </div>
        <div className="title-part flex flex-col gap-y-[5px]">
          <input
            type="text"
            placeholder="სათაური"
            className="inputi-title  w-[100%] border-b-[1px] px-[10px] py-[8px] outline-none"
          ></input>
        </div>
        <textarea
          placeholder="შეტყობინება..."
          className="textarea-part w-[100%] shadow-sm border-b-[1px] h-[150px] p-[10px] outline-none"
        ></textarea>
        <div className="mainBg w-[150px] flex items-center justify-center h-[40px] pt-[2px] mt-[10px] text-white cursor-pointer shadow-btn rounded-[20px]">
          <h4 className="send-button">გაგზავნა</h4>
        </div>
      </div>
      <div className="follow w-[50%] flex flex-col gap-y-[10px] bg-white p-[20px] rounded-[15px] shadow-sm">
        <div className="up-follow flex flex-col gap-y-[10px] border-b-[2px]">
          <h1 className="follow-title text-[22px]">გამოგვყევით</h1>
          <div className="h-[2px] rounded-full bg-gray-300">
            <div className="left mainBg w-[50%] h-[100%] rounded-full"></div>
          </div>
          <div className="contacts-info mt-[15px] mb-[30px] gap-[30px] flex flex-wrap ">
            <div className="adres flex gap-[10px]">
              <div className="icn w-[40px] h-[40px] mainBg text-white text-[20px] flex items-center justify-center  rounded-full">
                <GeoAlt className="i" />
              </div>
              <div className="">
                <p className=" text-[17px]	">მისამართი</p>
                <p className=" text-[13px]">წერეთლის გამზირი #116, თბილისი</p>
              </div>
            </div>
            <div className="mobile flex gap-[10px]">
              <div className="icn w-[40px] h-[40px] mainBg text-white text-[20px] flex items-center justify-center  rounded-full">
                <Telephone className="i" />
              </div>
              <div className="mobile-text">
                <p className="mobile-title text-[17px]">მობილური</p>
                <p className="adresi text-[13px]">2 11 11 90</p>
              </div>
            </div>
            <div className="e-mail flex gap-[10px]">
              <div className="icn w-[40px] h-[40px] mainBg text-white text-[20px] flex items-center justify-center  rounded-full">
                <Envelope className="i" />
              </div>
              <div className="e-mail-text">
                <p className="e-mail-title text-[17px]">ელ.ფოსტა</p>
                <p className="adresi text-[13px]">Sales@Bmsoft.dev</p>
              </div>
            </div>
          </div>
        </div>
        <div className="down-follow flex flex-col gap-y-[10px]">
          <h1 className="down-foll-title text-[22px] my-[15px]">
            შემოგვიერთდით
          </h1>
          <div className="h-[1.5px] rounded-full bg-gray-300">
            <div className="left mainBg w-[50%] h-[100%] rounded-full"></div>
          </div>
          <div className="icons flex gap-[10px] pt-[20px]">
            <div className="text-[24px] cursor-pointer text-[#010125] hover:text-gray-700 duration-200">
              <Facebook />
            </div>
            <div className="text-[24px] cursor-pointer hover:text-gray-700 duration-200 text-[#010125]">
              <Instagram />
            </div>
            <div className="text-[24px] cursor-pointer hover:text-gray-700 duration-200 text-[#010125]">
              <Twitter />
            </div>
            <div className="text-[24px] cursor-pointer hover:text-gray-700 duration-200 text-[#010125]">
              <Linkedin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactContent;
