import { Plus } from "react-bootstrap-icons"

function ProfilePayment(){
    return(
        <div className="pb-[30px]">
          <h1 className="payment-title mb-[40px] text-[22px]">ბარათები</h1>
          <div className="cards-in-prof flex flex-col">
            <div className="add-new-card-prof flex items-center p-[25px] cursor-pointer hover:shadow bg-white gap-[5px] w-[49%] rounded-[16px]">
              <div className="bg-gray-100 w-[40px] rounded-full h-[40px] flex items-center justify-center">
                <Plus className="text-[#010125] text-[25px]" />
              </div>
              <p className="text-[#010125]">ახალი ბარათის დამატება</p>
            </div>
          </div>
        </div>
    )
}

export default ProfilePayment