import { useState } from 'react';

function StarRatingSystem(){
  const [rating, setRating] = useState(0);

  const handleRating = (value) => {
    setRating(value);
  };

  return (
    <div className="text-center">
      {[...Array(5)].map((_, i) => {
        const ratingValue = i + 1;
        return (
          <span
            key={ratingValue}
            className="starsrating text-[50px] cursor-pointer"
            onClick={() => handleRating(ratingValue)}
            style={{ color: ratingValue <= rating ? '#eabe12' : 'gray' }}
          >
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

export default StarRatingSystem