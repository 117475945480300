import LogoShop from "../../../imgs/400.webp";
import { ClockFill } from "react-bootstrap-icons";
import { Link
 } from "react-router-dom";
function PartniorShop({img ,title}) {
  return (
    <Link to={"/mainshop/sasachuqre-baratebi/yvela"}>
    <div className="partnior-card h-[250px] w-[350px] rounded-[15px] cursor-pointer mb-[20px] relative">
      <img
        src={img}
        className="w-[100%] h-[100%] rounded-[15px] object-cover"
        alt=""
      />
      <div className=" w-[100%] h-[100%] rounded-[15px] hover:bg-[#80808082] absolute top-0">
        <div className="partner-card-bottom h-[33%] w-[100%] flex p-[10px] rounded-b-[18px] absolute bottom-0">
          <div className="logo-out w-[27%] h-[100%] flex justify-center relative">
            <div className="logo-in absolute w-[70px] h-[70px] top-[-40%] bg-[white] rounded-full border-2">
              <img
                src={LogoShop}
                className="w-[100%] h-[100%] object-contain rounded-full"
                alt=""
              />
            </div>
          </div>
          <div className="text-card w-[73%]">
            <h1 className="text-white">{title}</h1>
            <div className="text-card-bottom flex justify-between">
              <div className="flex items-center text-white">
                <ClockFill className="mr-[5px]" />
                <p>10:00 - 23:00</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </Link>
  );
}

export default PartniorShop;
