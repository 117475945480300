function CardProdMenu({ img, title }) {
  return (
    <div className="prod-card w-[100%] min-w-[140px] h-[260px] mb-[50px] border-2 bg-white rounded-[15px] relative" >
      <div className="prod-card-img flex  w-[100%] h-[100%] rounded-[15px]">
        <img
          src={img}
          className="image-prod w-[100%] h-[100%] object-cover rounded-[15px]"
          alt=""
        />
      </div>

      <div className="absolute prod-cart top-0 rounded-[15px] h-full w-full justify-center bg-[#00000047] hover:bg-[#00000061] duration-200 items-center text-center flex flex-col">
        <p className="prod-cart-text p-[10px] text-white flex">{title}</p>
      </div>
    </div>
  );
}

export default CardProdMenu;
