import {
  ArrowLeft,
  ArrowLeftShort,
  ArrowRightShort,
  Bank,
  Calendar,
  CashStack,
  Check,
  ChevronLeft,
  Clock,
  CreditCard,
  House,
} from "react-bootstrap-icons";
import CalculatorCart from "../content/cart-folder/calculatorRight";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddPromoCode from "../content/profile/addpromocode";
import CheckAnounsment from "../content/checkoutpage/checkAnounsment";
import easyway from "../../imgs/easyway.svg";
import glovo from "../../imgs/78.webp";
import wolt from "../../imgs/80.webp";
import PayMethodComponent from "../content/checkoutpage/pay-methods-component";
import tbc from "../../imgs/tbc.svg";
import bog from "../../imgs/bog.png";
import cartu from "../../imgs/cartu.png";
import liberty from "../../imgs/liberty.png";
import payze from "../../imgs/payze.png";
import unipay from "../../imgs/unipay.png";
import space from "../../imgs/space.png";
import crystal from "../../imgs/crystal.png";
import rebank from "../../imgs/rebank.png";
import credo from "../../imgs/credo.png";
import tera from "../../imgs/tera.png";
import ActiveMoney from "../contexts/activeMoney";
import { useEffect } from "react";
import CartData from "../contexts/CartData";


function CheckOut() {
  const location = useLocation();
  const navigate = useNavigate();
  const activeMoney = useContext(ActiveMoney);
  const newCartData = useContext(CartData);

  const [Checked, setChecked] = useState('geo');
  const [checkedDelivery, setCheckedDelivery] = useState('day');

  const handleCheck = () => {
    setChecked((prev) => !prev);
  };

  const [showAddPromo, setShowAddPromo] = useState(false);

  const handleShowAddPromo = () => {
    setShowAddPromo((prev) => !prev);
  };

  const [lessMoneyErroe, setLessMoneyError] = useState(false);
  const [allPrice, setAllPrice] = useState(0);

  const handleLessMoneyError = () => {
    if (location.pathname === "/checkout") {
      setLessMoneyError((prev) => !prev);
    } else {
      navigate("/checkout");
    }
  };
  const [deliveryCompanysData, setDeliveryCompanysData] = useState([
    {
      id: 1,
      img: easyway,
      priceDelivery: 5
    },
    {
      id: 2,
      img: glovo,
      priceDelivery: 6
    },
    {
      id: 3,
      img: wolt,
      priceDelivery: 7
    },
  ])
  const [pickdelivery, setPickdelivery] = useState(deliveryCompanysData[0]);
  

  const [active, setActive] = useState(true);
  const [data1, setData1] = useState([]);

  const [methoddata, setMethoddata] = useState([
    {
      id: 1,
      statename: "cards",
      active1:{},
      data: [
        {
          id: 1,
          icon: tbc,
          title: "თიბისი ბანკი",
        },
        {
          id: 2,
          icon: bog,
          title: "საქართველოს ბანკი",
        },
        {
          id: 3,
          icon: cartu,
          title: "ქართუ ბანკი",
        },
        {
          id: 4,
          icon: liberty,
          title: "ლიბერთი ბანკი",
        },
        {
          id: 5,
          icon: payze,
          title: "Payze",
        },
        {
          id: 6,
          icon: unipay,
          title: "უნიფეი",
        },
      ],
      icon: <CreditCard />,
      title: "ბარათი",
    },
    {
      id: 2,
      active1: '',
      statename: "cash",
      icon: <CashStack />,
      icon1: tbc,
      title: "ნაღდი ანგარიშსწორება",
    },
    {
      id: 3,
      active1:{},
      statename: "partpartpay",
      data: [
        {
          id: 1,
          icon: tbc,
          title: "თიბისი ბანკი",
        },
        {
          id: 2,
          icon: bog,
          title: "საქართველოს ბანკი",
        },
        {
          id: 3,
          icon: rebank,
          title: "რე ბანკი",
        },
        {
          id: 4,
          icon: space,
          title: "სფეისი",
        },
      ],
      icon: <Bank />,
      title: "ნაწილ-ნაწილ გადახდა",
    },
    {
      id: 4,
      statename: "ganvadeba",
      active1:{},
      data: [
        {
          id: 1,
          icon: tbc,
          title: "თიბისი ბანკი",
        },
        {
          id: 2,
          icon: bog,
          title: "საქართველოს ბანკი",
        },
        {
          id: 3,
          icon: cartu,
          title: "ქართუ ბანკი",
        },
        {
          id: 4,
          icon: liberty,
          title: "ლიბერთი ბანკი",
        },
        {
          id: 5,
          icon: payze,
          title: "Payze",
        },
        {
          id: 6,
          icon: unipay,
          title: "უნიფეი",
        },
        {
          id: 7,
          icon: tera,
          title: "ტერა ბანკი",
        },
        {
          id: 8,
          icon: credo,
          title: "კრედო ბანკი",
        },
        {
          id: 9,
          icon: rebank,
          title: "რე ბანკი",
        },
        {
          id: 10,
          icon: crystal,
          title: "კრისტალი",
        },
        {
          id: 11,
          icon: space,
          title: "სფეისი",
        },
      ],
      icon: <Bank />,
      title: "განვადება",
    },
  ]);
  useEffect(() => {
    setAllPrice(0);
    newCartData.cartData.map((item) => {
      if (item.sale) {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice(
            (prev) =>
              prev +
              (item.price - (item.price / 100) * item.sale) * item.counter
          );
        } else {
          return setAllPrice(
            (prev) =>
              prev +
              (item.dolPrice - (item.dolPrice / 100) * item.sale) * item.counter
          );
        }
      } else {
        if (activeMoney.activeMoney === "lari") {
          return setAllPrice((prev) => prev + item.price * item.counter);
        } else {
          return setAllPrice((prev) => prev + item.dolPrice * item.counter);
        }
      }
    });
  }, [newCartData]);
  const changer = (test) => {
    const newData=[...methoddata]
    newData.map((item) => {
      if(item.id === data1.id){
        item.active1=test
      }
      else{
        if(typeof(test)){
          item.active1 = ''
        }
        else{
          item.active1={}
        }
      }
    })
    setMethoddata(newData)
  }
  return (
    <div className=" w-[100%] flex justify-center bg-gray-100 mt-[90px] max-[540px]:mt-[70px] flex-col">
      <div
        className="w-full shadow-sm  border-b-[1px] flex
        border-slate-200 items-center justify-center bg-white "
      >
        <div className="w-full flex gap-3 items-center max-w-[1519px] px-[110px] py-[10px] header">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/contactPage">
            <p className="text-[14px]">კონტაქტი</p>
          </Link>
        </div>
      </div>
      <div className="backtocart w-[100%] max-w-[1526px] px-[110px] hidden items-center h-[50px] bg-white shadow">
        <Link to="/cart">
          <div className="flex items-center gap-[10px] cursor-pointer">
            {/* <div className="pb-[3px] text-[20px]">
              <ArrowLeft />
            </div> */}
            <h1 className="text-[18px] font-bold ">შეკვეთის დასრულება</h1>
          </div>
        </Link>
      </div>
      <div className="checkoutPage w-[100%] max-w-[1526px] px-[110px] flex gap-[40px]">
        <div className="checkoutContent w-[70%] mt-[20px] mb-[40px]">
          <div className="checkoutHeader flex justify-between items-center mb-[60px]">
            <h1 className="font-semibold text-[19px]">შეკვეთის დასრულება</h1>
            <Link to="/cart">
              <div className="flex items-center shadow-sm cursor-pointer text-gray-500 bg-white gap-[10px] p-[10px] rounded-[33px]">
                <ArrowLeftShort className="text-[25px]" />
                <p className="text-[17px]">კალათის რედაქტირება</p>
              </div>
            </Link>
          </div>

          <p className="mb-[15px] font-semibold text-[17px]">მისამართი</p>
          <div className="checkout-adrs flex justify-between shadow-sm items-center p-[20px] rounded-[53px] cursor-pointer group mb-[50px] bg-white">
            <div className="flex items-center gap-[15px]">
              <div className="bg-gray-200 w-[45px] h-[45px] rounded-full flex justify-center items-center text-gray-500">
                <House />
              </div>
              <div className="checkoutinf">
                <h1 className="font-semibold text-gray-500">სახლი</h1>
                <p className="adresscheckp text-[14px]">
                  vaja pshavela, georgia
                </p>
              </div>
            </div>
            <div className="w-[40px] h-[40px] rounded-full shadow-sm flex items-center justify-center text-[25px] group-hover:bg-[#010125] group-hover:text-white ease-out duration-200">
              <ArrowRightShort />
            </div>
          </div>

          <p className="mb-[15px] font-semibold text-[17px]">გადახდის მეთოდი</p>

          <div>
            {active && (
              <div className="flex flex-wrap w-full gap-[20px] justify-between">
                {methoddata.map((item) => (
                  <PayMethodComponent
                    key={item.id}
                    item={item}
                    setActive1={setActive}
                    setData1={setData1}
                  />
                ))}
              </div>
            )}

            {!active && (
              <div className="shadow-sm p-[20px] bg-white rounded-[33px] flex flex-col gap-y-[15px]">
                <div
                  onClick={() => {
                    setActive((prev) => !prev);
                  }}
                  className="flex items-center cursor-pointer gap-[10px]"
                >
                  <ChevronLeft />
                  <p>სხვა მეთოდით გადახდა</p>
                </div>
                {data1.data ? (
                  <div className="w-full flex flex-wrap justify-between gap-y-[15px]">
                    {data1.data.map((item) => {
                      return (
                        <div
                          key={item.id}
                          onClick={() => {
                            changer(item);
                            setActive((prev) => !prev);
                          }}
                          className={`flex duration-200 items-center gap-[15px] w-[48%] max-[770px]:w-full  border-[1px] px-[20px] h-[45px] rounded-[33px] hover:shadow cursor-pointer ${
                            data1.active1.id === item.id && "border-[#010125]"
                          }`}
                        >
                          <div
                            className={`w-[20px] h-[20px] rounded-full shadow-sm flex items-center justify-center text-[25px]  text-white ease-in duration-200 ${
                              data1.active1.id === item.id ? "mainBg" : "bg-white"
                            }`}
                          >
                            <Check />
                          </div>
                          <div className="flex gap-[5px] items-center">
                            <div className="h-[25px] items-center justify-center flex">
                              <img
                                src={item.icon}
                                className="w-full h-full object-contain"
                                alt=""
                              />
                            </div>
                            <p className="text-[17px]">{item.title}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setActive((prev) => !prev);
                      changer('cash')
                    }}
                    className={`flex duration-200 items-center gap-[15px] w-[48%] max-[770px]:w-full border-[1px] px-[20px] h-[45px] rounded-[33px] hover:shadow cursor-pointer ${
                      !(data1.active1 === '')  && "border-[#010125]"
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full shadow-sm flex items-center justify-center text-[25px]  text-white ease-in duration-200 ${
                        !(data1.active1 === '')  ? "mainBg" : "bg-white"
                      }`}
                    >
                      <Check />
                    </div>
                    <div className="flex gap-[5px] items-center">
                      <div className="h-[25px]  items-center justify-center flex">
                        <CashStack />
                      </div>
                      <p className="text-[17px]">ნაღდი ანგარიშსწორება</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            
          </div>

          <div className="transOUT hidden mt-[10px] items-center gap-[8px]">
            <div className="w-[18px] h-[18px] text-white text-[18px] rounded-full flex items-center justify-center maing">
              <Check />
            </div>
            <p className="text-[14px]">
              ტრანზაქციის შემდეგ, ბარათი შეინახება ბანკის დაცულ სერვერზე
            </p>
          </div>

          <p className="checkout-del mb-[15px] mt-[50px] font-semibold text-[17px]">
            მიწოდება
          </p>
          <div className="delivery flex w-[100%] gap-[20px] justify-between mb-[50px]">
            <div
              className="delivery-today flex w-[48%] justify-between shadow-sm bg-white items-center p-[20px] rounded-[53px] cursor-pointer group"
              onClick={() => setCheckedDelivery('day')}
            >
              <div className="flex items-center gap-[15px]">
                <div className="bg-gray-200 w-[45px] h-[45px] rounded-full flex justify-center items-center text-gray-500">
                  <Clock />
                </div>
                <div className="checkoutinf">
                  <h1 className="font-semibold text-gray-500">დღესვე მოტანა</h1>
                  <p className="text-[14px]">მოგიტანთ 2-3 საათში</p>
                </div>
              </div>
              <div
                className={`w-[30px] h-[30px] rounded-full shadow-sm flex items-center justify-center text-[25px]  text-white ease-in duration-200 ${
                  checkedDelivery === 'day' ? "mainBg" : "bg-white"
                }`}
              >
                <Check />
              </div>
            </div>
            <div
              className="delivery-tomorow flex w-[48%] justify-between shadow-sm bg-white items-center p-[20px] rounded-[53px] cursor-pointer group"
              onClick={() => setCheckedDelivery('nextDay')}
            >
              <div className="flex items-center gap-[15px]">
                <div className="bg-gray-200 w-[45px] h-[45px] rounded-full flex justify-center items-center text-gray-500">
                  <Calendar />
                </div>
                <div className="checkoutinf">
                  <h1 className="font-semibold text-gray-500">
                    შემდეგ დღეს მოტანა
                  </h1>
                  <p className="text-[14px]">10:00-დან 13:00 საათამდე</p>
                </div>
              </div>
              <div
                className={`w-[30px] h-[30px] rounded-full shadow-sm flex items-center justify-center text-[25px] text-white ease-in duration-200 ${
                  checkedDelivery === 'day' ? "bg-white" : "mainBg"
                }`}
              >
                <Check />
              </div>
            </div>
          </div>

          <p className="mb-[15px] font-semibold text-[17px]">ტელეფონი</p>
          <p className="checkout-tell w-[100%] py-[15px] px-[20px] text-[18px] shadow-sm bg-white rounded-[33px] mb-[50px]">
            555-551-148
          </p>

          <p className="mb-[15px] font-semibold text-[17px]">
            დამატებითი ინსტრუქცია
          </p>
          <textarea
            className="w-[100%] min-h-[150px] p-[20px] rounded-[33px] shadow-sm  bg-white outline-none"
            placeholder="ჩაწერე აქ..."
          ></textarea>
        </div>
        <CalculatorCart
          handleShowAddPromo={handleShowAddPromo}
          showAddPromo={showAddPromo}
          handleLessMoneyError={handleLessMoneyError}
          lessMoneyErroe={lessMoneyErroe}
        />
      </div>
      <div>
        <div className="checkoutbottomsum hidden w-[100%] max-w-[1526px] px-[110px] pb-[88px] bg-white flex-col">
          <div className="flex border-b-2 pb-[20px] pt-[10px] justify-between ">
            <p>ჯამი</p>
            {allPrice} {activeMoney.activeMoney === "lari" ? "₾" : "$"}
          </div>
          <div className="flex flex-col gap-y-[15px] border-b-2 border-[#efefef] py-[20px]">
            <p className="font-semibold">მიწოდება</p>
            <div className="flex flex-col gap-y-[5px]">
              <div className="flex justify-between">
                <div
                  className="flex gap-[10px] items-center"
                  onClick={() => setChecked('geo')}
                >
                  <div
                    className={`w-[10px] h-[10px] rounded-full ${
                      Checked === "geo"
                        ? "bg-[#010125]"
                        : "bg-white border-[1px] border-[#010125]"
                    }`}
                  ></div>
                  <p>საქართველო:</p>
                </div>
              </div>
              <div className={` ${ Checked === "geo" ? "flex" : "hidden"}`}>
                <div className="flex flex-col border-[1px] border-[#010125] items-center gap-y-[3px] bg-gray-100 py-[10px] px-[12px] rounded-[8px]">
                  <div className="w-[40px] h-[30px]">
                    <img
                      src={easyway}
                      className="w-full h-full object-contain"
                      alt=""
                    />
                  </div>
                  <p>5{activeMoney.activeMoney === "lari" ? "₾" : "$"}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[5px]">
              <div className="flex justify-between">
                <div
                  className="flex gap-[10px] items-center"
                  onClick={() => setChecked('tbilisi')}
                >
                  <div
                    className={`w-[10px] h-[10px] rounded-full ${
                      Checked === "geo"
                        ? "bg-white border-[1px] border-[#010125]"
                        : " bg-[#010125]"
                    }`}
                  ></div>
                  <p>თბილისი:</p>
                </div>
                <p className=""></p>
              </div>
              <div
                className={` gap-[5px] overflow-x-auto ${
                  Checked === "geo" ? "hidden" : "flex"
                }`}
              >
                {deliveryCompanysData.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => setPickdelivery(item)}
                    className={`border-[#010125] ${
                      pickdelivery && (pickdelivery.id === item.id )&& "border-[1px]"
                    } flex flex-col items-center gap-y-[3px] bg-gray-100 py-[10px] px-[12px] rounded-[8px]`}
                  >
                    <div className="w-[40px] h-[30px]">
                      <img
                        src={item.img}
                        className="w-full h-full object-contain"
                        alt="#"
                      />
                    </div>
                    <p>
                      {item.priceDelivery}
                      {activeMoney.activeMoney === "lari" ? "₾" : "$"}
                    </p>
                </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex border-b-2 py-[20px] justify-between ">
            <p>პრომო კოდი -20%</p>
            <p>
              {(allPrice / 100) * 20}
              {activeMoney.activeMoney === "lari" ? "₾" : "$"}
            </p>
          </div>
          <div className="flex justify-between py-[20px] border-b-2 border-[#efefef]">
            <p className="font-semibold">მიწოდება</p>
            <p>{ Checked === 'tbilisi' ? pickdelivery.priceDelivery : 5} {activeMoney.activeMoney === "lari" ? "₾" : "$"}{" "}</p>
          </div>
          <div className="flex pt-[20px] justify-between font-semibold">
            <p>სულ თანხა</p>
            <p>
              {(allPrice - (allPrice / 100) * 20)+(Checked === 'tbilisi' ? pickdelivery.priceDelivery : 5)}
              {activeMoney.activeMoney === "lari" ? "₾" : "$"}{" "}
            </p>
          </div>
          <div className="order-end-btn hidden flex-col w-full items-center py-[10px] px-[16px] bg-[white] rounded-[10px] mt-[10px] gap-y-[10px]">
            <div className="flex  justify-between items-center rounded-[10px] h-[45px] px-[5px] w-[50%] max-[530px]:w-[250px]">
              <input
                type="text"
                placeholder="პრომო კოდი"
                className=" outline-none border-b-[1px] border-[#010125] h-full w-[85%] px-[5px]"
              />
              <div className="w-[150px] h-full bg-[#010125] text-white flex justify-center items-center rounded-[30px] cursor-pointer text-[14px]">
                <h1>შენახვა</h1>
              </div>
            </div>
            <Link to="/checkout" className="w-[50%] max-[530px]:w-[250px]">
              <h1 className="endOrderCart bg-[#010125] text-white flex items-center py-[10px] cursor-pointer justify-center w-full rounded-[12px] tracking-wider max-[820px]:text-[14px] ">
                შეკვეთის გაფორმება
              </h1>
            </Link>
          </div>
        </div>
      </div>
      
      {showAddPromo && <AddPromoCode handleShowAddPromo={handleShowAddPromo} />}
      {lessMoneyErroe && (
        <CheckAnounsment handleLessMoneyError={handleLessMoneyError} />
      )}
    </div>
  );
}

export default CheckOut;
