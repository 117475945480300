import { Buildings, CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import PickShop from "./pickshop";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Hover1 from "../mainTopSliderVariations/hover/Hover2";
import CategoryData from "../../contexts/CategoryData";
import { useContext } from "react";
import Hover2 from "../mainTopSliderVariations/hover/Hover2";
import SubCategory from "../../categoryPopUpFolder/SubCategory";
import { BiChevronRight } from "react-icons/bi";

function CategListMshop() {
  const [showpickShop, setPickShop] = useState(false);
  const categoryData= useContext(CategoryData)
  const [isShowSubCategory,setIsShowSubCategory] = useState(false)
  const [subCategoryData, setSubCategoryData] = useState([])
  const handlePickShop = () => {
    setPickShop((prev) => !prev);
  };
  const [windowSize, setWindowSize] = useState([window.innerWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const pickRef = useRef(null);

  useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [windowSize]);

  function handleClickOutside(event) {
    if (pickRef.current && !pickRef.current.contains(event.target)) {
      // click occurred outside of wrapper, close the div here
      setPickShop(false);
    }
  }

  return (
    <div className="categ-list-whole w-[22%] relative  bg-[#f3f4f6]">
      <div
        className={`categ-list w-[100%] flex-col   h-[87vh] top-[90px] border-r pb-[40px] pt-[25px] overflow-y-scroll flex`}
      >
        <div className=" flex flex-col  items-center sticky" ref={pickRef}>
          <div
            className={`pickshop-btn flex items-center bg-white  justify-between p-[5px]  cursor-pointer w-[90%] ${
              showpickShop
                ? "rounded-t-[17px] mainBg text-white"
                : "rounded-[17px]"
            }`}
            onClick={handlePickShop}
          >
            <div className="w-[50px] h-[50px] flex justify-center items-center rounded-full  border-2">
              <Buildings className="text-[25px]" />
            </div>
            <div className="text-[14px] text-center">
              <h1 className="filialsindrop">ფილიალი #1</h1>
              <p className="">1551 შეფასება</p>
            </div>
            <div className="w-[23px] h-[40px] flex flex-col justify-center items-center">
              {showpickShop ? (
                <CaretUpFill className="" />
              ) : (
                <CaretDownFill className="" />
              )}
            </div>
          </div>
          {showpickShop && <PickShop handlePickShop={handlePickShop} />}
        </div>
        <ul className="mt-[12.5px] mx-[20px] py-5 rounded-[15px]  flex flex-col  bg-white pt-[12.5px] gap-y-[10px] px-4 ">
        <div className='w-full h-full overflow-auto '>
        {
          isShowSubCategory ? 
          <SubCategory subCategoryData={subCategoryData} setIsShowSubCategory={setIsShowSubCategory}/>
          :
          // <div className='w-full flex flex-col'>
              <ul className="gap-y-[10px] flex flex-col px-4">
                {
                  categoryData.categoryData.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => {setSubCategoryData(item); setIsShowSubCategory(true)}} 
                      className=" group border-b-2 border-slate-200 flex justify-between items-center ">
                      {/* <Link to={`/products/${item.textLink} `} className={`flex items-center  ${!item.subCategory && 'cursor-pointer-none'} group-one text-gray-600 pr-[5px]`}> */}
                        <h1 className="css-elipses text-[13px] py-[5px] mb-2">{item.text}</h1>
                        {
                          item.subCategory && 
                          <div>
                            <BiChevronRight className='text-[20px] mb-2'/> 
                          </div>
                        }
                      {/* </Link> */}
                    </li>
                  ))
                }
              </ul>
          // </div>
        }
      </div>
        </ul>
      </div>
    </div>
  );
}

export default CategListMshop;
