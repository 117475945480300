import { useEffect, useState } from "react";
import { Check } from "react-bootstrap-icons";

export default function PayMethodComponent({item, setActive1, setData1 }) {
  return (
    <>
      <div
        onClick={() => {
          setActive1(false);
          setData1(item);
        }}
        className="flex gap-[20px] items-center px-[30px] shadow-sm  w-[48%] max-[770px]:w-full bg-white  rounded-[53px] py-[20px] cursor-pointer justify-between"
      >
        <div className="flex items-center gap-[20px]">
          
        <div className="bg-gray-200 w-[45px] h-[45px] rounded-full flex justify-center items-center text-gray-500">
          {item.icon}
        </div>
        <p>{item.title}</p>
        </div>
        {item.active1 && item.active1 !== 'cash'  &&
          <div className="w-[40px] h-[40px] rounded-full flex items-center justify-center">
            <img className='rounded-full ' src={item.active1.icon} alt=''/>
          </div>
        } 
        {item.active1  && item.active1 === 'cash' &&
          <div
          className={`w-[20px] h-[20px] rounded-full mainBg shadow-sm flex items-center justify-center text-[25px]  text-white ease-in duration-200`}
        >
          <Check />
        </div>
        } 
      </div>
    </>
  );
}
