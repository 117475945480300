function ProdVideodescr() {
  return (
    <div className="w-[100%] border items-center flex flex-col bg-white">
      <div className="videostyle w-full  h-[500px]">
        <iframe
          className="w-[100%] h-[100%] rounded-[6px]"
          src="https://www.youtube.com/embed/kYXCHggWUJM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default ProdVideodescr;
