import {
  Clock,
  DashCircleFill,
  Facebook,
  Fonts,
  Instagram,
  Linkedin,
  PlusCircleFill,
  Twitter,
} from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import ft from "../../../imgs/12.webp";
import { useState } from "react";

import React from "react";
import { FacebookProvider, Comments } from "react-facebook";
import { useEffect } from "react";
import { useContext } from "react";
import { PrimaryContext } from "../../allPages/MainContext";
import SmallBlogs from "./smallblogs";

function EveryBlog() {
  const [fontsize, setCount] = useState(16);
  const { bloginfoData } = useContext(PrimaryContext);
  const params = useParams();
  const { id } = params;
  const [item, setItem] = useState([]);

  const [bloginfo, setBloginfo] = useState(null);

  useEffect(() => {
    setBloginfo(bloginfoData);
  }, [bloginfoData]);

  useEffect(() => {
    if (bloginfoData) {
      const filtered = bloginfoData.filter((item) => item.id == id);
      if (filtered) {
        setItem(filtered[0]);
      }
    }
  }, [bloginfoData, id]);
  const handleInc = () => {
    if (fontsize < 30) {
      setCount(fontsize + 2);
    } else {
      fontsize = 29;
    }
  };

  const handleDec = () => {
    if (fontsize > 20) {
      setCount(fontsize - 2);
    } else {
      fontsize = 16;
    }
  };

  return (
    <div className="blogpagemain mt-[90px] w-[100%] bg-gray-100 flex items-center flex-col gap-y-[30px]">
      <div
        className="w-full shadow-sm  border-b-[1px] flex
        border-slate-200 items-center justify-center bg-white "
      >
        <div className="w-full flex gap-3 items-center max-w-[1519px] px-[110px] py-[10px] header">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/allBlogs">
            <p className="text-[14px]">ბლოგები</p>
          </Link>
          <p>/</p>
          <Link to={`/allBlogs/${id}`}>
            <p className='text-[14px]'>{id}</p>
          </Link>
        </div>
      </div>
      <div className="blogpage max-w-[1536px] w-[100%] pb-[50px] px-[110px] ">
      <div className="flex  gap-[10px]">
        <div className="blogleft w-[75%] pt-[20px] flex flex-col gap-y-[20px]">
          <p className="inblogtitle text-[26px]">{item && item.blogtitle}</p>
          <div className="flex gap-[12px] items-center pb-[5px]">
            <div className="flex items-center gap-[8px]">
              <div>
                <Clock />
              </div>
              <p>0:46</p>
            </div>
            <p>გაზიარება:</p>
            <Link to="https://www.facebook.com/sharer/sharer.php?u=https://www.example.com/', '_blank">
              <div className="text-[20px] cursor-pointer text-[#010125] hover:text-gray-700 duration-200">
                <Facebook />
              </div>
            </Link>
            <div className="text-[20px] cursor-pointer hover:text-gray-700 duration-200 text-[#010125]">
              <Instagram />
            </div>
            <div className="text-[20px] cursor-pointer hover:text-gray-700 duration-200 text-[#010125]">
              <Twitter />
            </div>
            <div className="text-[20px] cursor-pointer hover:text-gray-700 duration-200 text-[#010125]">
              <Linkedin />
            </div>
          </div>
          <div className="inblogpht w-[100%] h-[60vh]">
            <img
              src={item && item.blogimg}
              className="w-[100%] h-[100%] object-cover rounded-[20px]"
              alt=""
            />
          </div>
          <div className="flex gap-[5px] text-[20px]">
            <DashCircleFill
              onClick={handleDec}
              className="cursor-pointer mainColor"
            />
            <Fonts />
            <PlusCircleFill
              onClick={handleInc}
              className="cursor-pointer mainColor"
            />
          </div>
          <p className={`inblogparagraph text-[${fontsize}px] w-[95%] duration-200`}>
            {item && item.blogtext}
          </p>

          {/* <FacebookProvider appId="691279866105721">
          <Comments href="http://example.com" />
        </FacebookProvider> */}
        </div>
        <div className="blogscrollright w-[25%] border-l pl-[10px]  top-[90px] pt-[20px] sticky  ">
          <h1 className="moreInteresante pb-[10px] text-center">
            კიდევ დაგაინტერესებთ
          </h1>
          <div className="smallblogsscroll flex flex-col gap-[10px] bg-white rounded-[20px] h-[100vh] overflow-y-scroll">
            {bloginfo !== null &&
              bloginfo.map((object) => {
                return (
                  <div key={object.id}>
                    <SmallBlogs
                      item={object}
                      blogimg={object.blogimg}
                      blogtitle={object.blogtitle}
                      blogtext={object.blogtext}
                      blogtime={object.blogtime}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default EveryBlog;
