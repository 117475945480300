import { Buildings, ClockFill } from "react-bootstrap-icons";

import Pht from "../../../imgs/2.webp"

function PickShopCard({ handleDroplist, title}) {
    return (
      <div className="cursor-pointer w-[100%] h-[150px] bg-white rounded-[16px] relative"
      onClick={()=>{handleDroplist()}}>
        <img src={Pht} className="w-[100%] h-[100%] object-cover rounded-[16px]" alt="" />
        <div className="absolute bottom-0 flex w-[100%] p-[10px] gap-[5px] items-center h-[50%] left-0 rounded-b-[16px] bg-white">
          <div className=" w-[50px] h-[50px] shadow flex items-center justify-center rounded-full">
            <Buildings className="text-[20px]"/>
          </div>
          <div className="w-[100%]">
            <h1>{title}</h1>
            <div className="flex justify-between items-center text-[13px] w-[100%]">
              <div className="flex items-center gap-[2px]">
                <ClockFill />
                <p>10:00</p>
                <p>-</p>
                <p>23:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default PickShopCard