import HeaderStyle from "../contexts/HeaderStyle";
import Header1 from "./Header1";
import Header2 from "./Header2";

import React, { useContext } from 'react'
import Header3 from "./header3";

const Header = () => {
  const headerStyle = useContext(HeaderStyle) 
  return (
    <>
    {headerStyle.headerStyle === 'header1' && <Header1/>}
    {headerStyle.headerStyle === 'header2' && <Header2/>}
    {headerStyle.headerStyle === 'header3' && <Header3/>}
    </>
  )
}

export default Header