import React from 'react'
import MainProductTwoSide from './MainProductTwoSide'
import MainCategory from './MainCategory'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import CategoryData from '../../contexts/CategoryData'

const MainProduct = () => {
  const location = useLocation();
  const categoryData = useContext(CategoryData)
  const {pathname} = location;  
  const [subCategory, setSubCategory] = useState()
  const test2 = pathname.split("/")[3]
  const [lastAndLast1,setLastAndLast1] = useState('')
  const test = pathname.split("/")[2]
  useEffect(()=>{

    let lastAndLast = ''
    if(subCategory && test2){
      subCategory.subCategory.map((item) => {
        if(item.title === test2){
          lastAndLast=item.titleGeo
        }
      })
    }
    setLastAndLast1(lastAndLast)
  },[test2])
  useEffect(() => {
    setSubCategory(categoryData.categoryData.filter((item) => item.textLink === test)[0])
  },[pathname])

  return (
    <div className='flex flex-col mt-[90px] max-[540px]:mt-[70px]'>
      <div className="w-full py-3 px-5 shadow-sm  border-b-[1px]  border-slate-200 items-center bg-white"> 
          <div className='flex flex-wrap gap-2'> 
            <Link to='/'>
              <p>მთავარი </p>
            </Link>
            <p>/</p>
            <Link to='/products'>
              <p>პროდუქტები</p>
            </Link>
        { subCategory &&
          <>
            <p>/</p>
            <Link to={`/products/${subCategory.textLink}`}>
              <p>{subCategory.text}</p>
            </Link>
            {
              lastAndLast1 && test2 && 
              <>
                <p>/</p>
                <Link to={`/products/${subCategory.textLink}/${test2}`}>
                  <p>{lastAndLast1}</p>
                </Link>
              </>
            }
            </>
        }
        </div>
      </div>
      <MainProductTwoSide>
        <MainCategory/>
      </MainProductTwoSide>
    </div>
  )
}

export default MainProduct