import PartniorShop from "./partniorShop";
import { useEffect, useState, useContext } from "react";
import { PrimaryContext } from "../../allPages/MainContext";

function PartniorShopList() {
  const filialsData = useContext(PrimaryContext).filialsData;
  const [filials, setDatasl] = useState(null);

  useEffect(() => {
    setDatasl(filialsData);
  }, [filialsData]);

  return (
    <div className="partnior-part mt-[20px] flex justify-center bg-[#f4f4f4]">
      <div className="partnior-shop w-[100%] px-[110px] max-w-[1526px]">
        <div className="flex justify-between mb-[20px] items-center">
          <h1 className="partner-text font-semibold text-[22px]">ფილიალები</h1>
        </div>
        <div className="filials flex gap-[15px] rounded-[15px] justify-start w-[100%] overflow-x-scroll">
          {!(filials === null) && (
            <>
              {filials.map((object) => {
                return (
                  <PartniorShop
                    key={object.id}
                    img={object.img}
                    title={object.name}
                    index={object.id}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PartniorShopList;
