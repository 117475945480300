import { Basket } from "react-bootstrap-icons";
import { Link } from "react-router-dom";


function EmptyCartBg(){
    return(
      <div className="emptycart flex justify-center flex-col items-center h-[70%] py-[15%]">
        <div className="bg-[#F1F1F5] w-[140px] h-[140px] rounded-[10px]  flex justify-center items-center">
          <Basket className="text-[40px] text-gray-500" />
        </div>
        <p className="pt-[20px] pb-[30px] font-semibold text-gray-500">კალათა ცარიელია</p>
        <Link to="/">
        <h1 className="bg-[#010125] text-white font-medium px-[20px] pt-[10px] pb-[8px] rounded-[33px] cursor-pointer">
          პროდუქტების დამატება
        </h1>
        </Link>
      </div>
    )
}

export default EmptyCartBg