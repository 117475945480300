import { BellFill, XLg } from "react-bootstrap-icons";

function CheckAnounsment({ handleLessMoneyError }) {
  return (
    <div className="w-[100vw] h-[100vh] z-[15] fixed top-0 left-0 bg-[#00000066] flex items-center justify-center">
      <div
        className="w-[100%] h-[100%] absolute"
        onClick={handleLessMoneyError}
      ></div>
      <div className="anounsmentbox w-[550px] z-[99] bg-white rounded-[18px]">
        <div className="flex justify-between border-b items-center px-[20px] py-[8px]">
          <h1 className="pt-[5px]">შეტყობინება</h1>
          <div className="w-[40px] h-[40px] rounded-full flex items-center shadow-btn cursor-pointer justify-center"
          onClick={handleLessMoneyError}>
            <XLg />
          </div>
        </div>
        <div className="anounsmentcontent flex flex-col justify-center items-center gap-y-[30px]  px-[20px] py-[40px]">
          <div className="w-[80px] h-[80px] rounded-full flex flex-col justify-center items-center bg-[#0101254e]">
            <div className="w-[50px] h-[50px] rounded-full flex flex-col justify-center items-center bg-[#010125] text-white">
              <BellFill />
            </div>
          </div>
          <p className="text-[17px]">მინიმალური შეკვეთის ფასია - 40 ₾</p>
          <div className="itsclearanounsment w-[300px] mt-[10px] h-[50px] flex justify-center items-center text-white text-[20px] cursor-pointer bg-[#010125] rounded-[33px]"
          onClick={handleLessMoneyError}>
            <h1>გასაგებია</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckAnounsment;
