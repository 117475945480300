import React, { useState, useEffect } from "react";
import { Dash, PlusLg, Trash3 } from "react-bootstrap-icons";

function OrdersCounter({ handleQuantity, price, items,handelDelete,newCartData }) {
  const [count, setCount] = useState(items.counter);

  const handleInc = () => {
    // setCount(count + 1)
    const lastCartData=[...newCartData.cartData]
    lastCartData.map((item)=> {
      if(item.id === items.id){
        item.counter=item.counter+1
      }
    })
    newCartData.setCartData(lastCartData)
    

  };

  const handleDec = () => {
    if (items.counter !== 1) {
      // setCount(count - 1);
      const lastCartData=[...newCartData.cartData]
      lastCartData.map((item)=> {
      if(item.id === items.id){
        item.counter=item.counter-1
      }
      })
      newCartData.setCartData(lastCartData)
    }
  };

  // useEffect(() => {
  //   handleQuantity(count);
    
  // }, [count]);

  return (
    <div className="h-[40px] max-md:h-[30px] flex flex-col">
      <div
        className="items-center border flex justify-between h-full rounded-[10px]">
        <div
          onClick={() => handelDelete(items.id)}
          className={`whenOne w-[40px] max-md:w-[25px] cursor-pointer h-full rounded-l-[10px] flex justify-center items-center hover:text-white hover:bg-[#010125] ${
            items.counter <= 1
              ? `${items.counter == 0 ? "hidden" : "block "}`
              : "hidden"
          }`}
        >
          <Trash3 className=" text-[18px]  max-md:text-[15px] " />
        </div>

        <div
          onClick={handleDec}
          className={`whenMoreTwo w-[40px] max-md:w-[25px] cursor-pointer h-full rounded-l-[10px] flex  justify-center items-center hover:text-white hover:bg-[#010125] ${
            items.counter <= 1
              ? `${items.counter == 0 ? "hidden" : "hidden"}`
              : "block"
          }`}
        >
          <Dash className="text-[22px]  max-md:text-[18px] " />
        </div>

        <p className="num w-[40px] max-md:w-[25px] h-full text-[18px] items-center flex justify-center max-md:pt-[4px] max-md:text-[16px]">
          {items.counter}
        </p>

        <div
          onClick={handleInc}
          className= "w-[40px] max-md:w-[25px] cursor-pointer  h-full rounded-r-[10px] flex justify-center items-center hover:text-white hover:bg-[#010125]"
        >
          <PlusLg className="text-[22px]  max-md:text-[18px] font-semibold" />
        </div>
      </div>
    </div>
  );
}

export default OrdersCounter;
