import { useState } from "react";

function Proddescr() {
  const [height, setheight] = useState(false)

  const handleheight = () =>{
    setheight((pre)=>!pre)
  }
  return (
    <div className="proddescription w-[100%] h-[100%] flex flex-col gap-[15px]">
      <p
        type="text"
        placeholder="პროდუქტის აღწერა"
        className={`prod-descrip outline-none w-[100%] overflow-hidden ${height ? "h-auto" : "h-[70px]"}`}
      >
        შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული
        ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი
        წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის
        შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი. სწორედ ასეთ
        დროს არის მოსახერხებელი ამ გენერატორით შექმნილი ტექსტის გამოყენება,
        რადგან უბრალოდ „ტექსტი ტექსტი ტექსტი“ ან სხვა გამეორებადი სიტყვების
        ჩაყრა, ხელოვნურ ვიზუალურ სიმეტრიას ქმნის და არაბუნებრივად გამოიყურება.
      </p>
      <p onClick={()=>(handleheight())} className="cursor-pointer">{height ? "ნაკლების ნახვა" : "მეტის ნახვა"}</p>
    </div>
  );
}

export default Proddescr;
