import PickShopCard from "./pickshopcards";



function PickShop({ handleDroplist}) {
  return (
    <div className="pickshpmain flex flex-col gap-y-4 shadow items-center absolute top-[60px] bg-white w-[90%] p-[20px] rounded-b-[16px]">
      <PickShopCard handleDroplist={handleDroplist} title={"ფილიალი #2"}/>
      <PickShopCard handleDroplist={handleDroplist} title={"ფილიალი #3"}/>
    </div>
  );
}

export default PickShop;
