import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function ForgetPassword1({setActive}) {
  const [mailValue,setMailValue] = useState('')
  const [isMailValueError,setIsMailValueError] = useState(false)
  ///handel change mailValue
  const handelChangeMailValue = (e) => {
    setMailValue(e.target.value)
    const re = /\S+@\S+\.\S+/.test(mailValue)
    if(mailValue.length === 0 || !re){
      setIsMailValueError(true)
    }
    else{
      setIsMailValueError(false)
    }
  }
  const handelClick = () => {
    if((!isMailValueError) && (mailValue.length > 0)){
      setActive("forgetpassword2")
    }
    else{
      setIsMailValueError(true)
    }
    
  }
  return (
    <div className="forgotpaswordee  w-full flex flex-col gap-y-[10px] mt-[-3px] px-[18%]">
      <p className="text-center text-[18px]">პაროლის აღდგენა</p>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ელ-ფოსტა</p>
        <div className='flex flex-col gap-1 '>
          <input
            value={mailValue}
            onChange={(e) => handelChangeMailValue(e)}
            type="text"
            placeholder="e.g john@mail.com"
            className={`w-[100%] h-[50px] text-[#010125] pb-[5px] px-[10px] border-[1px]  ${isMailValueError && ' border-red-600'} rounded-[10px] outline-none`}
          />
          {isMailValueError && <p className="font-semibold text-[12px] text-red-600 text-center">მიუთითეთ ელ-ფოსტა</p>}
        </div>
      </div>
      <div onClick={() => handelClick()} className="flex gap-[5px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-[#010125] text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#010125] border-[2px] border-[#010125] hover:bg-white duration-200">
        <h1>კოდის გაგზავნა</h1>
      </div>
    </div>
  );
}
