import { useContext, useEffect, useState } from "react";
import { Link} from "react-router-dom";
import { PrimaryContext } from "./MainContext";
import BlockBlog from "../content/blogpagecomponents/blockblogs";

function Blog() {
  const { bloginfoData } = useContext(PrimaryContext);
  const [bloginfo, setBloginfo] = useState(null);

  useEffect(() => {
    setBloginfo(bloginfoData);
  }, [bloginfoData]);

  // const location = useLocation();
  // const navigate = useNavigate(null);

  // useEffect(() => {
  //   if (location.pathname === "/blog") {
  //     navigate("/blog/allBlogs");
  //   }
  // }, [location]);

  return (
    // <div className="blogpagemain mt-[90px] w-[100%] bg-gray-100 flex items-center flex-col gap-y-[30px]">
    //   <div className="blogpage max-w-[1536px] w-[100%] pb-[50px] px-[110px] ">
    //     <Routes>
    //       <Route path={"allBlogs"} element={<BlogStart />}></Route>
    //       <Route path={"inBlog/:id"} element={<EveryBlog />}></Route>
    //     </Routes>
    //   </div>
    // </div>
    <div className=" mt-[90px] max-[540px]:mt-[70px] w-[100%] bg-gray-100 flex items-center flex-col">
      <div
        className="w-full shadow-sm  border-b-[1px] flex
        border-slate-200 items-center justify-center bg-white "
      >
        <div className="w-full flex gap-3 items-center max-w-[1519px] px-[110px] py-[10px] header">
          <Link to="/">
            <p className="text-[14px]">მთავარი</p>
          </Link>
          <p>/</p>
          <Link to="/allBlogs">
            <p className="text-[14px]">ბლოგები</p>
          </Link>
        </div>
      </div>
      <div className="blogpage max-w-[1536px] w-[100%] pb-[50px] px-[110px] ">
        <div className="w-[100%] items-center flex flex-col gap-y-[30px] pt-[20px]">
          <div className="flex justify-between w-[100%]">
            <div className="blogmaintitle mainBg w-[200px] max-[800px]:w-[200px] h-[40px] pt-[4px] items-center flex justify-center text-white rounded-[33px] text-[20px] max-[500px]:text-[17px] max-[500px]:w-[160px]">
              <h1>ბლოგები</h1>
            </div>
          </div>
          <div className="w-[100%]">
            <div className="giftcard-container blogconta flex  flex-wrap gap-y-[30px] gap-[2.5%]">
              {!(bloginfo === null) && (
                <>
                  {bloginfo.map((object) => {
                    return (
                      <div key={object.id} className="blockblog w-[18%]">
                        <BlockBlog
                          id={object.id}
                          blogimg={object.blogimg}
                          blogtitle={object.blogtitle}
                          blogtext={object.blogtext}
                          blogtime={object.blogtime}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
