import { Pencil, Person } from "react-bootstrap-icons";

function ProfileProfileWindow() {
  return (
    <div className="w-[100%]">
      <h1 className="profile-title text-[22px] mb-[40px]">პროფილი</h1>
      <div className="profile-cont w-[100%] flex gap-[20px]">
        <div className="profile-pht-cont w-[25%] cursor-pointer hover:shadow bg-white p-[20px] rounded-[16px] flex flex-col justify-between items-center">
          <div className="w-[120px] h-[120px] rounded-full bg-gray-200 flex justify-center items-center">
            <Person className="text-[35px] text-gray-600" />
          </div>
          <div className="flex gap-[10px]">
            <h1>გიორგი</h1>
            <h1>ჩიქოვანი</h1>
          </div>
          <p>591 908 930</p>
          <p>chiqovanig@gmail.com</p>
          <div className="flex border-[1px] border-gray-400  text-gray-500 w-[100%] items-center justify-center gap-[5px] py-[10px] rounded-[12px]">
            <Pencil />
            <p>ფოტოს შეცვლა</p>
          </div>
        </div>
        <div className="profile-input-area w-[75%] bg-white hover:shadow flex flex-col gap-y-[15px] p-[30px] rounded-[15px]">
          <fieldset className="profile-fieldset border-[1px] w-[400px] rounded-[12px]">
            <input
              type="text"
              placeholder="შენი სახელი"
              className="mx-[10px] mb-[10px] outline-none"
            />
            <legend className="ml-[10px] px-[5px]">სახელი</legend>
          </fieldset>
          <fieldset className="profile-fieldset border-[1px] w-[400px] rounded-[12px]">
            <input
              type="text"
              placeholder="შენი ელ. ფოსტა"
              className="mx-[10px] mb-[10px] outline-none"
            />
            <legend className="ml-[10px] px-[5px]">ელ. ფოსტა</legend>
          </fieldset>
          <fieldset className="profile-fieldset border-[1px] w-[400px] rounded-[12px]">
            <input
              type="text"
              placeholder="შენი ტელეფონი"
              className="mx-[10px] mb-[10px] outline-none"
            />
            <legend className="ml-[10px] px-[5px]">ტელეფონი</legend>
          </fieldset>
          <div className="">
            <h1 className="profile-save-btn py-[15px] float-right w-[110px] text-white cursor-pointer text-center rounded-[10px] bg-[#010125]">
              შენახვა
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileProfileWindow;
