import { useState, useEffect } from "react";
import Counter from "./prodcounter";
import { useLocation, Link } from "react-router-dom";
import { Cart, EyeFill, Heart, HeartFill } from "react-bootstrap-icons";
import CartData from "../../contexts/CartData";
import { useContext } from "react";
import WishlistData from "../../contexts/WishlistData";
import ActiveMoney from "../../contexts/activeMoney";
import useFunction from "../../hooks/useFunction";

function Salecomponent({
  item,
  img,
  title,
  price,
  sale,
  index,
  handleShowProdPopup,
  handlePopupDataChange,
  empty,
}) {
  let location = useLocation();
  const [counternum, setCounternum] = useState(1);
  const [test1, setTest1] = useState(0);
  const newCartData = useContext(CartData);
  const activeMoney = useContext(ActiveMoney);
  const [imageChanger, setImageChanger] = useState(img);
  const [hearted, setHearted] = useState(false);
  const wishlistData = useContext(WishlistData);

  const addWishlist = (items) => {
    const filtered = wishlistData.wishlistData.find(
      (item) => item.id === items.id
    );
    if (filtered) {
      console.log("test1");
    } else {
      const newWishlist = [{ ...items }, ...wishlistData.wishlistData];
      wishlistData.setWishlistData(newWishlist);
      localStorage.setItem("wishlistItem", JSON.stringify(newWishlist));
    }
  };
  const [addCart, handelHeart] = useFunction();
  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData]);
  const mouseOver = () => {
    if(colorDefault === item.color[0].name){
      setImageChanger(item.img1)
    }
  }
  const mouseOut = () => {
    if(colorDefault === item.color[0].name){
      setImageChanger(item.img)
    }
  }
  const [colorDefault, setColorDefault] = useState(item.color[0].name);

  return (
    <div className="prodcomp w-[100%] flex flex-col justify-end h-[100%] relative items-center group">
      <div
        className={`relative    rounded-[15px] flex flex-col justify-between ${
          location.pathname.split("/")[1] === "mainshop"
            ? "cardib w-[100%] "
            : "sale-card-pad w-[100%]"
        }`}
      >
        {
          item.sale &&
          <div className="absolute left-[8px] top-[8px] px-[8px] h-[25px] bg-[red] rounded-[20px] text-white text-[12px] flex items-center justify-center pt-[2px]">
            <p>-{sale}%</p>
          </div>
        }
        <div className="absolute z-[8] top-[8px] right-[8px] gap-y-[5px] flex flex-col sale-card-bottompart items-center">
          <div
            className="w-[30px] h-[30px] rounded-full flex items-center justify-center mainBg cursor-pointer text-white"
            onClick={() => {
              handelHeart(item);
            }}
          >
            {hearted ? <HeartFill /> : <Heart />}
          </div>
          <div
            className="w-[30px] h-[30px]  cursor-pointer rounded-full flex items-center justify-center  mainBg text-white"
            onClick={() => {
              handlePopupDataChange(item, counternum);
              handleShowProdPopup();
            }}
          >
            <EyeFill />
          </div>
        </div>
        <Link to={`/product/${item.id}`}>
          <div
            onMouseOver={() => mouseOver()}
            onMouseOut={() => mouseOut()}
            className={`cursor-pointer  w-[100%] flex justify-center bg-white rounded-[20px] ${
              location.pathname === "/product"
                ? "prodcomp-img h-[180px]"
                : `${
                    location.pathname.split("/")[1] === "mainshop"
                      ? "mainshopImage h-[200px]"
                      : "salecardimage h-[250px]"
                  }`
            }`}
          >
            <img  
              src={imageChanger}
              className="w=[100%] h-[98%] object-contain rounded-[33px]"
              alt=""
            />
          </div>
        </Link>
        <div className="py-[15px]">
          <div className="flex items-center gap-[5px] my-[10px]">
            {item.color.map((item) => (
              <div
                key={item.id}
                onClick={() => {
                  setColorDefault(item.name);
                  setImageChanger(item.src);
                }}
                className={`w-[30px] h-[30px] rounded-full border-[${colorDefault}] ${
                  colorDefault === item.name && "border-[1px]"
                } flex 
          items-center justify-center  cursor-pointer`}
              >
                <div
                  className={`w-[70%] h-[70%] bg-[${item.name}] rounded-[33px]`}
                ></div>
              </div>
            ))}
          </div>
          <Link to={`/product/${item.id}`}>
            <p className="salecardtitle">{title}</p>
          </Link>

          <div className="flex items-center  gap-[10px] justify-between max-[520px]:flex-col max-[520px]:items-start">
            {item.sale ? (
              <div className="salecrds flex gap-[5px] items-center ">
                <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                  {activeMoney.activeMoney === "lari"
                    ? `${price - (price / 100) * item.sale}₾`
                    : `${item.dolPrice - (item.dolPrice / 100) * item.sale}$`}
                </p>

                <p className="downtexti line-through text-[12px] pt-[5px]">
                  {activeMoney.activeMoney === "lari"
                    ? `${price}₾`
                    : `${item.dolPrice}$`}
                </p>
              </div>
            ) : (
              <div className="salecrds flex gap-[5px] items-center ">
                <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
                  {activeMoney.activeMoney === "lari"
                    ? `${price}₾`
                    : `${item.dolPrice}$`}
                </p>
              </div>
            )}
            {empty ? (
              <div
                onClick={() => {
                  addCart(item, counternum);
                  setTest1((prev) => prev + 1);
                }}
                className="giftcardbuy bg-[#010125] hover:bg-[#010125a2] duration-200 max-[520px]:w-full h-[35px] max-[410px]:px-[5px] pb-[3px] px-[10px] gap-[5px] text-[13px] flex justify-center items-center rounded-[33px] text-white cursor-pointer"
              >
                <Cart />
                <p className="">დამატება</p>
              </div>
            ) : (
              <div className="giftcardbuy  bg-[#0101254f] h-[35px] pb-[4px] pt-[5px] max-[520px]:w-full px-[8px] max-[410px]:px-[5px] flex justify-center text-[11px] items-center pointer-events-none rounded-[33px] text-white cursor-pointer">
                <p>არარის მარაგში</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Salecomponent;
