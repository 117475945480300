import "./styles/main.css";
import "./responsive.css";

import { MainContext } from "./components/allPages/MainContext";
import ScrollToTop from "./components/scrolltotop/scrollToTop";
import MainRouter from "./components/router/MainRouter";
import {useEffect, useRef, useState} from 'react'
import CartData from './components/contexts/CartData'
import WishlistData from "./components/contexts/WishlistData";
import ActiveMoney from "./components/contexts/activeMoney";
import CartPopUpShow from "./components/contexts/CartPopUpShow";
import CartPopUp from './components/CartPopUp'
import SettingPopUp from "./components/SettingPopUp";
import SettingPopUpShow from "./components/contexts/SettingPopUpShow";
import HeaderStyle from "./components/contexts/HeaderStyle";
import TopSliderVariations from "./components/contexts/topslidervariations";
import TopSliderWithText from "./components/contexts/TopSliderWithText";
import CategoryData from "./components/contexts/CategoryData";
import BorderRadiusContext from "./components/contexts/BorderRadiusContext";
import ProductStyleContext from "./components/contexts/ProductStyleContext";
import FirstSliderStyleContext from "./components/contexts/FirstSliderStyleContext";
import SecondSliderStyleContext from "./components/contexts/SecondSliderStyleContext";
import ThirdSliderStyleContext from "./components/contexts/ThirdSliderStyleContext";
import TestingContext from "./components/contexts/TestingContext";
import IsShowCategoryPopUp from "./components/contexts/IsShowCategoryPopUp";


function App() {
  const [cartData , setCartData] = useState([])
  useEffect( () => {
    const items=localStorage.getItem("items")
    if(items) {
      setCartData(
          JSON.parse(localStorage.getItem("items"))
        )
    }
  },[])
  useEffect( () => {
    const items=localStorage.getItem("wishlistItem")
    if(items) {
      setWishlistData(
          JSON.parse(localStorage.getItem("wishlistItem"))
        )
    }
  },[])
  const [testingContext , setTestingContext] = useState(1)
  const [cartPopUpShow , setCartPopUpShow] = useState(false)
  const [settingPopUpShow , setSettingPopUpShow] = useState(false)
  const [isShowCategoryPopUp , setIsShowCategoryPopUp] = useState(false)
  const [headerStyle , setHeaderStyle] = useState('header1')
  const [productStyleContext , setProductStyleContext] = useState('style3')
  const [firstSliderStyleContext , setFirstSliderStyleContext] = useState('style1')
  const [secondSliderStyleContext , setSecondSliderStyleContext] = useState('style1')
  const [thirdSliderStyleContext , setThirdSliderStyleContext] = useState('style1')
  const [borderRadius , setBorderRadius] = useState('15')
  const [topslidervariations, setTopSliderVariations] = useState('slider1')
  const [wishlistData , setWishlistData] = useState([])
  const [activeMoney , setActiveMoney] = useState('lari')
  const [topsliderwithtext , setTopSliderWithText] = useState('sliderwithtext')
  const [categoryData,setCategoryData] = useState([
    {
      id:1,
      text:'ავეჯი სახლისთვის',
      textLink:'avegiSaxlistvis',
      style:'style1',
      subCategory:[
        {
          id:1,
          title:'karada',
          titleGeo:'კარადის კოლექცია',
          parent:{
            id:1,
            textLink:'avegiSaxlistvis',
          },
        },
        {
          id:2,
          title:'skamebi',
          titleGeo:'სკამები კოლექცია ',
          parent:{
            id:1,
            textLink:'avegiSaxlistvis',
          },
          subCategory:[
            {
              id:1,
              title:'misagebisSkamebi',
              titleGeo:'მისაღების სკამები',
              parent:{
                id:2,
                textLink:'skamebi',
              },
            },
            {
              id:2,
              title:'samzareulosSkamebi',
              titleGeo:'სამზარეულოს სკამები',
              parent:{
                id:2,
                textLink:'skamebi',
              },
            }
          ]
        },
        {
          id:3,
          title:'misagebisMagida',
          titleGeo:'მისაღების მაგიდა',
          parent:{
            id:1,
            textLink:'avegiSaxlistvis',
          }
        },
        {
          id:4,
          title:'sadzineblisKarada',
          titleGeo:'საძინებლის კარადა',
          parent:{
            id:1,
            textLink:'avegiSaxlistvis',
          }
        },
        {
          id:5,
          title:'sadzineblisTumbo',
          titleGeo:'საძინებლის ტუმბო',
          parent:{
            id:1,
            textLink:'avegiSaxlistvis',
          }
        },
      ]
    },
    {
      id:2,
      text:'საოჯახო ტექნიკა',
      textLink:'saojaxoTeqnika',
      style:'style1',
      subCategory:[
        {
          id:1,
          title:'Televizori',
          titleGeo:'ტელევიზორი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          },
        },
        {
          id:2,
          title:'mtversasruti',
          titleGeo:'მტვერსასრუტი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          },
          subCategory:[
            {
              id:1,
              title:'robotiMtversasruti',
              titleGeo:'რობოტი მტვერსასრუტი',
              parent:{
                id:2,
                textLink:'mtversasruti',
              },
            },
            {
              id:2,
              title:'portatuliMtversasruti',
              titleGeo:'პორტატული მტვერსასრუტი',
              parent:{
                id:2,
                textLink:'mtversasruti',
              },
            },
            {
              id:3,
              title:'mtversasruti',
              titleGeo:'მტვერსასრუტი',
              parent:{
                id:2,
                textLink:'mtversasruti',
              },
            }
          ]
        },
        {
          id:3,
          title:'macivari',
          titleGeo:'მაცივარი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:4,
          title:'sarecxisManqana',
          titleGeo:'სარეცხის მანქანა',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:5,
          title:'wuwliaercxiManqana',
          titleGeo:'ჭურჭლის სარეცხი მანქანა',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:6,
          title:'tosteri',
          titleGeo:'ტოსტერი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:7,
          title:'blenderi',
          titleGeo:'ბლენდერი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:8,
          title:'choferi',
          titleGeo:'ჩოფერი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:9,
          title:'choxhorcisSakepiManqana',
          titleGeo:'ხორცის საკეპი მანქანა',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:10,
          title:'miqseri',
          titleGeo:'მიქსერი',
          parent:{
            id:2,
            textLink:'saojaxoTeqnika',
          }
        },
      ]
    },
    {
      id:3,
      text:'ქვაბებისა და ტაფების ნაკრები',
      textLink:'qvabebisaDaTTafebisNabori',
      style:'style1',
      subCategory:[
        {
          id:1,
          title:'granitisNakrebi',
          titleGeo:'გრანიტის ნაკრები',
          parent:{
            id:3,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:2,
          title:'ujangaviFoladisNakrebi',
          titleGeo:'უჟანგავი ფოლადი ნაკრები',
          parent:{
            id:3,
            textLink:'saojaxoTeqnika',
          }
        },
        {
          id:3,
          title:'emalisNakrebi',
          titleGeo:'ემაილის ნაკრები',
          parent:{
            id:3,
            textLink:'saojaxoTeqnika',
          }
        },
      ]
    },
    {
      id:4,
      text:'სახლი და ეზო',
      textLink:'SaxliEzo',
      style:'style1',
      subCategory:[
        {
          id:1,
          title:'gazonissakrewi',
          titleGeo:'გაზონის საკრეჭი',
          parent:{
            id:4,
            textLink:'SaxliEzo',
          }
        },
        {
          id:2,
          title:'ganateba',
          titleGeo:'განათება',
          parent:{
            id:4,
            textLink:'SaxliEzo',
          }
        },
        {
          id:3,
          title:'shezlongebi',
          titleGeo:'შეზლონგები',
          parent:{
            id:4,
            textLink:'SaxliEzo',
          }
        },
      ]
    },
    {
      id:5,
      text:'მობილური ტელეფონები',
      textLink:'mobiluriTelefonebi',
      style:'style2',
      subCategory:[
        {
          id:1,
          title:'brand',
          titleGeo:'ბრენდები',
          parent:{
            id:5,
            textLink:'mobiluriTelefonebi',
          },
          subCategory:[
            {
              id:1,
              title:'Apply',
              titleGeo:'Apple',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:2,
              title:'Samung',
              titleGeo:'Samung',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:3,
              title:'Xiaomi',
              titleGeo:'Xiaomi',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:4,
              title:'Poco',
              titleGeo:'Poco',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:5,
              title:'Vivo',
              titleGeo:'Vivo',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:6,
              title:'Google',
              titleGeo:'Google',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:7,
              title:'Nothing',
              titleGeo:'Nothing',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:8,
              title:'OnePluse',
              titleGeo:'OnePluse',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:9,
              title:'Nothing',
              titleGeo:'Nothing',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
            {
              id:10,
              title:'Nokia',
              titleGeo:'Nokia',
              parent:{
                id:1,
                textLink:'brand',
              },
            },
          ]
        },
        {
          id:2,
          title:'smartSabtebi',
          titleGeo:'სმარტ საათები',
          parent:{
            id:5,
            textLink:'mobiluriTelefonebi',
          },
          subCategory:[
            {
              id:1,
              title:'AppleWatch',
              titleGeo:'Apple Watch',
              parent:{
                id:2,
                textLink:'smartSabtebi',
              },
            },
            {
              id:2,
              title:'galaxyWatch',
              titleGeo:'Galaxy Watch',
              parent:{
                id:2,
                textLink:'smartSabtebi',
              },
            },
            {
              id:3,
              title:'MiWatch',
              titleGeo:'Mi Watch',
              parent:{
                id:2,
                textLink:'smartSabtebi',
              },
            },
            {
              id:4,
              title:'FitnesTrecker',
              titleGeo:'ფიტნეს ტრეკერები',
              parent:{
                id:2,
                textLink:'smartSabtebi',
              },
            },
            {
              id:5,
              title:'watchaqsesuarebi',
              titleGeo:'საათის აქსესუარები',
              parent:{
                id:2,
                textLink:'smartSabtebi',
              },
            },
            {
              id:6,
              title:'treckeraccessories',
              titleGeo:'ტრეკერის აქსესუარები',
              parent:{
                id:2,
                textLink:'smartSabtebi',
              },
            },
          ]
        },
        {
          id:3,
          title:'yursasmenbi',
          titleGeo:'ყურსასმენები',
          parent:{
            id:5,
            textLink:'mobiluriTelefonebi',
          },
          subCategory:[
            {
              id:1,
              title:'apple airpod',
              titleGeo:'Apple airpod',
              parent:{
                id:3,
                textLink:'yursasmenbi',
              },
            },
            {
              id:2,
              title:'galaxy buds',
              titleGeo:'Galaxy buds',
              parent:{
                id:3,
                textLink:'yursasmenbi',
              },
              
            },
            {
              id:3,
              title:'mi buds',
              titleGeo:'Mi buds',
              parent:{
                id:3,
                textLink:'yursasmenbi',
              },
              
            },
            {
              id:4,
              title:'realmebuds',
              titleGeo:'Realme buds',
              parent:{
                id:3,
                textLink:'yursasmenbi',
              },
              
            },
            {
              id:5,
              title:'pixel buds',
              titleGeo:'Pixel buds',
              parent:{
                id:3,
                textLink:'yursasmenbi',
              },
              
            },
          ]
        },
        {
          id:4,
          title:'mobilurisAqsesuarebi',
          titleGeo:'აქსუსუარები',
          parent:{
            id:5,
            textLink:'mobiluriTelefonebi',
          },
          subCategory:[
            {
              id:1,
              title:'case',
              titleGeo:'ქეისები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:2,
              title:'kamerisdamcavebi',
              titleGeo:'კამერის დამცავები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:3,
              title:'ekranisdamcai',
              titleGeo:'ეკრანის დამცავები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:4,
              title:'damteniadapteri',
              titleGeo:'დამტენი ადაპტერები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:5,
              title:'manqanisdamteni',
              titleGeo:'მანქანის დამტენები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:6,
              title:'garedamteni',
              titleGeo:'გარე დამტენები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:7,
              title:'manqanissamagri ',
              titleGeo:'მანქანის სამაგრები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:8,
              title:'yursasmenebi',
              titleGeo:'ყურსასმენები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:9,
              title:'yurisblutusi',
              titleGeo:'ყურის ბლუთესები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            },
            {
              id:10,
              title:'selfisjoxi',
              titleGeo:'სელფის ჯოხები',
              parent:{
                id:4,
                textLink:'mobilurisAqsesuarebi',
              },
            }
          ]
        },
      ]
    },
    // {
    //   id:5,
    //   text:'პროდუქცია',
    //   textLink:'production',
    //   style:'style2',
    //   subCategory:[
    //     {
    //       id:1,
    //       title:'brand',
    //       titleGeo:'ბრენდები',
    //       parent:{
    //         id:4,
    //         textLink:'SaxliEzo',
    //       },
    //       subCategory:[
    //         {
    //           id:1,
    //           title:'apply',
    //           titleGeo:'apply',
    //         },
    //         {
    //           id:2,
    //           title:'samung',
    //           titleGeo:'samung',
    //         },
    //       ]
    //     },
    //     {
    //       id:2,
    //       title:'smartSabtebi',
    //       titleGeo:'სმარტ საათები',
    //       parent:{
    //         id:4,
    //         textLink:'SaxliEzo',
    //       },
    //       subCategory:[
    //         {
    //           id:1,
    //           title:'apply',
    //           titleGeo:'apply',
    //         },
    //         {
    //           id:2,
    //           title:'samung',
    //           titleGeo:'samung',
    //         },
    //       ]
    //     },
    //     {
    //       id:3,
    //       title:'mobilurisAqsesuarebi',
    //       titleGeo:'მობილურის აქსუსუარები',
    //       parent:{
    //         id:4,
    //         textLink:'SaxliEzo',
    //       },
    //       subCategory:[
    //         {
    //           id:1,
    //           title:'apply',
    //           titleGeo:'apply',
    //         },
    //         {
    //           id:2,
    //           title:'samung',
    //           titleGeo:'samung',
    //         },
    //       ]
    //     },
    //     {
    //       id:4,
    //       title:'yursasmenbi',
    //       titleGeo:'ყურსასმენები',
    //       parent:{
    //         id:4,
    //         textLink:'SaxliEzo',
    //       },
    //       subCategory:[
    //         {
    //           id:1,
    //           title:'apply',
    //           titleGeo:'apply',
    //         },
    //         {
    //           id:2,
    //           title:'samung',
    //           titleGeo:'samung',
    //         },
    //       ]
    //     },

    //   ]
    // },
   
  ])

  const cartRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setCartPopUpShow(false);
    }
  }

  useEffect(() => {
    if (cartPopUpShow) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }, [cartPopUpShow]);

  return (
    <IsShowCategoryPopUp.Provider value={{isShowCategoryPopUp,setIsShowCategoryPopUp}}>
        <TestingContext.Provider value={{testingContext,setTestingContext}}>
          <FirstSliderStyleContext.Provider value={{firstSliderStyleContext,setFirstSliderStyleContext}}>
            <SecondSliderStyleContext.Provider value={{secondSliderStyleContext,setSecondSliderStyleContext}}>
              <ThirdSliderStyleContext.Provider value={{thirdSliderStyleContext,setThirdSliderStyleContext}}>
                <ProductStyleContext.Provider value={{productStyleContext,setProductStyleContext}}>
                  <CartPopUpShow.Provider value={{cartPopUpShow,setCartPopUpShow}}>
                    <BorderRadiusContext.Provider value={{borderRadius,setBorderRadius}}>
                      <SettingPopUpShow.Provider value={{settingPopUpShow,setSettingPopUpShow}}>
                        <HeaderStyle.Provider value={{headerStyle,setHeaderStyle}}>
                          <TopSliderVariations.Provider value={{topslidervariations, setTopSliderVariations}}>
                            <CategoryData.Provider value={{categoryData, setCategoryData}}>
                              <ActiveMoney.Provider value={{activeMoney,setActiveMoney}}>
                                <WishlistData.Provider value={{wishlistData,setWishlistData}}>
                                  <CartData.Provider value={{cartData,setCartData}}>
                                    <TopSliderWithText.Provider value={{topsliderwithtext , setTopSliderWithText}}>
                                      <MainContext>
                                          <ScrollToTop />
                                          <MainRouter/>
                                          {cartPopUpShow && <CartPopUp cartRef={cartRef} />}
                                          {settingPopUpShow && <SettingPopUp/> }
                                      </MainContext>
                                    </TopSliderWithText.Provider>
                                  </CartData.Provider>
                                </WishlistData.Provider>
                              </ActiveMoney.Provider>
                            </CategoryData.Provider>
                          </TopSliderVariations.Provider>
                        </HeaderStyle.Provider>
                      </SettingPopUpShow.Provider>
                    </BorderRadiusContext.Provider>
                  </CartPopUpShow.Provider>
                </ProductStyleContext.Provider>
              </ThirdSliderStyleContext.Provider>
            </SecondSliderStyleContext.Provider>
          </FirstSliderStyleContext.Provider>
        </TestingContext.Provider>
    </IsShowCategoryPopUp.Provider>
  );
}

export default App;
