import { useState } from "react";
import OneMessage from "./oneprodcoment";
import { Pen, PenFill } from "react-bootstrap-icons";

function ProdComments({ comments }) {
  const [height, setHeight] = useState(false);

  const handleSetheight = () => {
    setHeight((pre) => !pre);
  };

  return (
    <div className="comentsprod w-[50%] bg-white rounded-[10px] shadow-sm px-[20px] max-h-auto">
      <h1 className="commentstitle border-b-[1px] text-[20px] pt-[15px] pb-[10px]">
        კომენტარები
      </h1>
      <div
        className={`typedmessages min-[900px]:h-[300px] overflow-y-scroll py-[10px] flex flex-col max-[900px]:h-auto  ${
          height ? " max-[900px]:max-h-auto" : "max-[900px]:max-h-[200px]"
        }`}
      >
        {comments.length < 1 ? (
          <div className="w-full h-full flex items-center justify-center my-[20px]">
            <div className="flex items-center gap-[10px] px-[10px] py-[5px] rounded-[33px] bg-[#01012514]">
              <Pen />
              <p>დააფიქსირე აზრი პირველმა</p>
            </div>
          </div>
        ) : (
          comments.map((comment, index) => (
            <OneMessage
              key={index}
              name={comment.name}
              lastname={comment.lastName}
              date={comment.date}
              text={comment.comment}
            />
          ))
        )}
      </div>
      {comments.length > 0 && (
        <div
          onClick={() => handleSetheight()}
          className="h-[40px] my-[10px] w-[124px] hidden items-center cursor-pointer max-[900px]:flex"
        >
          <p>{height ? "ნაკლების ნახვა" : "მეტის ნახვა"}</p>
        </div>
      )}
    </div>
  );
}

export default ProdComments;
