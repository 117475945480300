import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function ForgetPassword3({ handleSecaut, handleDone }) {
  const [show, setshow] = useState(false);
  const [valueData,setValueData] = useState({
    newPasswordValue:'',
    isNewPasswordValueError:false,
    isNewPasswordValueError1:false,
    newPasswordValue2:'',
    isNewPasswordValue2Error:false,
  })
  const handelChange = (e) => {
    if(e.target.name === 'newPasswordValue' ){
      if(e.target.value.length < 8){
        if(!(e.target.value.charAt(0) === e.target.value.charAt(0).toUpperCase())){
          setValueData({...valueData, [e.target.name]: e.target.value, isNewPasswordValueError: true, isNewPasswordValueError1: true})
        }
        else{
          setValueData({...valueData, [e.target.name]: e.target.value, isNewPasswordValueError: true, isNewPasswordValueError1: false})
        }
      }
      else{
        if(!(e.target.value.charAt(0) === e.target.value.charAt(0).toUpperCase())){
          setValueData({...valueData, [e.target.name]: e.target.value, isNewPasswordValueError: false,isNewPasswordValueError1: true})
        }
        else{
          setValueData({...valueData, [e.target.name]: e.target.value, isNewPasswordValueError: false,isNewPasswordValueError1: false})
        }
      }
    }
    if(e.target.name === 'newPasswordValue2'){
      if(valueData.newPasswordValue !== e.target.value){
        setValueData({...valueData, [e.target.name]: e.target.value, isNewPasswordValue2Error: true})
      }
      else{
        setValueData({...valueData, [e.target.name]: e.target.value, isNewPasswordValue2Error: false })
      }
    }
    }
  const handleshow = () => {
    setshow((pre) => !pre);
  };
  /// handle click submit
  const handleClick = () => {
    if(((!valueData.isNewPasswordValueError) && (!valueData.isNewPasswordValueError1) && (!valueData.isNewPasswordValue2Error)) 
      && ((valueData.newPasswordValue !== '') && valueData.newPasswordValue2 !== '') 
      && (valueData.newPasswordValue===valueData.newPasswordValue2) ){
      handleDone()
      handleSecaut()
    }
  }
  return (
    <div className="forgotpaswordee w-full flex flex-col gap-y-[10px] mt-[-3px] px-[18%]">
      <p className="text-center text-[18px]">პაროლის აღდგენა</p>

      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">ახალი პაროლი</p>
        <div className='flex flex-col gap-1'>
          <div className={`flex w-[100%] h-[50px] border-[1px] ${(valueData.isNewPasswordValueError || valueData.isNewPasswordValueError1) && 'border-red-600'} rounded-[10px] items-center pr-[10px] gap-[10px]`}>
            <input
              onChange={(e) => handelChange(e)}
              value={valueData.newPasswordValue}
              name='newPasswordValue'
              type={show ? "text" : "password"}
              id="password"
              placeholder="***********"
              className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
            />
            <div onClick={handleshow} className="text-[20px] text-[#010125]">
              {show ? (
                <Eye className="cursor-pointer " />
              ) : (
                <EyeSlash className="cursor-pointer" />
              )}
            </div>
          </div>
          {
            valueData.isNewPasswordValueError && 
            <p className="font-semibold text-red-600 text-center text-[12px]">ახალი პაროლი უნდა იყო მინიმუმ 8 სიმბოლო</p>
          }
          {
            valueData.isNewPasswordValueError1 && 
            <p className="font-semibold text-red-600 text-center text-[12px]">ახალი პაროლის პირველი სიმბოლო უნდა იყოს დიდი</p>
          }
        </div>
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p className="font-semibold text-[#010125]">გაიმეორე პაროლი</p>
        <div className='flex flex-col gap-1'>
          <div className={`flex w-[100%] h-[50px] border-[1px] ${valueData.isNewPasswordValue2Error && 'border-red-600'} rounded-[10px] items-center pr-[10px] gap-[10px]`}>
            <input
              onChange={(e) => handelChange(e)}
              value={valueData.newPasswordValue2}
              type={show ? "text" : "password"}
              name="newPasswordValue2"
              id="password"
              placeholder="***********"
              className="w-[100%] h-[100%] text-[#010125] shadow-none px-[10px] outline-none rounded-[10px] appearance-none"
            />
            <div onClick={handleshow} className="text-[20px] text-[#010125]">
              {show ? (
                <Eye className="cursor-pointer " />
              ) : (
                <EyeSlash className="cursor-pointer" />
              )}
            </div>
          </div>
            {
               valueData.isNewPasswordValue2Error && 
               <p className="font-semibold text-red-600 text-[12px] text-center">სწორად გაიმეორე პაროლი</p>
            }
        </div>
      </div>
      <div
        onClick={() => handleClick()}
        className="flex gap-[5px] pt-[3px] items-center w-[100%] h-[50px] rounded-[10px] bg-[#010125] text-white justify-center font-semibold text-[18px] cursor-pointer  hover:text-[#010125] border-[2px] border-[#010125] hover:bg-white duration-200"
      >
        <h1>დადასტურება</h1>
      </div>
    </div>
  );
}
