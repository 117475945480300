import React from "react";
import { useContext } from "react";
import SettingPopUpShow from "./contexts/SettingPopUpShow";
import HeaderStyle from "./contexts/HeaderStyle";
import TopSliderVariations from "./contexts/topslidervariations";
import { XLg } from "react-bootstrap-icons";
import TopSliderWithText from "./contexts/TopSliderWithText";
import ProductStyleContext from "./contexts/ProductStyleContext";
import FirstSliderStyleContext from "./contexts/FirstSliderStyleContext";
import SecondSliderStyleContext from "./contexts/SecondSliderStyleContext";
import ThirdSliderStyleContext from "./contexts/ThirdSliderStyleContext";

const SettingPopUp = () => {
  const settingPopUpShow = useContext(SettingPopUpShow);
  const firstSliderStyleContext = useContext(FirstSliderStyleContext);
  const secondSliderStyleContext = useContext(SecondSliderStyleContext);
  const thirdSliderStyleContext = useContext(ThirdSliderStyleContext);
  const productStyleContext = useContext(ProductStyleContext);
  const headerStyle = useContext(HeaderStyle);
  const topslidervariations = useContext(TopSliderVariations);
  const topsliderwithtext = useContext(TopSliderWithText);
  return (
    <div
      className="w-full h-screen fixed top-0 flex
        justify-end z-[100] "
    >
      <div
        onClick={() => settingPopUpShow.setSettingPopUpShow(false)}
        className="w-full h-full  bg-[#000000bf]"
      ></div>
      <div
        className="absolute w-[300px] h-full overflow-auto bg-white rounded-l-md  border-[2px] border-slate-200 flex flex-col 
        "
      >
        <div className="flex items-center justify-between px-[10px] py-[10px] border-b-2 border-gray-200">
          <h1>პარამეტრები</h1>
          <div
            className="w-[40px] h-[40px] items-center cursor-pointer justify-center flex border-2 
            hover:bg-[#010125] duration-200 hover:text-white border-gray-200 rounded-full"
            onClick={() => settingPopUpShow.setSettingPopUpShow(false)}
          >
            <XLg />
          </div>
        </div>
        <div className="px-[10px] py-[20px] flex flex-col gap-y-[50px]">
          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">ჰედერის ვარიაციები</h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  headerStyle.headerStyle === "header1"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => headerStyle.setHeaderStyle("header1")}
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  headerStyle.headerStyle === "header2"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => headerStyle.setHeaderStyle("header2")}
              >
                დიზაინი 2
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  headerStyle.headerStyle === "header3"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => headerStyle.setHeaderStyle("header3")}
              >
                დიზაინი 3
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">სლაიდერის კონტეინერი</h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  topslidervariations.topslidervariations === "slider1"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  topslidervariations.setTopSliderVariations("slider1")
                }
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  topslidervariations.topslidervariations === "slider2"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  topslidervariations.setTopSliderVariations("slider2")
                }
              >
                დიზაინი 2
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">სლაიდერის კონფიგურაცია</h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  topsliderwithtext.topsliderwithtext === "sliderwithtext"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  topsliderwithtext.setTopSliderWithText("sliderwithtext")
                }
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  topsliderwithtext.topsliderwithtext === "sliderwithouttext"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  topsliderwithtext.setTopSliderWithText("sliderwithouttext")
                }
              >
                დიზაინი 2
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">პირველი სლაიდერი </h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  firstSliderStyleContext.firstSliderStyleContext === "style1"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  firstSliderStyleContext.setFirstSliderStyleContext("style1")
                }
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  firstSliderStyleContext.firstSliderStyleContext === "style2"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  firstSliderStyleContext.setFirstSliderStyleContext("style2")
                }
              >
                დიზაინი 2
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  firstSliderStyleContext.firstSliderStyleContext === "style3"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  firstSliderStyleContext.setFirstSliderStyleContext("style3")
                }
              >
                დიზაინი 3
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">მეორე სლაიდერი </h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  secondSliderStyleContext.secondSliderStyleContext === "style1"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  secondSliderStyleContext.setSecondSliderStyleContext("style1")
                }
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  secondSliderStyleContext.secondSliderStyleContext === "style2"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  secondSliderStyleContext.setSecondSliderStyleContext("style2")
                }
              >
                დიზაინი 2
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  secondSliderStyleContext.secondSliderStyleContext === "style3"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  secondSliderStyleContext.setSecondSliderStyleContext("style3")
                }
              >
                დიზაინი 3
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">მესამე სლაიდერი </h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  thirdSliderStyleContext.thirdSliderStyleContext === "style1"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  thirdSliderStyleContext.setThirdSliderStyleContext("style1")
                }
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  thirdSliderStyleContext.thirdSliderStyleContext === "style2"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  thirdSliderStyleContext.setThirdSliderStyleContext("style2")
                }
              >
                დიზაინი 2
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  thirdSliderStyleContext.thirdSliderStyleContext === "style3"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  thirdSliderStyleContext.setThirdSliderStyleContext("style3")
                }
              >
                დიზაინი 3
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-y-[10px]">
            <h1 className="">პროდუქტის სლაიდერი </h1>
            <div className="flex w-full flex-wrap gap-3 items-center">
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  productStyleContext.productStyleContext === "style1"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  productStyleContext.setProductStyleContext("style1")
                }
              >
                დიზაინი 1
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  productStyleContext.productStyleContext === "style2"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  productStyleContext.setProductStyleContext("style2")
                }
              >
                დიზაინი 2
              </p>
              <p
                className={`w-[46%] h-[60px] flex shadow-lg items-center border-[1px]  duration-200 
                justify-center pb-[1px] rounded-[10px] cursor-pointer ${
                  productStyleContext.productStyleContext === "style3"
                    ? "mainBg text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  productStyleContext.setProductStyleContext("style3")
                }
              >
                დიზაინი 3
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingPopUp;
