import {
    CaretDownFill,
    CaretUpFill,
  } from "react-bootstrap-icons";
  import { useState, useEffect, useRef } from "react";
  // import FullShopCart from "../content/mshopContent/fullmshopcart";
import MainCategory from "./MainCategory";
import CategListMshop from "../mshopContent/categlistmshop";
import DropedFilter from "../mshopContent/dropedfilter";
import DropListMshop from "../mshopContent/droplistmshop";
import MultiRangeSlider from "../mshopContent/multirangeslider";
import Dropdown from "../mshopContent/Dropdown";
  
  function MainProductTwoSide({children}) {
    const [windowSize, setWindowSize] = useState([window.innerWidth]);
    const [colorData, setColorData] = useState([
      {
        id: 1,
        name: "red",
        name1: "წითელი",
      },
      {
        id: 2,
        name: "yellow",
        name1: "ყვითელი",
      },
      {
        id: 3,
        name: "blue",
        name1: "ლურჯი",
      },
      {
        id: 4,
        name: "green",
        name1: "მწვანე",
      },
      {
        id: 5,
        name: "pink",
        name1: "ვარდისფერი",
      },
      {
        id: 6,
        name: "slate",
        name1: "ნაცრისფერი",
      },
    ]);
  
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowSize([window.innerWidth]);
      };
  
      window.addEventListener("resize", handleWindowResize);
  
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, []);
  
    const [quantity, setQuantity] = useState();
  
    const handleQuantity = () => {
      setQuantity((prev) => !prev);
    };
  
    const [filter, setFilter] = useState(false);
  
    const handleFilter = () => {
      setFilter((pre) => !pre);
      if (dropList === true) {
        setDroplist(false);
      }
    };
  
    const [dropList, setDroplist] = useState(false);
  
    const handleDroplist = () => {
      setDroplist((pre) => !pre);
      if (filter === true) {
        setFilter(false);
      }
    };
  
    //
  
    const dropRef = useRef(null);
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [windowSize]);
  
    function handleClickOutside(event) {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setDroplist(false);
        setFilter(false);
      }
    }
  
    const [sizedata, setSizedata] = useState([
      {
        id: 1,
        size: "SM",
      },
      {
        id: 2,
        size: "M",
      },
      {
        id: 3,
        size: "LG",
      },
      {
        id: 4,
        size: "XL",
      },
      {
        id: 5,
        size: "2XL",
      },
      {
        id: 6,
        size: "165x100",
      },
      {
        id: 7,
        size: "205x105",
      },
      {
        id: 8,
        size: "26",
      },
      {
        id: 9,
        size: "28",
      },
      {
        id: 10,
        size: "30",
      },
      {
        id: 11,
        size: "32",
      },
    ]);
  
    const [branddata, setBranddata] = useState([
      {
        id: 1,
        brand: "apple",
      },
      {
        id: 2,
        brand: "Xiaomi",
      },
      {
        id: 3,
        brand: "Samsung",
      },
      {
        id: 4,
        brand: "Huawei",
      },
      {
        id: 5,
        brand: "Google",
      },
      {
        id: 6,
        brand: "Nokia",
      },
    ]);
  
    return (
      <div className=" w-[100%] flex flex-col items-center">
        <div className="mainshop-whole max-w-[2100px] w-[100%] flex">
          <CategListMshop />
  
          {/* droped list */}
  
          <div
            ref={dropRef}
            className="droped-list-whole hidden flex-col w-[22%]"
          >
            {/* <div
              className={`droped-drop-btn z-[9] flex  text-white  h-[50px] justify-between
             items-center cursor-pointer`}
            >
              <div
                className="kategdroppop hidden justify-center w-[50%] mainBg h-full items-center gap-[5px] "
                onClick={() => {
                  handleDroplist();
                }}
              >
                <h1>კატეგორიები</h1>
                <CaretUpFill
                  className={`${
                    dropList ? " mt-[-5px]" : "text-white rotate-180"
                  }`}
                />
              </div>
              <div
                className={`filterdropmy w-[100%] max-[1150px]:w-[50%] flex items-center bg-white text-[#010125] justify-center h-full  gap-[5px] ${
                  dropList || filter === true ? "rounded-0" : "rounded-br-[14px]"
                } `}
                onClick={() => {
                  handleFilter();
                }}
              >
                {filter ? (
                  <CaretUpFill className="mt-[-5px]" />
                ) : (
                  <CaretDownFill className="" />
                )}
                <h1>ფილტრი</h1>
              </div>
            </div> */}
            <div>
              {/* {filter && <DropedFilter handleFilter={handleFilter} />}
              {dropList && <DropListMshop handleDroplist={handleDroplist} />} */}
            </div>
          </div>
          {/* droped list end */}
          <div className="shop-center-content w-[56%] bg-gray-100">
            {children}
          </div>
          <div className="shop-right-col sticky top-[70px] pb-[10px] w-[22%] h-[88vh] border-l-[1px]">
            <div className="mainshoprightfilter pl-[15px] pr-[7px] pt-[20px] pb-[7px] h-[100%] bg-white mt-[3px] w-[100%] overflow-y-scroll flex flex-col gap-[10px]">
              <div className="price ">
                <p className="shoplisttitle">ფასი</p>
                <MultiRangeSlider min={0} max={1000} />
              </div>
              <Dropdown name={"ბრენდი"} defaultState={true}>
                {branddata.map((item) => (
                  <div
                    key={item.id} 
                    className="flex gap-[5px]">
                    <input type="checkbox" name="a"></input>
                    <label htmlFor="a" className="text-[15px]">
                      {item.brand}
                    </label>
                  </div>
                ))}
              </Dropdown>
              <Dropdown name={"ზომა"} defaultState={true}>
                <div className="w-full flex flex-wrap gap-[5px]">
                  {sizedata.map((item) => (
                    <div
                      key={item.id} 
                      className=" border-[1px] border-gray-200 px-[5px] py-[2px] rounded-[8px] text-[11px] cursor-pointer">
                      <p>{item.size}</p>
                    </div>
                  ))}
                </div>
              </Dropdown>
              <Dropdown name={"ფერი"} defaultState={true}>
                <div className="w-full flex flex-col gap-[5px]">
                  {colorData.map((item) => (
                    <div
                      key={item.id} 
                      className="flex gap-[5px] ">
                      <input
                        className={`accent-${item.name}-600 `}
                        type="checkbox"
                        name="aa"
                      ></input>
                      <label className={`text-[15px] `}>{item.name1}</label>
                    </div>
                  ))}
                </div>
              </Dropdown>
              <div className="w-full h-auto flex  py-5   justify-between items-center px-5 bg-white border-t-[2px] border-t-slate-200">
              <div 
                // onClick={changer}
                className=" py-[10px] 
                  cursor-pointer flex justify-center items-center  text-[13px]"
                >
                <p className="text-[15px]">გასუფთავება</p>
              </div>
              <div className="bg-[#010125] rounded-full text-white py-3 px-5">
                <h1 className='text-[14px]'>გაფილტვრა</h1>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default MainProductTwoSide;
  