import { useState, useContext, useEffect } from "react";
import Counter from "./prodcounter";
import { useLocation, Link } from "react-router-dom";
import { Cart, Check, EyeFill, Heart, HeartFill } from "react-bootstrap-icons";
import CartData from "../../contexts/CartData";
import WishlistData from "../../contexts/WishlistData";
import ActiveMoney from "../../contexts/activeMoney";
import CartPopUpShow from "../../contexts/CartPopUpShow";
import useFunction from "../../hooks/useFunction";

function SliderComponent({
  item,
  img,
  title,
  price,
  index,
  handleShowProdPopup,
  handlePopupDataChange,
  sale,
  empty,
}) {
  let location = useLocation();
  const [colorDefault, setColorDefault] = useState(item.color[0].name);
  const [imageChanger, setImageChanger] = useState(img);
  const newCartData = useContext(CartData);
  const activeMoney = useContext(ActiveMoney);
  const wishlistData = useContext(WishlistData);
  const cartPopUpShow = useContext(CartPopUpShow);
  const [counternum, setCounternum] = useState(1);
  const [addCart, handelHeart] = useFunction();
  const [test1, setTest1] = useState(0);

  const [hearted, setHearted] = useState(false);
  const [cartAdded, setCartAdded] = useState(false);
  const handleHearted = () => {
    setHearted((pre) => !pre);
  };
  useEffect(() => {
    const filtered = wishlistData.wishlistData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setHearted(true);
    } else {
      setHearted(false);
    }
  }, [wishlistData]);
  ////cart data item
  useEffect(() => {
    const filtered = newCartData.cartData.filter(
      (items) => items.id === item.id
    );
    if (filtered.length > 0) {
      setCartAdded(true);
    } else {
      setCartAdded(false);
    }
  }, [newCartData]);
  const mouseOver = () => {
    if(colorDefault === item.color[0].name){
      setImageChanger(item.img1)
    }
  }
  const mouseOut = () => {
    if(colorDefault === item.color[0].name){
      setImageChanger(item.img)
    }
  }

  // const [fade, setFade] = useState(true);

  // useEffect(() => {
  //   setFade(true); // Trigger fade-in effect when src changes
  //   const timer = setTimeout(() => {
  //     setFade(false); // Reset fade effect after a delay (adjust duration in CSS)
  //   }, 500); // Adjust the delay duration as per your preference

  //   return () => clearTimeout(timer);
  // }, [imageChanger]);

  return (
    <div className="prodcomp w-[100%] flex justify-center h-[100%] ">
      <div
        className={`p-[15px] bg-white  rounded-[15px] flex flex-col justify-between relative group ${
          location.pathname.split("/")[1] === "mainshop"
            ? "cardib w-[100%] "
            : "cart-onmain w-[100%]"
        }`}
      >
        {sale && (
          <div
            className="absolute left-[8px] top-[8px] px-[8px] h-[25px] bg-[red] rounded-[20px]
          text-white text-[12px] flex items-center justify-center pt-[2px]
          "
          >
            <p>-{sale}%</p>
          </div>
        )}
        <Link to={`/product/${item.id}`}>
          <div
            onMouseOver={() => mouseOver()}
            onMouseOut={() => mouseOut()}
            className={`cursor-pointer  w-[100%] flex justify-center  ${
              location.pathname === "/product"
                ? "prodcomp-img h-[180px]"
                : `${
                    location.pathname.split("/")[1] === "mainshop"
                      ? "mainshNonsalecard h-[150px]"
                      : "cart-main h-[200px]"
                  }`
            }`}
          >
            <img
              src={imageChanger}
              className={`w-[100%] h-[98%] object-cover transition-all duration-500`}
              alt=""
            />
          </div>
        </Link>
        <div className="flex items-center gap-[3px] my-[10px]">
          {item.color.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                setColorDefault(item.name);
                setImageChanger(item.src);
              }}
              className={`w-[30px] h-[30px] rounded-full border-[${colorDefault}] ${
                colorDefault === item.name && "border-[1px]"
              } flex 
          items-center justify-center  cursor-pointer`}
            >
              <div
                className={`w-[70%] h-[70%] bg-[${item.name}] rounded-[33px]`}
              ></div>
            </div>
          ))}
        </div>
        <Link to={`/product/${item.id}`}>
          <p>{title}</p>
        </Link>

        {sale ? (
          <div className="salecrds flex gap-[5px] items-center ">
            <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
              {activeMoney.activeMoney === "lari"
                ? `${price - (price / 100) * sale}₾`
                : `${item.dolPrice - (item.dolPrice / 100) * sale}$`}
            </p>

            <p className="downtexti line-through text-[12px] pt-[5px]">
              {activeMoney.activeMoney === "lari"
                ? `${price}₾`
                : `${item.dolPrice}$`}
            </p>
          </div>
        ) : (
          <div className="salecrds flex gap-[5px] items-center ">
            <p className="up-text my-[5px] text-[17px]  text-[black]  rounded-[33px]  flex items-center pt-[2px]  h-[35px]">
              {activeMoney.activeMoney === "lari"
                ? `${price}₾`
                : `${item.dolPrice}$`}
            </p>
          </div>
        )}

        <div className="flex justify-between">
          <Counter
            price={price}
            setCounternum={setCounternum}
            sale={false}
            test1={test1}
          />

          <div
            className="w-[30px] h-[30px]  rounded-full flex items-center justify-center mainBg cursor-pointer text-white"
            onClick={() => {
              handelHeart(item);
            }}
          >
            {hearted ? <HeartFill /> : <Heart />}
          </div>

          <div
            className="w-[30px] h-[30px] rounded-full flex items-center justify-center  mainBg text-white cursor-pointer"
            onClick={() => {
              handlePopupDataChange(item, counternum);
              handleShowProdPopup();
            }}
          >
            <EyeFill />
          </div>
        </div>

        <div className="flex gap-[8px] items-center w-full mt-[8px]">
          {empty ? (
            <div
              onClick={() => {
                addCart(item, counternum);
                setTest1((prev) => prev + 1);
              }}
              className="giftcardbuy mt-[10px] w-[100%] bg-[#010125] duration-200 hover:bg-[#010125a2] pt-[3px] h-[35px] pb-[4px] gap-[5px] flex justify-center items-center rounded-[33px] text-white cursor-pointer"
            >
              {cartAdded && counternum === 0 ? (
                <Check className="text-lg" />
              ) : (
                <Cart />
              )}
              <p className="">
                {cartAdded && counternum === 0 ? "დამატებულია" : "დამატება"}
              </p>
            </div>
          ) : (
            <div className="giftcardbuy mt-[10px] w-[100%] bg-[#0101254f] h-[35px] pb-[4px] gap-[5px] pt-[5px] flex justify-center items-center pointer-events-none rounded-[33px] text-white cursor-pointer">
              <p>არარის მარაგში</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SliderComponent;
